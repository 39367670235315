import { CalendarResponse, UpdateCalendarDto } from "../types/calendar.types";
import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

function intDateToDateISOString(dayAsInt: string) {
	return (
		dayAsInt.slice(0, 4) +
		"-" +
		dayAsInt.slice(4, 6) +
		"-" +
		dayAsInt.slice(6)
	);
}

function convertDate(date: any) {
	date.rooms.forEach((room: any) => {
		room.dates = Object.entries(room.dates).reduce((pv, cv) => {
			return { ...pv, [intDateToDateISOString(cv[0])]: cv[1] };
		}, {});

		room.rates.forEach((rate: any) => {
			rate.dates = Object.entries(rate.dates).reduce((pv, cv) => {
				return {
					...pv,
					[intDateToDateISOString(cv[0])]: cv[1],
				};
			}, {});
		});
	});

	return date;
}

export class CalendarApi {
	static async get(query: {
		date: string[];
	}): Promise<CalendarResponse.CalendarResponse> {
		return mainApiInstence
			.get("/calendar", { params: query })
			.then(getDataMiddleware)
			.then(convertDate);
	}
	static async pricePerGuestStrategy(query: {
		room_id: number;
		rate_id: number;
	}): Promise<CalendarResponse.CalendarResponse> {
		return mainApiInstence
			.get("/calendar/price-per-guest-strategy", { params: query })
			.then(getDataMiddleware);
	}
	static async updateStrategy(query: {
		room_id: number;
		rate_id: number;
		default_guest: number;
		price_by_guest_strategy: any[];
	}): Promise<CalendarResponse.CalendarResponse> {
		return mainApiInstence
			.patch("/calendar/price-per-guest-strategy", query)
			.then(getDataMiddleware);
	}

	static async update(
		data: UpdateCalendarDto
	): Promise<CalendarResponse.CalendarResponse> {
		return mainApiInstence
			.patch("/calendar", data)
			.then(getDataMiddleware)
			.then(convertDate);
	}
	static async addRoom(
		data: any
	): Promise<CalendarResponse.CalendarResponse> {
		return mainApiInstence
			.post("/property/rooms", data)
			.then(getDataMiddleware)
			.then(convertDate);
	}
}
