import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row } from "antd";
import { t } from "i18n";
import React, { useMemo } from "react";

export function FilterViewNew({ filter, onSubmit, fields }) {
	const initial = useMemo(() => filter, []);
	const [form] = Form.useForm();

	return (
		<Form
			form={form}
			initialValues={initial}
			onFinish={onSubmit}
			layout="vertical"
		>
			<Row gutter={8} style={{ justifyContent: "space-between" }}>
				{fields.map((field, i) => (
					<Col span={12} key={i}>
						<Form.Item name={field.name} label={field.label}>
							<field.InputComponent
								size="small"
								type={field.type}
								placeholder={field.label}
								{...field.inputProps}
							/>
						</Form.Item>
					</Col>
				))}
				<Col span={24} className="ml-auto">
					<Form.Item>
						<div className="d-flex justify-content-center">
							<Button
								type="primary"
								htmlType="submit"
								style={{ marginRight: 5 }}
								icon={<FilterOutlined />}
							>
								{t("Filter")}
							</Button>
						</div>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}

export function FilterView({ filter, onSubmit, fields }) {
	const initial = useMemo(() => filter, []);
	const [form] = Form.useForm();
	return (
		<Form
			form={form}
			initialValues={initial}
			onFinish={onSubmit}
			layout="vertical"
		>
			<Row gutter={8} style={{ justifyContent: "space-between" }}>
				{fields.map((field, i) => (
					<Col xs={12} sm={6} md={6} lg={5} key={i}>
						<Form.Item name={field.name} label={field.label}>
							<field.InputComponent
								size="small"
								type={field.type}
								placeholder={field.label}
								{...field.inputProps}
							/>
						</Form.Item>
					</Col>
				))}
				<Col span={4} className="ml-auto">
					<Form.Item label={t("Filter")}>
						<div className="d-flex">
							<Button
								type="primary"
								size="small"
								htmlType="submit"
								style={{ marginRight: 5 }}
								icon={<FilterOutlined />}
							>
								{t("Filter")}
							</Button>
							<Button
								block
								size="small"
								htmlType="submit"
								icon={<ReloadOutlined />}
								onClick={() => form.resetFields("")}
							/>
						</div>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
