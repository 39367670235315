import NotFound from "components/pages/404";
import React from "react";
import { Route, Switch } from "react-router-dom";
import AllProperties from "./AllProperties";
import PropertyItem from "./PropertyItem";

export default function PropertiesLayout({ match }) {
	return (
		<Switch>
			<Route exact path={match.path} component={AllProperties} />
			<Route path={match.path + "/:id"} component={PropertyItem} />
			<Route exact path="/404" component={NotFound} />
		</Switch>
	);
}
