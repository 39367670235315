import Select from "antd/es/select";
import Input from "antd/lib/input/Input";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";
import { PropertyStatus } from "./property.const";

export function getPropertiesFilterFields(regions, categories) {
	return [
		{
			name: "name",
			label: t("name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: "legal_name",
			label: t("Legal name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: "star",
			label: t("Star"),
			inputProps: {
				options: [
					{ label: t("no-star"), value: 0 },
					{ label: 1, value: 1 },
					{ label: 2, value: 2 },
					{ label: 3, value: 3 },
					{ label: 4, value: 4 },
					{ label: 5, value: 5 },
				],
				allowClear: true,
			},
			span: 4,
			InputComponent: Select,
		},
		{
			name: "status",
			label: t("Status"),
			inputProps: {
				options: Object.entries(PropertyStatus).map((e) => ({
					label: e[0],
					value: e[1],
				})),
				allowClear: true,
			},
			span: 4,
			InputComponent: Select,
		},
		{
			name: "rating",
			label: t("Rating"),
			span: 4,
			type: "number",
			InputComponent: Input,
		},
		{
			name: "contact_name",
			label: t("Contact name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: "phone_number",
			label: t("Phone number"),
			span: 4,
			type: "number",
			InputComponent: PhoneNumberWithPrefix,
		},
		{
			name: "percentage",
			label: t("Percentage(%)"),
			span: 4,
			type: "number",
			InputComponent: Input,
		},
		{
			name: "city_id",
			label: t("City"),
			inputProps: {
				options: regions.map((r) => ({
					label: r.name,
					value: r.id,
				})),
				allowClear: true,
			},
			span: 4,
			InputComponent: Select,
		},
		{
			name: "category_id",
			label: t("Category"),
			inputProps: {
				options: categories.map((item) => ({
					label: item.name,
					value: item.id,
				})),
				allowClear: true,
			},
			span: 4,
			InputComponent: Select,
		},
	];
}
