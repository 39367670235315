import { Avatar, Button, Card, Col, Form, Row, Tag } from "antd";
import { FacilitiesAdminApi } from "api/admin/FacilitiesAdminApi";
import { PropertyApi } from "api/PropertyApi";
import { ResourceAdminApi } from "api/ResourceAdminApi";
import { ResourceApi } from "api/ResourceApi";
import initial_filter from "constants/InitialFilter";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import TableComponent from "../components/TableComponent";
import { FacilitiesForm } from "./Components/FacilitiesForm";
import ResourseModal from "./Modal";

export default function Facilities() {
	const columns = [
		{
			title: `${t("Id")}`,
			dataIndex: "id",
		},
		{
			title: `${t("Icon")}`,
			dataIndex: "icon",
			render: (_, record) => {
				return <Avatar size={25} shape="square" src={record.icon} />;
			},
		},
		{
			title: `${t("Name")}`,
			dataIndex: "name",
		},
		{
			title: `${t("Pay option")}`,
			dataIndex: "pay_option",
			render: (_, record) => {
				if (record.pay_option) {
					return "Paid";
				} else if (!record.pay_option) {
					return "Not Paid";
				} else {
					return "-";
				}
			},
		},
		{
			title: `${t("Group name")}`,
			dataIndex: "facility_group_id",
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			render: (_, record) => {
				if (record.status === 0) {
					return <Tag color="red">{t("Inactive")}</Tag>;
				} else if (record.status === 1) {
					return <Tag color="green">{t("Active")}</Tag>;
				}
			},
		},
		{
			title: `${t("Created At")}`,
			dataIndex: "createdAt",
			render: (_, record) => {
				return dayjs(record.createdAt).format("MMM Do YY");
			},
		},
		{
			title: `${t("Operations")}`,
			dataIndex: "operations",
			render: (_, record) => {
				return (
					<Row>
						<Button
							onClick={() => {
								form.setFieldsValue(record);
								if (record.icon) {
									setFileList([{ url: record.icon }]);
								}
								setisEditModalOpen(true);
							}}
						>
							{t("Edit")}
						</Button>
						<Button
							onClick={() => deleteHandler(record)}
							type={"primary"}
							danger
							style={{ marginLeft: 10 }}
						>
							{t("Delete")}
						</Button>
					</Row>
				);
			},
		},
	];
	const [filter, setFilter] = useState(initial_filter);
	const [data, setdata] = useState([]);
	const [isEditModalOpen, setisEditModalOpen] = useState(false);
	const [isCreateModalOpen, setisCreateModalOpen] = useState(false);
	const [facilitiesGroup, setfacilitiesGroup] = useState([]);
	const [loading, setloading] = useState(false);
	const [form] = Form.useForm();
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		fetch(filter);
		ResourceApi.getList("facilities/groups", {}).then((data) => {
			setfacilitiesGroup(data.items);
		});
	}, []);
	function fetch(filter) {
		setloading(true);
		FacilitiesAdminApi.getList(filter).then((data) => {
			setdata(data);
			setloading(false);
		});
	}

	const onEdit = () => {
		ResourceAdminApi.facilitiesPatch(
			{ ...form.getFieldValue() },
			form.getFieldValue().id
		)
			.then(({ data: newData }) => {
				MessagesService.success();
				const newState = data.items.map((obj) => {
					if (obj.id === newData.id) {
						return { ...newData, sort_order: obj.sort_order };
					}
					return obj;
				});
				setdata({ items: newState });
				setisEditModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
				setisEditModalOpen(false);
			});
	};
	const onCreate = () => {
		ResourceAdminApi.facilitiesPost({
			...form.getFieldValue(),
			icon: fileList[0]?.url,
			sort_order: data.items.length + 1,
		})
			.then(({ data: newData }) => {
				MessagesService.success();
				setdata({ items: [...data.items, newData] });
				setisCreateModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	const deleteHandler = (record) => {
		ResourceAdminApi.facilitiesDelete(record.id)
			.then((f) => {
				setdata({
					items: data.items.filter((item) => item.id != record.id),
				});
				MessagesService.success();
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	// UPLOAD function
	const deleteFile = async (info) => {
		await PropertyApi.deletePhoto(info.id)
			.then(() => {
				MessagesService.success();
				setFileList(fileList.filter((item) => item.id !== info.id));
			})
			.catch(MessagesService.error);
	};
	function ValidateFile(file) {
		const isLargeFile = file.size && file.size > 5 * 1024 * 1024;

		if (isLargeFile) {
			throw new Error(
				`${file.name} ${t("Image size should not exceed 5mb.")}`
			);
		}
	}
	const handleChange = async (info) => {
		try {
			ValidateFile(info.file);
			if (info.file.status === "done") {
				info.file.url = info.file.response.path;
				info.file.id = info.file.response.id;
			}
			setFileList(info.fileList);
		} catch (error) {
			MessagesService.error(error);
		}
	};
	return (
		<div>
			<Card>
				<Row style={{ justifyContent: "space-between" }}>
					<Col>
						<h4 className="m-0">{t("Facilities list")}</h4>
					</Col>
					<Row gutter={10}>
						<Col>
							<Button
								type="primary"
								size="small"
								onClick={() => {
									setFileList([]);
									setisCreateModalOpen(true);
									form.resetFields();
								}}
							>
								{t("Create")}
							</Button>
						</Col>
					</Row>
				</Row>
			</Card>
			<TableComponent
				columns={columns}
				data={data}
				filter={filter}
				setFilter={setFilter}
				fetch={fetch}
				loading={loading}
			/>
			<ResourseModal
				title={t("Edit")}
				isModalOpen={isEditModalOpen}
				handleCancel={() => setisEditModalOpen(false)}
				content={
					<FacilitiesForm
						title={"Edit"}
						facilitiesGroup={facilitiesGroup}
						form={form}
						fileList={fileList}
						onFinish={onEdit}
						deleteFile={deleteFile}
						handleChange={handleChange}
					></FacilitiesForm>
				}
			></ResourseModal>

			<ResourseModal
				title="Create"
				isModalOpen={isCreateModalOpen}
				handleCancel={() => setisCreateModalOpen(false)}
				content={
					<FacilitiesForm
						title={"Create"}
						facilitiesGroup={facilitiesGroup}
						form={form}
						fileList={fileList}
						onFinish={onCreate}
						deleteFile={deleteFile}
						handleChange={handleChange}
					></FacilitiesForm>
				}
			></ResourseModal>
		</div>
	);
}
