import QueryString from "qs";
import mainApiInstence from "./mainApiInstence";
export class LegalDataApi {
	static getLegalPropertys() {
		return mainApiInstence.get(`/property/legal-data`);
	}
	static createLegalDataProperty(data: any) {
		return mainApiInstence.post(`/property/legal-data`, data);
	}
	static getAllLegalDatas(query: any) {
		return mainApiInstence.get(
			`/properties/legal-data?${QueryString.stringify(query)}`
		);
	}
	static editLegalDataProperty(id: number, data: any) {
		return mainApiInstence.patch(`/legal-data/${id}`, data);
	}
	static editLegalCustomer(id: number, data: any) {
		return mainApiInstence.patch(
			`/property/legal-data/${String(id)}`,
			data
		);
	}
}
