import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="page" />}>
			<Switch>
				<Route
					path={`${AUTH_PREFIX_PATH}/login`}
					component={lazy(() => import(`./authentication/login`))}
				/>
				<Route
					path={`${AUTH_PREFIX_PATH}/register`}
					component={lazy(() =>
						import(`./authentication/register/RegisterLayout`)
					)}
				/>
				<Route
					path={`${AUTH_PREFIX_PATH}/confirm-email/:hash`}
					component={lazy(() => import(`./authentication/confirm`))}
				/>
				<Route
					path={`${AUTH_PREFIX_PATH}/password/reset`}
					component={lazy(() =>
						import(`./authentication/forgotPassword`)
					)}
				/>
				<Route
					path={`${AUTH_PREFIX_PATH}/password-change/:hash`}
					component={lazy(() =>
						import(`./authentication/resetPassword`)
					)}
				/>

				<Redirect
					from={`${AUTH_PREFIX_PATH}`}
					to={`${AUTH_PREFIX_PATH}/login`}
				/>
			</Switch>
		</Suspense>
	);
};

export default AppViews;
