import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { t } from "i18n";
import React from "react";
import { TranslationLocales } from ".";

function ContentTranslationFormItemDescription({ name }: { name: string }) {
	return TranslationLocales.map((locale) => {
		return (
			<Form.Item
				key={locale}
				name={[name, locale]}
				label={t("in-" + locale)}
				rules={[
					{
						required: true,
					},
				]}
			>
				<TextArea placeholder={t("Description")} />
			</Form.Item>
		);
	});
}

export default ContentTranslationFormItemDescription;
