import { UserOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { t } from "i18n";
import React from "react";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";

export default function SettingsForm({
	form,
	fileList,
	onChange,
	onPreview,
	deleteFile,
	onFinish,
	initialValue,
}) {
	return (
		<Col xs={24} sm={24} md={12} lg={12}>
			<Form
				name="Settings"
				form={form}
				onFinish={onFinish}
				initialValues={initialValue}
				layout="vertical"
			>
				<Form.Item
					label={t("Avatar")}
					name="avatar"
					labelCol={{ span: 8 }}
				>
					<ImgCrop rotate>
						<Upload
							accept="image/png, image/jpeg"
							listType="picture-card"
							onChange={onChange}
							fileList={fileList}
							customRequest={imageUploadCustomRequest}
							showUploadList={true}
							onPreview={onPreview}
							onRemove={deleteFile}
							style={{
								borderRadius: "50% !important",
							}}
						>
							{fileList.length < 1 && (
								<UserOutlined style={{ fontSize: 24 }} />
							)}
						</Upload>
					</ImgCrop>
				</Form.Item>

				<Form.Item
					label={t("First name")}
					name="first_name"
					labelCol={{ span: 8 }}
				>
					<Input placeholder={t("First name")} />
				</Form.Item>
				<Form.Item
					label={t("Last name")}
					name="last_name"
					labelCol={{ span: 8 }}
				>
					<Input placeholder={t("Last name")} />
				</Form.Item>

				<Alert
					message={t(
						"To change the password, enter the old and new password and click save"
					)}
					type="info"
					showIcon
					style={{ marginBottom: 10 }}
				/>

				<Form.Item
					label={t("Old password")}
					name="old_password"
					labelCol={{ span: 8 }}
				>
					<Input.Password placeholder={t("Old password")} />
				</Form.Item>

				<Form.Item
					label={t("New password")}
					name="new_password"
					labelCol={{ span: 8 }}
				>
					<Input.Password placeholder={t("New password")} />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						{t("Save")}
					</Button>
				</Form.Item>
			</Form>
		</Col>
	);
}
