import { Button, Card, Col, Form, Row, Tag } from "antd";
import { AminitiesGroupAdminApi } from "api/admin/AminitiesGroupAdminApi";
import { ResourceAdminApi } from "api/ResourceAdminApi";
import initial_filter from "constants/InitialFilter";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import TableComponent from "../components/TableComponent";
import { AminitiesGroupForm } from "./Components/AminitiesGroupForm";
import ResourseModal from "./Modal";

export default function AminitiesGroup() {
	const [filter, setFilter] = useState(initial_filter);
	const columns = [
		{
			title: `${t("Id")}`,
			dataIndex: "id",
		},
		{
			title: `${t("Name")}`,
			dataIndex: "name",
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			render: (_, record) => {
				if (record.status === 0) {
					return <Tag color="red">{t("Inactive")}</Tag>;
				} else if (record.status === 1) {
					return <Tag color="green">{t("Active")}</Tag>;
				}
			},
		},
		{
			title: `${t("Created At")}`,
			dataIndex: "createdAt",
			render: (_, record) => {
				return dayjs(record.createdAt).format("MMM Do YY");
			},
		},
		{
			title: `${t("Operations")}`,
			dataIndex: "operations",
			render: (_, record) => {
				return (
					<Row>
						<Button
							onClick={() => {
								form.setFieldsValue(record);
								setisEditModalOpen(true);
							}}
						>
							{t("Edit")}
						</Button>
						<Button
							onClick={() => deleteHandler(record)}
							type={"primary"}
							danger
							style={{ marginLeft: 10 }}
						>
							{t("Delete")}
						</Button>
					</Row>
				);
			},
		},
	];
	const [data, setdata] = useState([]);
	const [isEditModalOpen, setisEditModalOpen] = useState(false);
	const [isCreateModalOpen, setisCreateModalOpen] = useState(false);
	const [form] = Form.useForm();
	const [loading, setloading] = useState(false);

	useEffect(() => {
		fetch(filter);
	}, []);

	function fetch(filter) {
		setloading(true);
		AminitiesGroupAdminApi.getList(filter).then((data) => {
			setdata(data);
			setloading(false);
		});
	}

	const onEdit = () => {
		ResourceAdminApi.aminitiesGroupPatch(
			{ ...form.getFieldValue() },
			form.getFieldValue().id
		)
			.then(({ data: newData }) => {
				MessagesService.success();
				const newState = data.items.map((obj) => {
					if (obj.id === newData.id) {
						return { ...newData, sort_order: obj.sort_order };
					}
					return obj;
				});
				setdata({ items: newState });
				setisEditModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
				setisEditModalOpen(false);
			});
	};
	const onCreate = () => {
		ResourceAdminApi.aminitiesGroupPost({
			...form.getFieldValue(),
			sort_order: data.length + 1,
		})
			.then(({ data: newData }) => {
				MessagesService.success();
				setdata({ items: [...data.items, newData] });
				setisCreateModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	const deleteHandler = (record) => {
		ResourceAdminApi.aminitiesGroupDelete(record.id)
			.then((f) => {
				setdata({
					items: data.items.filter((item) => item.id != record.id),
				});
				MessagesService.success();
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	return (
		<div>
			<Card>
				<Row style={{ justifyContent: "space-between" }}>
					<Col>
						<h4 className="m-0">{t("Aminities group list")}</h4>
					</Col>
					<Row gutter={10}>
						<Col>
							<Button
								type="primary"
								size="small"
								onClick={() => {
									setisCreateModalOpen(true);
									form.resetFields();
								}}
							>
								{t("Create")}
							</Button>
						</Col>
					</Row>
				</Row>
			</Card>
			<TableComponent
				columns={columns}
				data={data}
				filter={filter}
				setFilter={setFilter}
				fetch={fetch}
				loading={loading}
			/>
			<ResourseModal
				title={t("Edit")}
				isModalOpen={isEditModalOpen}
				handleCancel={() => setisEditModalOpen(false)}
				content={
					<AminitiesGroupForm
						title={t("Edit")}
						form={form}
						onFinish={onEdit}
					></AminitiesGroupForm>
				}
			></ResourseModal>

			<ResourseModal
				title={t("Create")}
				isModalOpen={isCreateModalOpen}
				handleCancel={() => setisCreateModalOpen(false)}
				content={
					<AminitiesGroupForm
						title={t("Create")}
						form={form}
						onFinish={onCreate}
					></AminitiesGroupForm>
				}
			></ResourseModal>
		</div>
	);
}
