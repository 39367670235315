import { Card, Col, Form, Radio, Row } from "antd";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import {
	getDescriptionTranslation,
	getNameTranslation,
} from "../../../../../services/ContentTranslation/get-translations";

export default function HotelCategory() {
	const [categorys, setcategorys] = useState([]);
	useEffect(() => {
		ResourceApi.getList("categories", {
			page: 1,
			limit: 10,
			active: true,
		}).then((data) =>
			setcategorys(data.items.sort((a, b) => a.sort - b.sort))
		);
	}, []);

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="center">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h3 className="my-3 mt-5">
							{t("which property category")}
						</h3>

						<Card>
							<div className="my-0 p-3">
								<Row justify="start">
									<Col xs={24} sm={24} md={24} lg={24}>
										<Form.Item
											label={
												<p
													style={{
														fontSize: "16px",
														margin: 0,
													}}
												>
													{t(
														"Select property category"
													)}
												</p>
											}
											name="category_id"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Property category is required!"
													)}`,
												},
											]}
										>
											<Radio.Group
												name="hotel_catalog"
												size="large"
											>
												<Row gutter={12}>
													{categorys.map((item) => {
														return (
															<Col
																key={item.id}
																xs={24}
																sm={24}
																md={12}
																lg={8}
																className={
																	"mb-2"
																}
															>
																<Radio.Button
																	value={
																		item.id
																	}
																	style={{
																		height: "100%",
																		padding:
																			"10px",
																	}}
																>
																	<Row>
																		<Col
																			span={
																				12
																			}
																		>
																			<img
																				src={
																					item.image
																						? item
																								?.image
																								?.path
																						: "https://searchenginereports.net/newassets/new-images/dummy.jpg"
																				}
																				alt="Catalog image"
																				style={{
																					display:
																						"flex",
																					width: "100%",
																					objectFit:
																						"cover",
																				}}
																			/>
																		</Col>
																		<Col
																			span={
																				12
																			}
																			className="p-2"
																		>
																			<h4>
																				{getNameTranslation(
																					item
																				)}
																			</h4>
																			<p
																				style={{
																					fontSize: 12,
																				}}
																			>
																				{getDescriptionTranslation(
																					item
																				)}
																			</p>
																		</Col>
																	</Row>
																</Radio.Button>
															</Col>
														);
													})}
												</Row>
											</Radio.Group>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
