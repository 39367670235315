import { getDataMiddleware } from "api/api.utils";
import QueryString from "qs";
import mainApiInstence from "../mainApiInstence";

export class RoomTypesAdminApi {
	static async getList(query: any) {
		return mainApiInstence
			.get(`/rooms/room-types?${QueryString.stringify(query)}`)
			.then(getDataMiddleware);
	}
}
