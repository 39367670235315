import mainApiInstence from "./mainApiInstence";

export class ResourceAdminApi {
	// Categories
	static categoriesPost(data: any) {
		return mainApiInstence.post("/categories", data);
	}
	static categoriesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/categories/${id}`, data);
	}
	static categoriesDelete(id: number) {
		return mainApiInstence.delete(`/categories/${id}`);
	}

	//NearbyPlaces
	static nearbyPlacesPost(data: any) {
		return mainApiInstence.post("/nearby-places", data);
	}
	static nearbyPlacesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/nearby-places/${id}`, data);
	}
	static nearbyPlacesDelete(id: number) {
		return mainApiInstence.delete(`/nearby-places/${id}`);
	}

	// FacilitiesGroup
	static facilitiesGroupPost(data: any) {
		return mainApiInstence.post("/facilities/groups", data);
	}
	static facilitiesGroupPatch(data: any, id: any) {
		return mainApiInstence.patch(`/facilities/groups/${id}`, data);
	}
	static facilitiesGroupDelete(id: number) {
		return mainApiInstence.delete(`/facilities/groups/${id}`);
	}

	// AminitiesGroup
	static aminitiesGroupPost(data: any) {
		return mainApiInstence.post("/amenities/groups", data);
	}
	static aminitiesGroupPatch(data: any, id: any) {
		return mainApiInstence.patch(`/amenities/groups/${id}`, data);
	}
	static aminitiesGroupDelete(id: number) {
		return mainApiInstence.delete(`/amenities/groups/${id}`);
	}

	// Facilities
	static facilitiesPost(data: any) {
		return mainApiInstence.post("/facilities", data);
	}
	static facilitiesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/facilities/${id}`, data);
	}
	static facilitiesDelete(id: number) {
		return mainApiInstence.delete(`/facilities/${id}`);
	}

	// Amenities
	static amenitiesPost(data: any) {
		return mainApiInstence.post("/amenities", data);
	}
	static amenitiesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/amenities/${id}`, data);
	}
	static amenitiesDelete(id: number) {
		return mainApiInstence.delete(`/amenities/${id}`);
	}

	// Regions
	static regionsPost(data: any) {
		return mainApiInstence.post("/regions", data);
	}
	static regionsPatch(data: any, id: any) {
		return mainApiInstence.patch(`/regions/${id}`, data);
	}
	static regionsDelete(id: number) {
		return mainApiInstence.delete(`/regions/${id}`);
	}

	// Payment-medthod
	static paymentMethodsPost(data: any) {
		return mainApiInstence.post("/payment-methods", data);
	}
	static paymentMethodsPatch(data: any, id: any) {
		return mainApiInstence.patch(`/payment-methods/${id}`, data);
	}
	static paymentMethodsDelete(id: number) {
		return mainApiInstence.delete(`/payment-methods/${id}`);
	}

	// Photo-tags
	static photoTagsPost(data: any) {
		return mainApiInstence.post("/photo-tags", data);
	}
	static photoTagsPatch(data: any, id: any) {
		return mainApiInstence.patch(`/photo-tags/${id}`, data);
	}
	static photoTagsDelete(id: number) {
		return mainApiInstence.delete(`/photo-tags/${id}`);
	}

	// Bed-type
	static bedTypesPost(data: any) {
		return mainApiInstence.post("rooms/bed-types", data);
	}
	static bedTypesPatch(data: any, id: any) {
		return mainApiInstence.patch(`rooms/bed-types/${id}`, data);
	}
	static bedTypesDelete(id: number) {
		return mainApiInstence.delete(`rooms/bed-types/${id}`);
	}

	// Room-types
	static roomTypesPost(data: any) {
		return mainApiInstence.post("rooms/room-types", data);
	}
	static roomTypesPatch(data: any, id: any) {
		return mainApiInstence.patch(`rooms/room-types/${id}`, data);
	}
	static roomTypesDelete(id: number) {
		return mainApiInstence.delete(`rooms/room-types/${id}`);
	}

	// Breakfast types
	static breakfastTypesPost(data: any) {
		return mainApiInstence.post("/breakfast-types", data);
	}
	static breakfastTypesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/breakfast-types/${id}`, data);
	}
	static breakfastTypesDelete(id: number) {
		return mainApiInstence.delete(`/breakfast-types/${id}`);
	}

	// Languages types
	static staffLanguagesPost(data: any) {
		return mainApiInstence.post("/languages", data);
	}
	static staffLanguagesPatch(data: any, id: any) {
		return mainApiInstence.patch(`/languages/${id}`, data);
	}
	static staffLanguagesDelete(id: number) {
		return mainApiInstence.delete(`/languages/${id}`);
	}
}
