import {
	CreditCardOutlined,
	DollarCircleOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, message, Popconfirm, Row, Tag } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ReservationsApi } from "api/Reservations";
import BackButton from "components/shared-components/BackButton";
import Loading from "components/shared-components/Loading";
import { ReservationStatus } from "constants/ReservationStatus";
import { WantsConstants } from "constants/WantConstants";
import * as htmlToImage from "html-to-image";
import { t } from "i18n";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { MessagesService } from "services/Messages";
import { formatCurrency } from "utils/formatCurrency";
import { formatDateString } from "utils/formatDateString";
import { CardModal } from "./Components/CardModal";
import { ConcelationModal } from "./Components/ConcelationModal";

export default function ReservationItem({ role }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();
	const { id } = useParams();
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [openCard, setopenCard] = useState(false);
	const domEl = useRef(null);

	function datediff(first, second) {
		return Math.round((second - first) / (1000 * 60 * 60 * 24));
	}
	const confirm = () => {
		message.success(t("Status changed to active!"));
	};
	const handleOk = () => {
		setConfirmLoading(true);
		PropertyApi.cancelReservation(id, form.getFieldValue().reason)
			.then((data) => {
				MessagesService.success();
				setConfirmLoading(false);
				setOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				setConfirmLoading(false);
			});
	};

	const downloadImage = async () => {
		const dataUrl = await htmlToImage.toPng(domEl.current);

		// download image
		const link = document.createElement("a");
		link.download = `${
			data.customer.first_name +
			" " +
			data.customer.last_name +
			" " +
			data.checkin +
			" " +
			data.checkout
		}.png`;
		link.href = dataUrl;
		link.click();
	};

	useEffect(() => {
		setLoading(true);
		ReservationsApi.getReservation(role, id)
			.then(({ data }) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				message.error(t("error"));
			});
	}, []);

	const statusColor =
		data.status === 0
			? "red"
			: data.status === 1
			? "green"
			: data.status === 2 && "gold";

	if (loading) {
		return <Loading />;
	}
	return (
		<div>
			<Col span={24} sm={24} md={16} lg={16}>
				<Row
					style={{
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 15,
					}}
				>
					<div className="d-flex">
						<BackButton />
						<h2 className="m-0">{t("Reservation details")}</h2>
					</div>
					<div
						span={12}
						style={{ display: "flex", justifyContent: "end" }}
					>
						<Popconfirm
							placement="bottomRight"
							title={t("Вы хотите изменить свой статус?")}
							onConfirm={confirm}
							okText="Yes"
							cancelText="No"
						>
							<div className="d-flex align-items-center justify-content-end">
								<h5
									className="m-0"
									style={{ paddingRight: 10 }}
								>
									{t("Status")}:
								</h5>
								<Tag color={statusColor}>
									{t(ReservationStatus[data.status])}
								</Tag>
							</div>
						</Popconfirm>
					</div>
				</Row>
			</Col>

			<Row style={{ justifyContent: "space-between" }}>
				<Col span={24} sm={24} md={16} lg={16} ref={domEl}>
					<Card>
						<Row>
							<Col span={24} sm={24} md={12} lg={12}>
								<div>
									<p className="m-0">{t("Check-in")}</p>
									<h4>{formatDateString(data.checkin)}</h4>
								</div>
								<div>
									<p className="m-0">{t("Check-out")}</p>
									<h4>{formatDateString(data.checkout)}</h4>
								</div>

								<div>
									<p className="m-0">{t("Length of stay")}</p>
									<h5>
										{datediff(
											new Date(data.checkin),
											new Date(data.checkout)
										)}{" "}
										{t("nights")}
									</h5>
								</div>

								<div>
									<p className="m-0">{t("Total guests")}</p>
									<h5>
										{data?.records?.reduce(
											(accumulator, currentValue) => {
												return (
													accumulator +
													currentValue.guest_count
												);
											},
											0
										)}
									</h5>
								</div>

								<div>
									<p className="m-0">{t("Total price")}</p>
									<h4>{formatCurrency(data.total)}</h4>
								</div>
							</Col>
							<Col span={24} sm={24} md={12} lg={12}>
								<div>
									<p className="m-0">
										<i>{t("Guest name:")}</i>
									</p>
									<h4 className="my-1 d-flex align-items-center">
										<i>
											{data?.customer?.first_name +
												" " +
												data?.customer?.last_name}
										</i>
										<span
											style={{
												padding: "2px 4px",
												marginLeft: 10,
												borderRadius: 3,
												background:
													"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
												color: "#fff",
												fontSize: 12,
											}}
										>
											{t("Guest")}
										</span>
									</h4>
									<p className="m-0 d-flex align-items-center">
										<img
											src="https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg"
											alt="Uzbek flug"
											width={25}
											style={{
												marginRight: 5,
												borderRadius: 3,
											}}
										/>
										<span>{t("Uzbekistan")}</span>
									</p>
									<div>
										<a
											rel="noreferrer"
											href={`mailto:${data?.customer?.email}`}
											target={"_blank"}
										>
											{data?.customer?.email}
										</a>
									</div>
									<a
										href="tet:994551260"
										target={"_blank"}
										rel="noreferrer"
									>
										{data?.customer?.phone_number}
									</a>
								</div>

								{/* <div>
									<p className="m-0">
										{t("Preferred language")}
									</p>
									<h5>{t("Uzbek")}</h5>
								</div> */}

								{/* <Col span={12}>
										<p className="m-0">{t("Channel")}</p>
										<h5>{t("Uzbekhospitaliyt.uz")}</h5>
									</Col> */}

								<Col>
									<p className="m-0">{t("Booking number")}</p>
									<h5>{data.id}</h5>
								</Col>

								<Col span={12}>
									<p className="m-0">{t("Resived")}</p>
									<h5>
										{new Date(
											data.createdAt
										).toLocaleString("ru")}
									</h5>
								</Col>

								<Col>
									<p className="m-0">{t("Commission")}:</p>
									<h5>{formatCurrency(data?.comission)}</h5>
								</Col>
							</Col>
						</Row>
						<Row>
							<Col span={24} sm={24} md={24} lg={24}>
								<div>
									<p className="m-0">{t("Note")}:</p>
									<span>{data?.note}</span>
								</div>
							</Col>
							<Col span={24} sm={24} md={24} lg={24}>
								<p className="m-0">{t("Wants")}:</p>
								{data?.wants?.map((item, index) => {
									return (
										<span
											style={{
												display: "block",
											}}
											key={index}
										>
											- {WantsConstants[item]}
										</span>
									);
								})}
							</Col>
						</Row>
					</Card>

					<Card>
						<h2>{t("Payment")}</h2>
						<Row>
							<Col span={8}>
								<h4>{t("Payment type")}</h4>
							</Col>
							<Col>
								<span style={{ fontSize: 16 }}>
									{data.payment ? (
										<div className="d-flex align-items-center">
											<CreditCardOutlined />
											<span
												style={{
													margin: "0 10px 0 5px",
												}}
											>
												{" "}
												{t("Card")}{" "}
											</span>
											<Tag
												color="green"
												style={{
													fontWeight: 600,
												}}
											>
												{data.payment.paid
													? t("Paid")
													: t("Not Paid")}
											</Tag>
										</div>
									) : (
										<div className="d-flex align-items-center">
											<DollarCircleOutlined />
											<span
												style={{
													margin: "0 10px 0 5px",
												}}
											>
												{" "}
												{t("Onsite payment")}{" "}
											</span>
										</div>
									)}
								</span>
							</Col>
						</Row>
					</Card>
					<Card>
						{data?.records?.map((record, index) => {
							return (
								<>
									<Row
										key={index}
										style={{ margin: "10px 0" }}
										gap={5}
									>
										<Col span={16} sm={20} md={20} lg={20}>
											<h4>
												{getNameTranslation(
													record.room.room_type
												)}
											</h4>
											<Row
												style={{
													display: "dlex",
													justifyContent:
														"space-around",
												}}
											>
												<Col
													span={24}
													sm={8}
													md={8}
													lg={8}
												>
													<div className="d-flex align-items-center">
														{checkInIcon}
														<span
															style={{
																paddingLeft:
																	"5px",
															}}
														>
															{formatDateString(
																data.checkin
															)}
														</span>
													</div>
												</Col>
												<Col
													span={24}
													sm={8}
													md={8}
													lg={8}
												>
													<div className="d-flex align-items-center">
														{checkOutIcon}
														<span
															style={{
																paddingLeft:
																	"5px",
															}}
														>
															{formatDateString(
																data.checkout
															)}
														</span>
													</div>
												</Col>
												<Col
													span={24}
													sm={8}
													md={8}
													lg={8}
												>
													<div className="d-flex align-items-center">
														{breakfastIcon}
														<span
															style={{
																paddingLeft:
																	"5px",
															}}
														>
															{t("Breakfast")}
														</span>
													</div>
												</Col>
											</Row>
										</Col>
										<Col span={8} sm={4} md={4} lg={4}>
											<h4>
												{formatCurrency(record?.price)}
											</h4>
										</Col>
									</Row>
									<Row style={{ justifyContent: "start" }}>
										<Col span={8}>
											<Row
												style={{
													alignItems: "center",
												}}
											>
												<h5 className="m-0">
													{t("Guest name")}:{" "}
												</h5>
												<p
													className="m-0"
													style={{ paddingLeft: 5 }}
												>
													{" " + record.guest_name}
												</p>
											</Row>
										</Col>
										<Col span={8}>
											<Row
												style={{
													alignItems: "center",
												}}
											>
												<h5 className="m-0">
													{t("Guest count")}:{" "}
												</h5>
												<p
													className="m-0"
													style={{ paddingLeft: 5 }}
												>
													{" " + record.occupancy}
												</p>
											</Row>
										</Col>
									</Row>
								</>
							);
						})}
					</Card>
				</Col>
				<Col span={24} sm={24} md={7} lg={7}>
					<Card>
						<h5>{t("Reservation actions")}</h5>
						{data.status > 0 ? (
							<Button
								type="primary"
								ghost
								style={{ width: "100%" }}
								onClick={() => setOpen(true)}
							>
								{t("Request to cancel reservation")}
							</Button>
						) : (
							""
						)}

						<div style={{ marginTop: 10 }}>
							{/* <h5>{t("Payments")}</h5>
							<Button
								type="primary"
								ghost
								style={{ width: "100%", marginBottom: 10 }}
								icon={<EyeOutlined />}
								onClick={() => setopenCard(true)}
							>
								{t("View credit card details")}
							</Button> */}
							<Row style={{ alignItems: "center" }}>
								<Tag color="#87d068">{t("Resived")}</Tag>
								<span style={{ fontSize: 12 }}>
									{new Date(data.createdAt).toLocaleString(
										"ru"
									)}
								</span>
							</Row>
							{/* <Alert
								style={{ marginTop: 10 }}
								type="warning"
								showIcon
								message={
									<span style={{ fontSize: 12 }}>
										{t(
											"You can view the credit card details 3 more times with in the next 12 days"
										)}
									</span>
								}
								closable
							/> */}
						</div>

						<div style={{ marginTop: 10 }}>
							<Button
								type="primary"
								ghost
								style={{ width: "100%", marginBottom: 10 }}
								icon={<DownloadOutlined />}
								onClick={downloadImage}
							>
								{t("Download")}
							</Button>
						</div>
					</Card>
				</Col>
			</Row>

			<ConcelationModal
				open={open}
				setOpen={setOpen}
				form={form}
				confirmLoading={confirmLoading}
				handleOk={handleOk}
			></ConcelationModal>

			<CardModal
				openCard={openCard}
				setopenCard={setopenCard}
			></CardModal>
		</div>
	);
}

const checkInIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="18"
		height="18"
	>
		<path fill="none" d="M0 0h24v24H0z" />
		<path d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z" />
	</svg>
);
const checkOutIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="18"
		height="18"
	>
		<path fill="none" d="M0 0h24v24H0z" />
		<path d="M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5zm13-6v-3h-7v-2h7V8l5 4-5 4z" />
	</svg>
);
const breakfastIcon = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width="18"
		height="18"
	>
		<path fill="none" d="M0 0h24v24H0z" />
		<path d="M14.268 12.146l-.854.854 7.071 7.071-1.414 1.414L12 14.415l-7.071 7.07-1.414-1.414 9.339-9.339c-.588-1.457.02-3.555 1.62-5.157 1.953-1.952 4.644-2.427 6.011-1.06s.892 4.058-1.06 6.01c-1.602 1.602-3.7 2.21-5.157 1.621zM4.222 3.808l6.717 6.717-2.828 2.829-3.89-3.89a4 4 0 0 1 0-5.656zM18.01 9.11c1.258-1.257 1.517-2.726 1.061-3.182-.456-.456-1.925-.197-3.182 1.06-1.257 1.258-1.516 2.727-1.06 3.183.455.455 1.924.196 3.181-1.061z" />
	</svg>
);
