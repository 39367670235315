import { Table } from "antd";
import { AntTableSortMapRevert } from "../properties/AllProperties/property.const";

export default function TableComponent({
	columns,
	data,
	filter,
	setFilter,
	fetch,
	loading,
}) {
	return (
		<>
			<Table
				rowKey={"id"}
				columns={columns}
				dataSource={data.items}
				onChange={(p, _, sort) => {
					const f = {
						...filter,
						page: p.current,
						limit: p.pageSize,
						order: AntTableSortMapRevert[sort.order],
					};
					setFilter(f);
					fetch(f);
				}}
				scroll={{
					x: 1200,
				}}
				pagination={{
					current: filter.page,
					total: data.total,
					pageSize: filter.limit,
				}}
				loading={loading}
			/>
		</>
	);
}
