import { EditOutlined, StarFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnType } from "antd/es/table/interface";
import { t } from "i18n";
import { DefaultRecordType } from "rc-table/lib/interface";
import React from "react";
import { Link, match } from "react-router-dom";
import { AntTableSortMap, PropertyFilter } from "./property.const";
import { PropertyStatusComponent } from "./PropertyStatus";

export function getPropertyColumns(match: match, filter: PropertyFilter) {
	const columns: ColumnType<DefaultRecordType>[] = [
		{
			title: `${t("Id")}`,
			dataIndex: "id",
			key: "id",
			width: 50,
			fixed: "left",
			sorter: true,
		},
		{
			title: `${t("Name")}`,
			dataIndex: "name",
			key: "name",
			fixed: "left",

			render: (_, record) => {
				return (
					<>
						<h5 className="m-0 p-0">{record.name}</h5>
						{/* <p className="m-0 p-0">
                            <b>Legal name:</b> {record.legal_name}
                        </p> */}
					</>
				);
			},
			width: 200,
			sorter: true,
		},
		{
			title: `${t("Star")}`,
			dataIndex: "star",
			key: "star",
			width: 80,
			render: (_, record) => {
				return record.star === 0 ? (
					"-"
				) : (
					<>
						{record.star} <StarFilled />
					</>
				);
			},
			sorter: true,
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			key: "status",
			width: 130,
			sorter: true,

			render: (status) => {
				return <PropertyStatusComponent status={status} />;
			},
		},
		{
			title: `${t("Contact name")}`,
			dataIndex: "contact_name",
			key: "contact_name",
			width: 150,
		},
		{
			title: `${t("Phone number")}`,
			dataIndex: "phone_number",
			key: "phone_number",
			width: 200,
			render: (phone, record) => {
				return (
					<>
						{phone}
						<br />
						{record.extra_phone_number}
					</>
				);
			},
		},

		{
			title: `${t("Address")}`,
			dataIndex: "address",
			key: "address",
			width: 250,
			render: (_, record) => {
				return (
					<>
						<b>{record?.address?.city?.name}</b>,{" "}
						{record?.address?.addressLine}
					</>
				);
			},
		},
		{
			title: `${t("Category")}`,
			dataIndex: "category",
			key: "category",
			width: 200,
			render: (_, record) => {
				return (
					<span className="m-0 p-0 d-block">
						{record.category.name}
					</span>
				);
			},
		},
		{
			title: `${t("Rating")}`,
			dataIndex: "rating",
			key: "rating",
			width: 80,
			sorter: true,

			render: (_, record) => {
				return (
					<span className="m-0 p-0 d-block">
						{Math.round(record.rating)}
					</span>
				);
			},
		},
		{
			title: `${t("Percent")}`,
			dataIndex: "percentage",
			key: "percentage",
			width: 90,
			sorter: true,
		},

		{
			title: `${t("Created")}`,
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value) => {
				return new Date(value).toLocaleString("ru");
			},
			width: 150,
			sorter: true,
		},
		{
			title: `${t("Updated")}`,
			dataIndex: "updatedAt",
			key: "updatedAt",
			render: (value) => {
				return new Date(value).toLocaleString("ru");
			},
			width: 150,
			sorter: true,
		},
		{
			title: "",
			dataIndex: "id",
			key: "operations",
			render: (id) => {
				return (
					<Link to={`${match.path}/${id}/main`}>
						<Button
							size="small"
							className="m-0"
							icon={<EditOutlined />}
						/>
					</Link>
				);
			},
			fixed: "right",
			width: 80,
		},
	];

	return columns.map((c) => {
		if (filter.order_by && c.dataIndex === filter.order_by) {
			c.sortOrder = AntTableSortMap[filter.order];
		}

		return c;
	});
}
