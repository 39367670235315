import { Card, Col, Rate, Row } from "antd";
import mainApiInstence from "api/mainApiInstence";
import BackButton from "components/shared-components/BackButton";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropertyMenu from "./PropertyMenu";
import PropertyMenuLayout from "./PropertyMenuLayout";
import { PropertyStatusComponent } from "./PropertyStatus";

export default function PropertyItem({ match }) {
	const { id } = useParams();
	const [property, setProperty] = useState();
	const fetchProperty = () => {
		mainApiInstence
			.get(`/properties/${id}`)
			.then(({ data }) => {
				setProperty(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		fetchProperty();
	}, [id]);

	if (!property) {
		return "";
	}

	return (
		<>
			<Card>
				<div className="d-flex justify-content-between align-items-center">
					<h2 className="m-0 d-flex align-items-center">
						<BackButton />
						<b>{property.name} </b>
						<Rate
							disabled
							defaultValue={property.star}
							key={property.star}
							style={{
								color: "#000",
								fontSize: 16,
								marginBottom: 2,
								marginLeft: 10,
							}}
						/>
					</h2>
					<PropertyStatusComponent status={property.status} />
				</div>
			</Card>
			<Row
				className="propety-content"
				gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
			>
				<Col span={5}>
					<PropertyMenu match={match} />
				</Col>
				<Col span={19}>
					<PropertyMenuLayout
						match={match}
						setProperty={setProperty}
					/>
				</Col>
			</Row>
		</>
	);
}
