import { Card, Col, Row, Table, Typography } from "antd";
import { t } from "i18n";
import React from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { AviablityPopup } from "./AviablityPopup";
import { DataType } from "./BookingTopCalendar";
import BulkEditModal from "./BulkEditModal";
import { EditPriceModal } from "./EditPriceModal";
import { getRateRows, netBooked, roomStatusMap, roomToSellMap } from "./utils";

function BookingCalendar({
	data,
	fetchCalendar,
	loading,
	showPricePerGuest,
}: DataType) {
	return (
		<Card>
			{data.rooms.map((room: any) => {
				return (
					<div key={room.id}>
						<Typography.Title level={5}>
							<Row
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Col style={{ margin: 0 }}>
									<>
										{getNameTranslation(room)}{" "}
										<span>(Room ID: {room.id})</span>
									</>
								</Col>
								<Col>
									<BulkEditModal room={room} />
								</Col>
							</Row>
						</Typography.Title>
						<Table
							loading={loading}
							bordered
							pagination={false}
							columns={[
								{
									dataIndex: "name",
									fixed: "left",
								},
								...data.date_range.map((data: any) => ({
									dataIndex: data,
									render: (_: any) => (_ ? _ : ""),
								})),
							]}
							scroll={{ x: 500 }}
							dataSource={[
								{
									id: "status",
									name: t("Room status"),
									...roomStatusMap(room, data.date_range),
								},
								{
									id: "rsell",
									name: t("Rooms to Sell"),
									...roomToSellMap(room, data.date_range),
								},

								{
									id: "net",
									name: t("Net Booked"),
									...netBooked(data.date_range, room.dates),
								},
								...getRateRows(data, room, showPricePerGuest),
							]}
							rowKey="id"
							className="sync-scroll"
							showHeader={false}
						/>
					</div>
				);
			})}
			<AviablityPopup />
			<EditPriceModal fetchCalendar={fetchCalendar} />
		</Card>
	);
}

export default BookingCalendar;
