import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { LegalDataApi } from "api/LegalDataApi";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";

export default function LegalData() {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	useEffect(() => {
		setLoading(true);
		LegalDataApi.getLegalPropertys()
			.then(({ data }) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	const onFinish = (values) => {
		setLoading(true);
		LegalDataApi.editLegalDataProperty(data?.id, {
			tin: String(form.getFieldValue().tin),
			name: form.getFieldValue().name,
			account: String(form.getFieldValue().account),
			mfi: String(form.getFieldValue().mfi),
			okonx: String(form.getFieldValue().okonx),
			region: form.getFieldValue().region,
			phone_number: form.getFieldValue().phone_number,
			bank_name: form.getFieldValue().bank_name,
			status: form.getFieldValue().status,
		})
			.then(({ data }) => {
				MessagesService.success(t("Save"));
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				MessagesService.error(t("Error legal data"));
				setLoading(false);
			});
	};
	if (loading) {	
		return <Loading />;
	}

	return (
		<Card>
			<h2>{t("Legal data")}</h2>
			<Form form={form} initialValues={data} onFinish={onFinish}>
				<Row gutter={10}>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"status"}
							label={t("Status")}
							labelCol={{
								span: 24,
							}}
						>
							<Select placeholder={t("Select status")}>
								<Select.Option value={0}>
									{t("Disactive")}
								</Select.Option>
								<Select.Option value={1}>
									{t("Active")}
								</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"name"}
							label={t("Full name of the accommodation")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please input the name of the property"
									),
								},
							]}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							label={t("Phone Number")}
							name={"phone_number"}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									len: 13,
									message: `${t(
										"Please input your Phone Number!"
									)}`,
								},
							]}
						>
							<PhoneNumberWithPrefix />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"tin"}
							label={t("STIR")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please input the STIR of the business"
									),
								},
								{
									validator: (rule, value) => {
										if (
											value &&
											value.toString().length !== 9
										) {
											return Promise.reject(
												t("STIR must be 9 digits")
											);
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								maxLength={9}
								placeholder={"XXXXXXXXX"}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"okonx"}
							label={t("IFUT code")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please input the IFUT code of the business"
									),
								},
								{
									validator: (rule, value) => {
										if (
											value &&
											value.toString().length !== 5
										) {
											return Promise.reject(
												t("IFUT code must be 9 digits")
											);
										}
										return Promise.resolve();
									},
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								maxLength={5}
								placeholder={"XXXXX"}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"mfi"}
							label={t("Bank code (MFO)")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please input the bank code (MFO)"
									),
								},
							]}
						>
							<InputNumber
								style={{ width: "100%" }}
								placeholder="XXXXX"
								maxLength={5}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"account"}
							label={t("Current account number")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please select the current account number"
									),
								},
							]}
						>
							<Input
								style={{ width: "100%" }}
								placeholder="XXXXXXXXXXXXXXXXXXXX"
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"bank_name"}
							label={t("Bank name")}
							labelCol={{
								span: 24,
							}}
						>
							<Input placeholder={t("Bank name")} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Button htmlType="submit" type="primary">
							{t("Save")}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
}
