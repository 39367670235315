import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, message, Row } from "antd";
import { RoomsApi } from "api/RoomsApi";
import Loading from "components/shared-components/Loading";
import { NO_IMAGE_URL } from "constants/any";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RoomItem from "./components/RoomItem";

const RoomDetails = () => {
	const history = useHistory();
	const [rooms, setRooms] = useState([]);
	const [loading, setloading] = useState(false);
	useEffect(() => {
		setloading(true);
		RoomsApi.get()
			.then((data) => {
				setRooms(data.data.items);
				setloading(false);
			})
			.catch((err) => {
				setloading(false);
			});
	}, []);

	function DeletePlane(id) {
		setloading(true);
		RoomsApi.delete(id)
			.then(() => {
				setRooms((room) => room.filter((room) => room.id !== id));
				message.success(t("Deleted successfuly!"));
				setloading(false);
			})
			.catch((err) => {
				console.log(err);
				setloading(false);
				message.error(t("error"));
			});
	}
	if (loading) {
		return <Loading />;
	}
	return (
		<div>
			<div className="d-flex align-items-center justify-content-between w-100 mb-4">
				<h2>{t("Room details")}</h2>
				<Button
					type="primary"
					onClick={() => {
						history.push("/app/room-create");
					}}
					icon={<PlusCircleFilled />}
				>
					{t("Create new room")}
				</Button>
			</div>
			<Row justify="between" gutter={20}>
				{rooms.map((item, index) => {
					return (
						<Col xs={24} sm={12} md={8} lg={8} key={index}>
							<RoomItem
								cover={item?.photo?.path || NO_IMAGE_URL}
								id={item.id}
								data={item}
								DeletePlane={DeletePlane}
							/>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

export default RoomDetails;
