import { Button, Col, Form, message, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BasicInfo from "./components/BasicInfo";

import { PropertyApi } from "api/PropertyApi";
import Footer from "components/layout-components/Footer";
import { t } from "i18n";
import { useHistory } from "react-router-dom";
import { MessagesService } from "services/Messages";
import Navbar from "../components/Navbar";
import Amenities from "./components/Amenities";
import FacilitiesServices from "./components/FacilitiesServices";
import HotelCategory from "./components/HotelCategory";
import LayoutInfo from "./components/LayoutInfo";
import Payments from "./components/Payments";
import Photos from "./components/Photos";
import Politics from "./components/Politics";
// import UploadPictures from "./components/TestPhotos";

const RegistrationLocalStorageService = {
	setFormData: (data) => localStorage.setItem("reg", JSON.stringify(data)),
	getFormData: () => JSON.parse(localStorage.getItem("reg") || "{}"),
};

const PropertyAbout = () => {
	// States:
	const { Step } = Steps;
	const oldData = RegistrationLocalStorageService.getFormData();
	const [current, setCurrent] = useState(oldData.step ? oldData.step : 0);
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [rooms, setRooms] = useState(oldData.rooms ? oldData.rooms : []);
	const [form] = Form.useForm();

	useEffect(() => {
		RegistrationLocalStorageService.setFormData({
			...form.getFieldValue(),
			step: current,
			rooms: rooms,
		});
	}, [current, rooms.length]);

	useEffect(() => {
		form.setFieldsValue(oldData);
	}, []);

	const onFinish = (values) => {
		form.validateFields()
			.then(async () => {
				form.setFieldValue(["layout", "rooms"], rooms);
				const data = form.getFieldValue();
				setLoading(true);
				await PropertyApi.create({
					...data,
					facilities: {
						...data.facilities,
						facilities: data.facilities.facilities.map((id) => {
							return {
								facility_id: id,
								paid: false,
							};
						}),
					},
					photos: data.photos.map((item) => item.response.id),
				})
					.then(() => {
						MessagesService.success();
						RegistrationLocalStorageService.setFormData({});
						history.push("/app/property/thanks");
					})
					.catch((err) => {
						Object.entries(err.response.data.errors).map((item) => {
							return MessagesService.error(
								item[0] + " " + item[1]
							);
						});

						setLoading(false);
					})
					.finally(() => {
						setLoading(false);
						localStorage.removeItem("reg");
					});
			})
			.catch((info) => {
				MessagesService.error(t("There are gaps in data entry"));
				console.log("Validate Failed:", info);
				setLoading(false);
			});
	};

	// Steps:
	const steps = [
		{
			title: `${t("Select Category")}`,
			content: (
				<HotelCategory
					user={user}
					setCurrent={setCurrent}
					current={current}
					form={form}
				/>
			),
		},
		{
			title: `${t("Basic info")}`,
			content: (
				<BasicInfo
					user={user}
					setCurrent={setCurrent}
					current={current}
					form={form}
				/>
			),
		},
		{
			title: `${t("Layout")}`,
			content: (
				<LayoutInfo
					form={form}
					user={user}
					setCurrent={setCurrent}
					current={current}
					setRooms={setRooms}
					rooms={rooms}
				/>
			),
		},
		{
			title: `${t("Facilities")}`,
			content: (
				<FacilitiesServices
					user={user}
					setCurrent={setCurrent}
					current={current}
				/>
			),
		},
		{
			title: `${t("Amenities")}`,
			content: <Amenities setCurrent={setCurrent} current={current} />,
		},
		{
			title: `${t("Photos")}`,
			content: <Photos form={form} />,
		},
		{
			title: `${t("Policies")}`,
			content: (
				<Politics
					setCurrent={setCurrent}
					current={current}
					initialValues={{}}
					isRegistration={true}
				/>
			),
		},
		{
			title: `${t("Payments")}`,
			content: <Payments setCurrent={setCurrent} current={current} />,
		},
	];

	const backgroundStyle = {
		background: "#fefefe",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	};
	return (
		<div className="h-100" style={backgroundStyle}>
			<Navbar />
			<div className="d-flex flex-column justify-content-center h-100">
				<Row justify="center" className="mt-4">
					<Col xs={24} sm={24} md={24} lg={24}>
						<Steps
							className="site-navigation-steps p-4"
							onChange={(_current) => {
								if (_current < current) {
									return setCurrent(_current);
								}
								if (current + 1 < _current) return;
								form.validateFields()
									.then(() => {
										setCurrent(_current);
									})
									.catch(() => {
										message.warn(
											t("Please fill required fields")
										);
									});
							}}
							type="inline"
							current={current}
							labelPlacement="vertical"
						>
							{steps.map((item) => (
								<Step key={item.title} title={item.title} />
							))}
						</Steps>

						<div className="steps-content">
							<Form
								name="basic-info"
								form={form}
								layout="vertical"
								onFinish={onFinish}
								autoComplete="off"
							>
								{current !== 2 && steps[current].content}
							</Form>
							{current === 2 && steps[current].content}

							<div className="steps-action mt-4 d-flex justify-content-center">
								<Button
									className="m-1"
									disabled={current === 0}
									onClick={() => setCurrent(current - 1)}
									size="large"
								>
									{t("Prev")}
								</Button>
								{current === steps.length - 1 ? (
									<Button
										type="primary"
										className="m-1"
										htmlType="submit"
										onClick={form.submit}
										size="large"
										loading={loading}
									>
										{t("Finish")}
									</Button>
								) : (
									<Button
										className="m-1"
										type="primary"
										size="large"
										disabled={current === steps.length - 1}
										onClick={() => {
											form.validateFields()
												.then((data) => {
													if (
														current != 2 ||
														(current === 2 &&
															rooms.length > 0)
													) {
														setCurrent(current + 1);
													} else {
														message.warn(
															t("Create room")
														);
													}
												})
												.catch(() => {
													message.warn(
														t(
															"Please fill required fields"
														)
													);
												});
										}}
									>
										{t("Next")}
									</Button>
								)}
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<div className="mt-4">
				<Footer />
			</div>
		</div>
	);
};

export default PropertyAbout;
