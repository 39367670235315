import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	Card,
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Tooltip,
} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import mainApiInstence from "api/mainApiInstence";
import { ResourceApi } from "api/ResourceApi";
import MapIcon from "assets/images/mapIcon";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import Loading from "components/shared-components/Loading";
import { HotelStatus } from "constants/HotelStatus";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import Chat from "services/Chat";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { MessagesService } from "services/Messages";
import MapModal from "views/app-views/property/map/MapModal";

function openMap() {
	Chat.send({ open: true }, "map");
}
export default function Main({ id, setProperties }) {
	const [property, setProperty] = useState();
	const [categories, setCategories] = useState();
	const [regions, setRegions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	useEffect(() => {
		mainApiInstence
			.get(`/properties/${id}`)
			.then(({ data }) => setProperty(data))
			.catch((error) => {
				console.log(error);
			});
		mainApiInstence
			.get(`/property/categories`)
			.then(({ data }) => setCategories(data.items))
			.catch((error) => {
				console.log(error);
			});
	}, [id]);

	function onChange(data) {
		form.setFieldValue(["address", "latitude"], +data.latitude);
		form.setFieldValue(["address", "longitude"], +data.longitude);
		form.setFieldValue(["address", "addressLine"], data.addressLine1);
	}

	useEffect(() => {
		ResourceApi.getList("regions", { limit: 50 }).then((r) => {
			setRegions(r.items);
		});
	}, []);

	const onFinish = () => {
		setLoading(true);
		mainApiInstence
			.patch(`/properties/${id}`, form.getFieldValue())
			.then(({ data }) => {
				MessagesService.success();
				setLoading(false);
				setProperties(data);
			})
			.catch((err) => {
				MessagesService.error();
				setLoading(false);
				console.log(err);
			});
	};

	const onModeration = (key, data) => {
		setLoading(true);
		const datas = new Object();
		datas[key] = data;
		mainApiInstence
			.patch(`/properties/${id}`, datas)
			.then(({ data }) => {
				MessagesService.success();
				setProperty(data);
				setLoading(false);
			})
			.catch((err) => {
				MessagesService.error();
				setLoading(false);
				console.log(err);
			});
	};
	if (!(property && categories)) {
		return <Loading />;
	}
	return (
		<Card>
			<Form
				name="edit"
				layout="vertical"
				initialValues={property}
				form={form}
				onFinish={onFinish}
			>
				<Row justify="between" className="justify-content-between">
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							name={"name"}
							label="Property name"
							style={{ color: "red", margin: 0, marginBottom: 5 }}
							labelCol={{
								span: 24,
							}}
						>
							<Input className="p-2" />
						</Form.Item>
						{property?.moderation_data?.name ? (
							<Alert
								message={
									<Tooltip
										placement="topLeft"
										title={t(
											"Please check and confirm the property name moderated request"
										)}
									>
										<Row
											style={{
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Col>
												<span>
													{
														property.moderation_data
															.name
													}
												</span>
											</Col>
											<Col>
												<ButtonGroup
													style={{
														display: "flex",
														alignItems: "center",
														gap: 8,
													}}
												>
													<Button
														type="link"
														style={{ padding: 0 }}
														onClick={() =>
															onModeration(
																"name",
																property
																	.moderation_data
																	.name
															)
														}
													>
														<CheckCircleOutlined
															style={{
																color: "green",
																fontSize: 18,
															}}
														/>
													</Button>
													<Button
														type="link"
														style={{ padding: 0 }}
														onClick={() =>
															onModeration(
																"name",
																property.name
															)
														}
													>
														<CloseCircleOutlined
															style={{
																color: "red",
																fontSize: 18,
															}}
														/>
													</Button>
												</ButtonGroup>
											</Col>
										</Row>
									</Tooltip>
								}
								type="warning"
								showIcon
								style={{ marginBottom: 10 }}
							/>
						) : (
							""
						)}
					</Col>
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item name="status" label="Status">
							<Select
								options={Object.entries(HotelStatus).map(
									(entire) => ({
										label: t(entire[1]),
										value: parseInt(entire[0]),
									})
								)}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							label={t("Category name")}
							name="category_id"
							labelCol={{
								span: 24,
							}}
						>
							<Select
								placeholder={t("Select category")}
								options={categories.map((item) => ({
									label: getNameTranslation(item),
									value: item.id,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							label={t("Star rating")}
							name={["star"]}
							labelCol={{
								span: 24,
							}}
						>
							<Select placeholder="Star rating">
								<Select.Option value={0}>
									{t("No rating")}
								</Select.Option>
								<Select.Option value={1}>1 ✯</Select.Option>
								<Select.Option value={2}>2 ✯✯</Select.Option>
								<Select.Option value={3}>3 ✯✯✯</Select.Option>
								<Select.Option value={4}>4 ✯✯✯✯</Select.Option>
								<Select.Option value={5}>5 ✯✯✯✯✯</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							label={t("Phone Number")}
							name={["phone_number"]}
							labelCol={{
								span: 24,
							}}
						>
							<PhoneNumberWithPrefix />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							label={t("Alternative phone number (optional)")}
							name={["extra_phone_number"]}
							labelCol={{
								span: 24,
							}}
						>
							<PhoneNumberWithPrefix />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={11}>
						<Form.Item
							label={t("Contact name")}
							name={["contact_name"]}
							labelCol={{
								span: 24,
							}}
						>
							<Input
								className="p-2"
								placeholder="Who will receive the letter?
"
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={20} lg={11}>
						<Form.Item name={["address", "id"]} hidden>
							<InputNumber />
						</Form.Item>
						<Form.Item
							name={["address", "city_id"]}
							label={t("Region")}
							labelCol={{
								span: 24,
							}}
						>
							<Select
								placeholder={t("Region")}
								options={regions.map((r) => ({
									label: r.name,
									value: r.id,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={20} lg={11}>
						<Form.Item
							name={["address", "addressLine"]}
							label={t("address")}
							labelCol={{
								span: 24,
							}}
						>
							<Input className=" p-2" />
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={20} lg={11}>
						<Form.Item
							name={["address", "addressLine2"]}
							label={t("Address line 2")}
							labelCol={{
								span: 24,
							}}
						>
							<Input className="p-2" />
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={20} lg={11}>
						<Form.Item
							label={t("Map your property")}
							rules={[
								{
									required: true,
									message: "",
								},
							]}
						>
							<Button
								type="primary"
								ghost
								onClick={openMap}
								size="middle"
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<MapIcon />
								<span style={{ marginLeft: 10 }}>
									{t("Use Map")}
								</span>
							</Button>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={20} lg={11}>
						<Form.Item className="text-left">
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
							>
								{t("Save")}
							</Button>
						</Form.Item>
					</Col>
					<Form.Item name={["address", "country"]} hidden />
					<Form.Item name={["address", "id"]} hidden />
				</Row>
			</Form>
			<MapModal
				onChange={onChange}
				value={[property.address.latitude, property.address.longitude]}
			/>
		</Card>
	);
}
