import { Menu } from "antd";
import { t } from "i18n";
import React, { useState } from "react";
import { Link } from "react-router-dom";
function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label,
	};
}
const PropertyMenu = ({ match }) => {
	const [current, setCurrent] = useState("1");
	const onClick = (e) => {
		setCurrent(e.key);
	};
	const items = [
		getItem(<Link to={match.url + "/main"}>{t("Main")}</Link>, "1"),

		getItem(<Link to={match.url + "/calendar"}>{t("Calendar")}</Link>, "2"),
		getItem(<Link to={match.url + "/rates"}>{t("Rate plans")}</Link>, "3"),
		getItem(
			<Link to={match.url + "/facilities"}>{t("Facilities")}</Link>,
			"5"
		),
		getItem(<Link to={match.url + "/photos"}>{t("Photos")}</Link>, "4"),
		getItem(<Link to={match.url + "/rooms"}>{t("Rooms")}</Link>, "6"),
		getItem(
			<Link to={match.url + "/room-amenities"}>
				{t("Room amenities")}
			</Link>,
			"7"
		),
		getItem(
			<Link to={match.url + "/nearby-places"}>{t("Nearby places")}</Link>,
			"8"
		),
		getItem(
			<Link to={match.url + "/legal-data"}>{t("Legal data")}</Link>,
			"9"
		),
	];
	return (
		<Menu
			onClick={onClick}
			style={{
				width: "100%",
			}}
			defaultOpenKeys={["sub1"]}
			selectedKeys={[current]}
			items={items}
		/>
	);
};
export default PropertyMenu;
