import { Card, Col, Form, Input, Row } from "antd";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";

export default function BillingDetails() {
	const [regions, setRegions] = useState([]);

	useEffect(() => {
		ResourceApi.getList("regions", { limit: 100 }).then((r) => {
			setRegions(r.items);
		});
	}, []);

	return (
		<Card>
			<Row gutter={10}>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"mfi"}
						label={t("Bank code (MFO)")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t("Please input the bank code (MFO)"),
								pattern: new RegExp("^[0-9]*$"),
							},
						]}
					>
						<Input
							style={{ width: "100%" }}
							placeholder="XXXXX"
							maxLength={5}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"account"}
						label={t("Current account number")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please select the current account number"
								),
								pattern: new RegExp("^[0-9]*$"),
							},
						]}
					>
						<Input
							style={{ width: "100%" }}
							placeholder="XXXXXXXXXXXXXXXXXXXX"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"bank_name"}
						label={t("Bank name")}
						labelCol={{
							span: 24,
						}}
					>
						<Input placeholder={t("Bank name")} />
					</Form.Item>
				</Col>
			</Row>
		</Card>
	);
}
