import { CalendarApi } from "api/CalendarApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CalendarActions from "redux/calendar/calendar.actions";
import { getCalendarState } from "redux/calendar/calendar.selector";
import Chat from "services/Chat";
import { havePriceActive, mergeUpdatedDate, updateStatus } from "utils/array";
import { isSunday } from "utils/date";

function isLeftFull(currentDateRecord: any, prevDateRecord: any) {
	return prevDateRecord && currentDateRecord.active === prevDateRecord.active;
}

function isRightFull(currentDateRecord: any, nextDateRecord: any) {
	return nextDateRecord && currentDateRecord.active === nextDateRecord.active;
}

export function StatusCell({
	room,
	dates,
	dateIndex,
}: {
	room?: any;
	dates?: any;
	dateIndex: number;
}) {
	const record = room.dates;
	const currentdate = dates[dateIndex];
	const currentDateRecord = record[currentdate] || { active: false };
	const prevDateRecord = record[dates[dateIndex - 1]] || { active: false };
	const nextDateRecord = record[dates[dateIndex + 1]] || { active: false };
	const calendar = getCalendarState();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState({
		loadingIndex: currentdate,
		loading: false,
	});

	const openApopup = (data: any) => {
		const isOpen = havePriceActive(data);

		if (!isOpen || !isOpen?.active || isOpen?.price < 1 || data.count < 1) {
			Chat.send(
				{ ...data, dayInfo: isOpen, open: true, loading, setLoading },
				"aviability_popup"
			);
		} else {
			setLoading({ ...loading, loading: true });
			CalendarApi.update(
				updateStatus(!data.active, {
					...data,
					from_date: currentdate,
					until_date: currentdate,
				})
			)
				.then((res) => {
					const calendar_data = mergeUpdatedDate(calendar.data, res);
					dispatch(CalendarActions.updateData(calendar_data));
					setLoading({ ...loading, loading: false });
				})
				.catch((err) => {
					console.log(err);
					setLoading({ ...loading, loading: false });
				});
		}
	};
	return (
		<div
			onClick={() =>
				openApopup({
					...record[currentdate],
					room: room,
					currentdate,
				})
			}
			className={`head${currentDateRecord.active ? "" : " unavailable"}${
				isLeftFull(currentDateRecord, prevDateRecord) ? " end" : ""
			}${
				isRightFull(currentDateRecord, nextDateRecord) ? " start" : ""
			} ${isSunday(new Date(currentdate)) ? "border-right" : ""} `}
		>
			{loading.loadingIndex === currentdate && loading.loading ? (
				<Loading size={20} />
			) : (
				<div className="status">
					{currentDateRecord.active ? t("Bookable") : t("Closed")}
				</div>
			)}
		</div>
	);
}
