import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { ResourceApi } from "api/ResourceApi";
import MapIcon from "assets/images/mapIcon";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import Chat from "services/Chat";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import MapModal from "views/app-views/property/map/MapModal";

function openMap() {
	Chat.send({ open: true }, "map");
}

export default function BasicInfo({ user, form }) {
	const [regions, setRegions] = useState([]);

	function onChange(data) {
		form.setFieldValue(
			["basic_info", "address", "latitude"],
			+data.latitude
		);
		form.setFieldValue(
			["basic_info", "address", "longitude"],
			+data.longitude
		);
		form.setFieldValue(
			["basic_info", "address", "addressLine"],
			data.addressLine1
		);
	}

	useEffect(() => {
		ResourceApi.getList("regions", { limit: 100 }).then((r) => {
			setRegions(r.items);
		});
	}, []);

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-3 mt-2">
							{t("Welcome")}{" "}
							{user.first_name + " " + user.last_name}!
						</h2>
						<p>{t("Start by telling")}</p>
						<Card className="my-3">
							<div className="my-0 p-2">
								<h3>
									{t("What is the name of your property?!")}
								</h3>
								<Row justify="space-between">
									<Col xs={24} sm={24} md={12} lg={12}>
										<Form.Item
											name={[
												"basic_info",
												"name_property",
											]}
											label={t("Property name!")}
											style={{ color: "red" }}
											labelCol={{
												span: 24,
											}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
											rules={[
												{
													required: true,
													message: `${t(
														"Property name is required!"
													)}`,
												},
												{
													min: 5,
													message: `${t(
														"Property name be longer than 5 characters!"
													)}`,
												},
											]}
										>
											<Input className="p-2" />
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={11} lg={11}>
										<Form.Item
											label={t("Star rating")}
											name={["basic_info", "star"]}
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Star is required"
													)}`,
												},
											]}
										>
											<Select placeholder="Star rating">
												<Select.Option value={0}>
													{t("No rating")}
												</Select.Option>
												<Select.Option value={1}>
													1 ✯
												</Select.Option>
												<Select.Option value={2}>
													2 ✯✯
												</Select.Option>
												<Select.Option value={3}>
													3 ✯✯✯
												</Select.Option>
												<Select.Option value={4}>
													4 ✯✯✯✯
												</Select.Option>
												<Select.Option value={5}>
													5 ✯✯✯✯✯
												</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
						<Card className="my-3">
							<div className="my-0 p-3">
								<h3>{t("contact details")}</h3>
								<Row justify="start">
									<Col xs={24} sm={24} md={12} lg={12}>
										<Form.Item
											label={t("Contact name")}
											name={[
												"basic_info",
												"contact_name",
											]}
											labelCol={{
												span: 24,
											}} // tooltip="Siz foydalanadigan emailni kiriting biz tastiqlash kod jo'natamiz!"
											rules={[
												{
													required: true,
													message: `${t(
														"Oops! Please fill in the contact name"
													)}`,
												},
												{
													min: 5,
													message: `${t(
														"Property name be longer than 5 characters!"
													)}`,
												},
											]}
										>
											<Input
												className="p-2"
												placeholder={t(
													"Who will receive the letter?"
												)}
											/>
										</Form.Item>
										<p>{t("Contact number")}</p>
										<Row
											justify="between"
											className="justify-content-between"
										>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={11}
											>
												{" "}
												<Form.Item
													label={t("Phone Number")}
													name={[
														"basic_info",
														"phone_number",
													]}
													labelCol={{
														span: 24,
													}}
													rules={[
														{
															required: true,
															len: 13,
															message: `${t(
																"Please input your Phone Number!"
															)}`,
														},
													]}
												>
													<PhoneNumberWithPrefix />
												</Form.Item>
											</Col>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={11}
											>
												{" "}
												<Form.Item
													label={t(
														"Alternative phone number (optional)"
													)}
													name={[
														"basic_info",
														"extra_phone_number",
													]}
													labelCol={{
														span: 24,
													}}
													rules={[
														{
															len: 13,
															message: `${t(
																"Please input your Phone Number!"
															)}`,
														},
													]}
												>
													<PhoneNumberWithPrefix />
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						</Card>
						<Card>
							<div className="my-0 p-lg-2 p-1">
								<Row justify="start">
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("property located")}</h3>
										<Form.Item
											label={t("Map your property")}
											rules={[
												{
													required: true,
													message: "",
												},
											]}
										>
											<Button
												type="primary"
												ghost
												onClick={openMap}
												size="large"
												style={{
													display: "flex",
													alignItems: "center",
													height: 50,
												}}
											>
												<MapIcon />
												<span
													style={{ marginLeft: 10 }}
												>
													{t("Use Map")}
												</span>
											</Button>
										</Form.Item>
										<Form.Item
											name={[
												"basic_info",
												"address",
												"addressLine",
											]}
											label={t("Street address!")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Street address is required!"
													)}`,
												},
												{
													min: 3,
													message: `${t(
														"Street address must be longer than or equal to 4 characters"
													)}`,
												},
											]}
										>
											<Input className=" p-2" />
										</Form.Item>
										<Form.Item
											name={[
												"basic_info",
												"address",
												"addressLine2",
											]}
											label={t("Address line 2")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
										>
											<Input className="p-2" />
										</Form.Item>
										<Form.Item
											name={[
												"basic_info",
												"address",
												"city",
											]}
											label={t("Region")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Region is required!"
													)}`,
												},
											]}
										>
											<Select
												options={regions.map((r) => ({
													label: getNameTranslation(
														r
													),
													value: r.id,
												}))}
												filterOption={(input, option) =>
													(option?.label ?? "")
														.toLowerCase()
														.includes(
															input.toLowerCase()
														)
												}
												filterSort={(
													optionA,
													optionB
												) =>
													(optionA?.label ?? "")
														.toLowerCase()
														.localeCompare(
															(
																optionB?.label ??
																""
															).toLowerCase()
														)
												}
												showSearch
											/>
										</Form.Item>

										<Form.Item
											name={[
												"basic_info",
												"address",
												"country",
											]}
											hidden
											initialValue={"uz"}
										>
											<Input></Input>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
			<MapModal onChange={onChange} />
		</div>
	);
}
