import { PrinterFilled } from "@ant-design/icons";
import { Button, Card, Col, Form, message, Row, Select, Table } from "antd";
import Column from "antd/lib/table/Column";
import { PropertiesAdminApi } from "api/admin/PropertiesAdminApi";
import { ReservationsApi } from "api/Reservations";
import Status from "components/Status";
import { ReservationStatus } from "constants/ReservationStatus";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { formatCurrency } from "utils/formatCurrency";
import { formatDateString } from "utils/formatDateString";
import { cleanFalsyFromObject } from "utils/utils/object";
import DataRanger from "../property/calendar/DataRanger";

export default function Reservations({ role }) {
	const [filter, setfilter] = useState({
		data_by: "checkin",
		sort_by: "checkin",
		date_from: dayjs(getPreviousDay(new Date())).format("YYYY-MM-DD"),
		date_until: dayjs(new Date()).format("YYYY-MM-DD"),
	});
	const { t } = useTranslation();
	const [data, setdata] = useState([]);
	const [propertes, setPropertes] = useState([]);
	const [loading, setLoading] = useState(false);
	const [meta, setmeta] = useState();
	const [form] = Form.useForm();
	const domEl = useRef(null);

	function getPreviousDay(date = new Date()) {
		const previous = new Date(date.getTime());
		previous.setDate(date.getDate() - 1);
		return previous;
	}
	useEffect(() => {
		setLoading(true);
		ReservationsApi.getReservations(role, {
			...cleanFalsyFromObject(filter),
		})
			.then(({ data }) => {
				setmeta(data.meta);
				setdata(data.items);
				PropertiesAdminApi.getList().then(({ items }) => {
					setPropertes(items);
					setLoading(false);
				});
			})
			.catch((err) => {
				console.error(err);
				message.error(t("error"));
				setLoading(false);
			});
	}, []);
	const onFinish = () => {
		setLoading(true);
		ReservationsApi.getReservations(role, {
			...cleanFalsyFromObject(filter),
		})
			.then(({ data }) => {
				setdata(data.items);
				setmeta(data.meta);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				message.error(t("error"));
				setLoading(false);
			});
	};

	return (
		<div>
			<Row
				style={{
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Col>
					<h1>{t("Reservations")}</h1>
				</Col>
				<Col>
					<Row>
						{/* <Button
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginRight: "15px",
							}}
							onClick={downloadImage}
						>
							<Col className="m-2">
								<DownloadOutlined />
							</Col>
							<Col>{t("Download")}</Col>
						</Button> */}
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Col className="m-2">
								<PrinterFilled />
							</Col>
							<Col>{t("Print")}</Col>
						</Row>
					</Row>
				</Col>
			</Row>

			<Card>
				<Form
					layout="vertical"
					name="filter"
					form={form}
					onFinish={() => onFinish()}
					initialValues={filter}
				>
					<Row
						style={{
							display: "flex",
							alignItems: "end",
							gap: 20,
						}}
					>
						<Col xs={12} sm={12} md={3} lg={3}>
							<Form.Item name={"data_by"} label={t("Date of")}>
								<Select
									size="large"
									onChange={(e) =>
										setfilter({ ...filter, data_by: e })
									}
									options={[
										{
											value: "checkin",
											label: `${t("Check-in")}`,
										},
										{
											value: "checkout",
											label: `${t("Check-out")}`,
										},
									]}
								/>
							</Form.Item>
						</Col>
						{role === "admin" ? (
							<Col xs={12} sm={12} md={3} lg={3}>
								<Form.Item
									name={"property_name"}
									label={t("Property")}
								>
									<Select
										size="large"
										onChange={(e) =>
											setfilter({
												...filter,
												property_id: parseInt(e),
											})
										}
										options={[
											{ label: t("all"), value: "" },
											...propertes.map((item) => ({
												label: item.name,
												value: item.id,
											})),
										]}
									/>
								</Form.Item>
							</Col>
						) : (
							""
						)}

						<Col xs={12} sm={12} md={5} lg={5}>
							<DataRanger
								onChange={(range) =>
									setfilter({
										...filter,
										date_from: range[0],
										date_until: range[1],
									})
								}
								value={[filter.date_from, filter.date_until]}
							/>
						</Col>
						<Col xs={12} sm={12} md={3} lg={3}>
							<Form.Item name={"status"} label={t("Status")}>
								<Select
									allowClear
									size="large"
									onChange={(e) =>
										setfilter({ ...filter, status: e })
									}
									placeholder={t("all")}
									options={Object.entries(
										ReservationStatus
									).map(([key, label]) => {
										return {
											label: t(label),
											value: key,
										};
									})}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} sm={12} md={3} lg={3}>
							<Form.Item name={"sort_by"} label={t("Sort by")}>
								<Select
									size="large"
									onChange={(e) =>
										setfilter({ ...filter, sort_by: e })
									}
									options={[
										{
											value: "checkin",
											label: `${t("Check-in")}`,
										},
										{
											value: "checkout",
											label: `${t("Check-out")}`,
										},
										{
											value: "total",
											label: `${t("Total")}`,
										},
										{
											value: "status",
											label: `${t("Status")}`,
										},
									]}
								/>
							</Form.Item>
						</Col>
						<Col xs={12} sm={12} md={3} lg={3}>
							<Form.Item>
								<Button
									type={"primary"}
									htmlType="submit"
									loading={loading}
								>
									{t("filter")}
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Card>

			<Table
				className="ant-card-bordered rounded"
				rowKey={"id"}
				dataSource={data}
				loading={loading}
				ref={domEl}
				footer={() => (
					<Row justify="end" gutter={15}>
						<Col className="d-flex">
							<h5 style={{ marginRight: 10 }}>
								{t("Total price")}
							</h5>
							<span>
								<span>
									{formatCurrency(Number(meta?.total))}
								</span>
							</span>
						</Col>
						<Col className="d-flex">
							<h5 style={{ marginRight: 10 }}>
								{t("Commission")}
							</h5>
							<span>
								{formatCurrency(Number(meta?.comission))}
							</span>
						</Col>
					</Row>
				)}
			>
				<Column
					title={t("Guest name")}
					dataIndex="customer"
					key="customer"
					render={(text, alldata) => {
						return (
							<Link to={`reservations/${alldata.id}`}>
								{text.first_name + " " + text.last_name}
							</Link>
						);
					}}
				/>
				<Column
					title={t("Check-in")}
					dataIndex="checkin"
					key="checkin"
					render={formatDateString}
				/>
				<Column
					title={t("Check-out")}
					dataIndex="checkout"
					key="checkout"
					render={formatDateString}
				/>
				<Column
					title={t("Rooms")}
					dataIndex="records"
					key="records"
					render={(room) => {
						return room.map((item) => {
							return (
								getNameTranslation(item.room.room_type) + " "
							);
						});
					}}
				/>
				<Column
					title={t("Booked on")}
					dataIndex="createdAt"
					key="createdAt"
					render={formatDateString}
				/>
				<Column
					title={t("Status")}
					dataIndex="status"
					key="status"
					render={(status) => {
						return <Status status={status} />;
					}}
				/>
				<Column
					title={t("Price")}
					dataIndex="total"
					key="total"
					render={(value) => formatCurrency(value)}
				/>
				<Column
					title={t("Commission")}
					dataIndex="comission"
					key="comission"
					render={(value) => formatCurrency(value)}
				/>
				<Column title={t("Booking number")} dataIndex="id" key="id" />
			</Table>
		</div>
	);
}
