import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import PropertyAbout from "./about";
import ThanksProperty from "./thankyou";

export const Properties = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/property/registration`}
					component={PropertyAbout}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/property/thanks`}
					component={ThanksProperty}
				/>

				{/* <Redirect to={`${APP_PREFIX_PATH}/dashboard`} /> */}
			</Switch>
		</Suspense>
	);
};
export default Properties;
