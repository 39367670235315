import axios from "axios";

async function getAddressCoordinate({ latitude, longitude }) {
	return await axios
		.get(
			`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
		)
		.then((res) => {
			const dataMap = res.data;
			const data = {
				addressLine1: dataMap.display_name || "-",
				countryCode: dataMap.address.country_code.toUpperCase() || "-",
				region: dataMap.address.county || "-",
				dependentLocality: dataMap.address.country || "-",
				name: dataMap.display_name || "-",
				administrativeArea: dataMap.address.road || "-",
				country: dataMap.address.country || "-",
				postalCode: dataMap.address.postcode || "-",
				latitude: dataMap.lat || "-",
				longitude: dataMap.lon || "-",
			};

			return data;
		});
}

export async function getAddressByCoordinate(latitude, longitude) {
	try {
		const data = await getAddressCoordinate({
			latitude,
			longitude,
		});

		return {
			addressLine1: data.addressLine1,
			countryCode: data.countryCode,
			region: data.region,
			postalCode: data.postalCode,
			name: data.name,
			dependentLocality: data.dependentLocality,
			administrativeArea: data.administrativeArea,
			country: data.country,
			latitude: data.latitude,
			longitude: data.longitude,
		};
	} catch (error) {
		console.error(error);
		return {
			addressLine1: "Error",
			countryCode: "",
			region: "",
			postalCode: "",
			dependentLocality: "",
			name: "",
			administrativeArea: "",
			country: "",
			latitude: 10,
			longitude: 10,
		};
	}
}
