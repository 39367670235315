import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select } from "antd";
import { t } from "i18n";
import React from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";

export function RegionForm(props) {

	return (
		<Form
			name={props.title}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item name="name" label={t("Regions name")}>
				<Input placeholder={t("Regions name")} />
			</Form.Item>
			<ContentTranslationFormItem name="name_translations" />

			<Form.Item
				name="parent_id"
				label={t(
					"The region you entered belongs to which region?(Optional)"
				)}
				tooltip={{
					title: t(
						"For example, if you enter Yunsabad, you should select Tashkent here"
					),
					icon: <InfoCircleOutlined />,
				}}
			>
				<Select
					showSearch
					optionFilterProp="children"
					filterOption={(input, option) =>
						(option?.label ?? "")
							.toLowerCase()
							.includes(input.toLowerCase())
					}
					options={props?.regions.map((item) => {
						if (item) {
							return { label: item.name, value: item.id };
						}
					})}
				/>
			</Form.Item>
			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
