import { getLang } from "i18n";
const lang = getLang();

const TraslateService = (name, name_translations) => {
	if (name_translations && name_translations[lang]) {
		return name_translations[lang];
	}
	return name;
};

export default TraslateService;
