import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { t } from "i18n";
import React from "react";
import { TranslationLocales } from "services/ContentTranslation";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";

export function RoomTypesForm(props) {
	return (
		<Form
			name={props.title}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Row
				style={{
					justifyContent: "space-between",
				}}
			>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name="name"
						label={t("Room type name")}
						rules={[
							{
								required: true,
								message: `${t("Name is required")}`,
							},
						]}
					>
						<Input placeholder={t("Room type name")} />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={10}>
					<Form.Item name="status" label={t("Status")}>
						<Select
							options={[
								{
									label: `${t("In Active")}`,
									value: 0,
								},
								{
									label: `${t("Active")}`,
									value: 1,
								},
							]}
						/>
					</Form.Item>
				</Col>
			</Row>

			<ContentTranslationFormItem name="name_translations" />

			<Col
				wrapperCol={{
					xs: {
						span: 24,
						offset: 0,
					},
				}}
			>
				<Form.List name="room_names">
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name, ...restField }) => (
								<Space
									key={key}
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: 0,
									}}
									align="baseline"
								>
									<Form.Item
										{...restField}
										name={[name, "name"]}
										rules={[
											{
												required: true,
											},
										]}
										label="Room name"
									>
										<Input placeholder="First Name" />
									</Form.Item>

									<Row
										style={{
											justifyContent: "space-between",
										}}
									>
										{TranslationLocales.map((locale) => {
											return (
												<Form.Item
													name={[
														name,
														"name_translations",
														locale,
													]}
													{...restField}
													label={t("in-" + locale)}
													rules={[
														{
															required: true,
														},
													]}
												>
													<Input
														placeholder={t("name")}
													/>
												</Form.Item>
											);
										})}
									</Row>

									<MinusCircleOutlined
										onClick={() => remove(name)}
										style={{
											color: "red",
										}}
									/>
								</Space>
							))}
							<Form.Item>
								<Button
									type="dashed"
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
								>
									Add field
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Col>
			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Create")}
				</Button>
			</Form.Item>
		</Form>
	);
}
