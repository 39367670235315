import Loading from "components/shared-components/Loading";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Reservations from "views/app-views/reservations";
import Customers from "./customers/Customers";
import CustomersItem from "./customers/CustomersItem";
import Dashboard from "./dashboard";
import LegalCustomers from "./legal-customers";
import LegalCustomersItem from "./legal-customers/LegalCustomersItem";
import CorporateTransaction from "./payments/corporate-transaction";
import Payments from "./payments/payments";
import AllLegalDatas from "./properties/AllLegalDatas";
import PropertiesLayout from "./properties/AllProperties";
import ModerationProperties from "./properties/ModerationProperties";
import Amenities from "./recourses/Amenities";
import AminitiesGroup from "./recourses/AminitiesGroup";
import BedTypes from "./recourses/BedTypes";
import BreakfastTypes from "./recourses/BreakfastTypes";
import Categories from "./recourses/Categories";
import Facilities from "./recourses/Facilities";
import FacilitiesGroup from "./recourses/FacilitiesGroup";
import NearbyPlaces from "./recourses/NearbyPlaces";
import PaymentMethods from "./recourses/PaymentMethods";
import PhotoTags from "./recourses/PhotoTags";
import Regions from "./recourses/Regions";
import RoomTypes from "./recourses/RoomTypes";
import StaffLanguages from "./recourses/StaffLanguages";

export const AdminViews = ({ match }) => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route path={match.path + `/dashboard`} component={Dashboard} />
				<Route
					path={match.path + `/facilities-group`}
					component={FacilitiesGroup}
				/>
				<Route
					path={match.path + `/categories`}
					component={Categories}
				/>
				<Route
					path={match.path + `/facilities`}
					component={Facilities}
				/>

				<Route
					path={match.path + `/customers/:id`}
					component={CustomersItem}
				/>

				<Route path={match.path + `/customers`} component={Customers} />

				<Route path={match.path + `/amenities`} component={Amenities} />

				<Route path={`${match.path}/reservations`}>
					<Reservations role="admin" />
				</Route>
				<Route
					path={match.path + `/amenities-group`}
					component={AminitiesGroup}
				/>
				<Route path={match.path + `/regions`} component={Regions} />
				<Route
					path={match.path + `/payment-methods`}
					component={PaymentMethods}
				/>
				<Route
					path={match.path + `/photo-tags`}
					component={PhotoTags}
				/>

				<Route
					path={match.path + `/room-types`}
					component={RoomTypes}
				/>

				<Route path={match.path + `/bed-types`} component={BedTypes} />
				<Route
					path={match.path + `/all-properties`}
					component={PropertiesLayout}
				/>
				<Route
					path={match.path + `/nearby-places`}
					component={NearbyPlaces}
				/>
				<Route
					path={match.path + `/moderation`}
					component={ModerationProperties}
				/>
				<Route path={match.path + `/payments`} component={Payments} />
				<Route
					path={match.path + `/corporate-transaction`}
					component={CorporateTransaction}
				/>

				<Route
					path={match.path + `/languages`}
					component={StaffLanguages}
				/>
				<Route
					path={match.path + `/legal-customers/:id`}
					component={LegalCustomersItem}
				/>

				<Route
					path={match.path + `/legal-customers`}
					component={LegalCustomers}
				/>

				<Route
					path={match.path + `/all-legal-datas`}
					component={AllLegalDatas}
				/>

				<Route
					path={match.path + `/breakfast-types`}
					component={BreakfastTypes}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AdminViews);
