import { getState } from "redux/store";
import { createSelector } from "reselect";
import { CalendarResponse } from "types/calendar.types";

export const getCalendarState = (): CalendarResponse.CalendarState =>
	getState().calendar;

export const calendarSelector = (state: {
	calendar: CalendarResponse.CalendarState;
}) => state.calendar;

export const calendarDataSelector = createSelector(
	calendarSelector,
	(c:any) => c.data
);
