import { Alert, Button, Card, Col, Form, InputNumber } from "antd";
import { PropertyApi } from "api/PropertyApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";

export default function Marketing() {
	const [data, setData] = useState({});
	const [loader, setloader] = useState(false);
	const [form] = Form.useForm();
	useEffect(async () => {
		setloader(true);
		await PropertyApi.getPrimaryProperty().then((data) => {
			setData(data);
			setloader(false);
		});
	}, []);
	const onFinish = () => {
		PropertyApi.updateMarketing(form.getFieldValue())
			.then((data) => {
				MessagesService.success();
			})
			.catch((err) => {
				MessagesService.error();
			});
	};
	if (loader) {
		return <Loading />;
	}
	return (
		<>
			<h3>{t("Marketing")}</h3>
			<Card>
				<Col xs={24} sm={24} md={24} lg={12}>
					<Form form={form} onFinish={onFinish} initialValues={data}>
						<Form.Item
							name="percentage"
							label={t("Percentage of commission")}
							labelCol={{ span: 24 }}
						>
							<InputNumber
								min={15}
								max={70}
								type={"number"}
								addonAfter="%"
							/>
						</Form.Item>

						<Alert
							// message={t("Waiting")}
							description={
								<h5>
									{t(
										"As you increase the percentage, your property will appear higher in the search"
									)}
								</h5>
							}
							type="info"
							showIcon
							style={{ marginBottom: 20 }}
						/>

						<Form.Item>
							<Button type="primary" htmlType="submit">
								{t("Save")}
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Card>
		</>
	);
}
