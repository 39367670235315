import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/plots";
import { Card, Col, Empty, Pagination, Progress, Row } from "antd";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import ReviewsCatalog from "constants/ReviewCatalog";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function Reviews() {
	const [data, setData] = useState([]);
	const [page, setpage] = useState(1);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	useEffect(() => {
		setLoading(true);
		ResourceApi.getList("property/reviews", {
			limit: 10,
			page: page,
			order: "ASC",
		}).then((data) => {
			setData(data);
			setLoading(false);
		});
	}, [page]);

	let overall = [];
	data?.overall_review?.children.map((item) => {
		overall.push({ ...item, type: ReviewsCatalog()[item.category] });
	});
	const config = {
		data: overall,
		xField: "type",
		yField: "mark",
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 0.6,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		meta: {
			type: {
				alias: "Ilhomjon",
			},
			mark: {
				alias: "mark",
			},
		},
	};

	if (loading) {
		return <Loading />;
	}
	return (
		<div>
			{data?.items?.length > 0 ? (
				<>
					<Row
						style={{
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Col>
							<h2>{t("All reviews")}</h2>
						</Col>
						<Col>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<h4 style={{ margin: 0, padding: 0 }}>
									{t("Overall reviews")}:
								</h4>
								<span
									style={{
										padding: "5px 10px",
										borderRadius: 3,
										background:
											"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
										color: "#fff",
										fontSize: 16,
										fontWeight: "600",
										marginLeft: "8px",
									}}
								>
									{data?.overall_review?.mark}
								</span>
							</div>
						</Col>
					</Row>
					<h4>{t("Reviews of the catalog")}</h4>
					<Column {...config} style={{ marginBottom: 20 }} />

					{data?.items.map((item, index) => {
						return (
							<Card key={index}>
								<div className="d-flex justify-content-between align-items-start">
									<Row className="align-items-center">
										<Col>
											<span
												style={{
													padding: "8px 10px",
													borderRadius: 3,
													background:
														"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
													color: "#fff",
													fontSize: 16,
													fontWeight: "600",
													marginRight: "8px",
												}}
											>
												{item?.mark}
											</span>
										</Col>
										<Col>
											<h5 className="m-0">
												{item?.customer.first_name +
													" " +
													item?.customer?.last_name}
											</h5>
											<span
												className="m-0"
												style={{ fontSize: 11 }}
											>
												<span>
													{t("Reservation number:")}{" "}
												</span>
												<Link
													onClick={() =>
														history.push(
															`/app/reservations/${item?.reservation_id}`
														)
													}
												>
													{item?.reservation_id}
												</Link>
											</span>
										</Col>
									</Row>
									<Col>
										<span>
											{dayjs(item.createdAt).format(
												"DD-MM-YYYY HH:MM"
											)}
										</span>
									</Col>
								</div>
								<hr />
								<h4>{t("Basic categories")}</h4>
								<Row gutter={20}>
									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Stuff")}{" "}
											</h5>
										</div>
										<Progress
											percent={item.children[0].mark * 10}
										/>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Cleanliness")}
											</h5>
										</div>
										<Progress
											percent={item.children[1].mark * 10}
										/>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Location ")}
											</h5>
										</div>
										<Progress
											percent={item.children[2].mark * 10}
										/>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Facilities")}
											</h5>
										</div>
										<Progress
											percent={item.children[3].mark * 10}
										/>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Comfort")}
											</h5>
										</div>
										<Progress
											percent={item.children[4].mark * 10}
										/>
									</Col>

									<Col
										xs={12}
										sm={12}
										md={8}
										lg={8}
										className="mb-2"
									>
										<div className="d-flex">
											<h5 className="m-0 p-0">
												{t("Value of money ")}
											</h5>
										</div>
										<Progress
											percent={item.children[5].mark * 10}
										/>
									</Col>
								</Row>
								<Card>
									<h5>
										<SmileOutlined /> {t("Good comment:")}{" "}
									</h5>
									<p className="m-0">{item.good_comment}</p>
								</Card>
								<Card>
									<h5>
										<FrownOutlined /> {t("Bad comment:")}{" "}
									</h5>
									<p className="m-0">{item.bad_comment}</p>
								</Card>
							</Card>
						);
					})}
					<Pagination
						defaultCurrent={page}
						total={data.total}
						onChange={(page) => setpage(page)}
					/>
				</>
			) : (
				<Empty />
			)}
		</div>
	);
}
