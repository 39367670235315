import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from "antd";
import SelectResource from "components/fields/SelectResource";
import { SmokingTypes } from "constants/SmokingType";
import { t } from "i18n";
import React from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { RoomBedOptions } from "views/app-views/properties/about/components/RoomBedOptions";
export default function RoomForm({
	initialValues,
	roomType,
	setRoomType,
	loading,
	form,
	onFinish,
	submitBtn,
}) {
	console.log(roomType);
	return (
		<Form
			name="room"
			form={form}
			layout="vertical"
			autoComplete="off"
			initialValues={initialValues}
		>
			<Card className="my-3">
				<div className="my-0 p-2">
					<Row justify="start">
						<Col xs={24} sm={24} md={24} lg={24}>
							<h3>{t("Please select")}</h3>
							<Row justify="between" gutter={5}>
								<Col xs={24} sm={24} md={12} lg={12}>
									<Form.Item
										label={t("Room type")}
										name={["room_type", "id"]}
										rules={[
											{
												required: true,
												message: `${t(
													"Room type is required!"
												)}`,
											},
										]}
									>
										<SelectResource
											resource="rooms/room-types"
											onExtraChange={(id, room_type) => {
												setRoomType(room_type);
												form.setFieldsValue({
													room_name: undefined,
													custom_name:
														getNameTranslation(
															room_type
														),
												});
											}}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12}>
									<Form.Item
										label={t("Room name")}
										name={["room_name", "id"]}
										rules={[
											{
												required: true,
												message: `${t(
													"Room name is required!"
												)}`,
											},
										]}
									>
										<Select
											options={roomType?.room_names.map(
												(room_name) => ({
													value: room_name.id,
													label: getNameTranslation(
														room_name
													),
												})
											)}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12}>
									<Form.Item
										name={["custom_name"]}
										label={t("Create custom name")}
									>
										<Input className="w-100 p-2" />
									</Form.Item>
								</Col>
								<Col xs={24} sm={24} md={12} lg={12}>
									<Form.Item
										label={t("Smoking policy")}
										name={["smoking"]}
										rules={[
											{
												required: true,
												message: `${t(
													"Smoking policy is required!"
												)}`,
											},
										]}
									>
										<Select
											placeholder={t("Smoking policy")}
											options={Object.entries(
												SmokingTypes
											).map(([key, value]) => {
												return {
													value: parseInt(key),
													label: t(value),
												};
											})}
										></Select>
									</Form.Item>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Card>
			<RoomBedOptions />
			<Card>
				<div className="my-0 p-2">
					<Row justify="start">
						<Col xs={24} sm={24} md={20} lg={12}>
							<h3>{t("Room size")}</h3>
							<Form.Item
								name={["room_size"]}
								label={t("Room size")}
								className="mt-4"
							>
								<InputNumber
									min={0}
									addonAfter={<span>&#13217;</span>}
									style={{ width: "80%" }}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={20} lg={12}>
							<h3>{t("Occupancy")}</h3>
							<Form.Item
								name={["capacity"]}
								label={t("Occupancy")}
								className="mt-4"
							>
								<InputNumber min={0} style={{ width: "80%" }} />
							</Form.Item>
						</Col>
					</Row>
				</div>
			</Card>

			<Form.Item>
				<Button
					type="primary"
					className="m-1"
					size="large"
					onClick={onFinish}
					loading={loading}
				>
					{submitBtn}
				</Button>
			</Form.Item>
		</Form>
	);
}
