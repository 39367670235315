import { Button, Card, Col, Row } from "antd";
import { t } from "i18n";
import React from "react";

export default function Confirmation({ form, current, setCurrent }) {
	const data = form.getFieldValue();
	return (
		<Col gutter={10} xs={24} sm={24} md={16} lg={16}>
			<Card>
				<Col xs={24} sm={24} md={24} lg={24}>
					<h3>{t("Please review and submit your details")}</h3>
					<p>
						{t(
							"Before submitting the form, please check that the information you entered is correct."
						)}
					</p>
				</Col>
			</Card>
			<Card>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="d-flex justify-content-between align-items-center">
						<h3>{t("Property details")}</h3>
						<Button type="link" onClick={() => setCurrent(0)}>
							{t("Edit")}
						</Button>
					</div>
				</Col>
				<hr />
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("Property name")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.property_name}
					</Col>
				</Row>
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("Address")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.address}
					</Col>
				</Row>
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("Phone number")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.phone_number}
					</Col>
				</Row>
			</Card>
			<Card>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="d-flex justify-content-between align-items-center">
						<h3>{t("Registration details")}</h3>
						<Button type="link" onClick={() => setCurrent(1)}>
							{t("Edit")}
						</Button>
					</div>
				</Col>
				<hr />
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("IFUT code")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.okonx}
					</Col>
				</Row>
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("TIN")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.tin}
					</Col>
				</Row>
			</Card>
			<Card>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="d-flex justify-content-between align-items-center">
						<h3>{t("Billing Details")}</h3>
						<Button type="link" onClick={() => setCurrent(2)}>
							{t("Edit")}
						</Button>
					</div>
				</Col>
				<hr />
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("Bank name")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.bank_name}
					</Col>
				</Row>
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("MFO")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.mfi}
					</Col>
				</Row>
				<Row justify="space-between">
					<Col xs={12} sm={12} md={12} lg={12}>
						<h5>{t("Account")}:</h5>
					</Col>
					<Col xs={12} sm={12} md={12} lg={12}>
						{data.account}
					</Col>
				</Row>
			</Card>
		</Col>
	);
}
