import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18n";
import React from "react";
import Chat from "services/Chat";
import { isSunday } from "../../../../utils/date";
import { PriceCell } from "./PriceSell";
import { RoomCell } from "./RoomSell";
import { StatusCell } from "./StatusSell";

function getPriceComponent(room: any, rate: any, data: any) {
	const newr: any = {};
	data.date_range.forEach((date: any, index: number) => {
		newr[date] = (
			<PriceCell rate={{ ...rate, room_id: room.id }} dateIndex={index} />
		);
	});

	return newr;
}

function getPriceByGuestColumnsData(
	guest: string,
	rate: any,
	date_range: string[]
) {
	const columns: any = {};

	date_range.forEach((date: any) => {
		const data = rate.dates[date];
		columns[date] = data && (
			<PricePerGuestCell price={data.prices_by_guest[guest]} />
		);
	});

	return columns;
}

function PricePerGuestCell({ price }: any) {
	return <div className="per-guest-cell">{price}</div>;
}

export function roomStatusMap(room: any, dates: string[]) {
	const newr: any = {};
	dates.forEach((date, index) => {
		newr[date] = (
			<StatusCell
				key={date}
				room={room}
				dates={dates}
				dateIndex={index}
			/>
		);
	});

	return newr;
}
export function roomToSellMap(room: any, dates: string[]) {
	const newr: any = {};
	dates.forEach((date, index) => {
		newr[date] = (
			<RoomCell key={date} room={room} dates={dates} dateIndex={index} />
		);
	});

	return newr;
}

function getNetBooks(dates: any, date: string) {
	const data = dates[date];

	if (data && data.net_booked) {
		return data.net_booked;
	}

	return "";
}

export function netBooked(date_range: string[], room_dates: any) {
	const newr: any = {};

	date_range.forEach((date) => {
		newr[date] = (
			<div
				className={`net-booked ${
					isSunday(new Date(date)) ? "border-right" : ""
				}`}
			>
				{getNetBooks(room_dates, date)}
			</div>
		);
	});
	return newr;
}

function getPriceByGuestRows(rate: any, range: string[], today_iso: string) {
	if (!rate.dates[today_iso]) {
		return;
	}

	const prices_by_guest = Object.entries(
		rate.dates[today_iso].prices_by_guest
	);

	if (prices_by_guest && prices_by_guest.length > 1) {
		return prices_by_guest
			.filter((data) => rate.default_guest !== data[0])
			.map((data) => ({
				name: (
					<div className="per-guest-cell-first">
						<UserOutlined /> × {data[0]}
					</div>
				),
				id: String(rate.id) + data[0],
				...getPriceByGuestColumnsData(data[0], rate, range),
			}));
	}
}

export function getRateRows(
	data: any,
	room: any,
	show_price_per_guest?: boolean
) {
	const rows: any[] = [];

	const today_iso = new Date()
		.toLocaleDateString("ru")
		.slice(0, 10)
		.split(".")
		.reverse()
		.join("-");

	room.rates.forEach((rate: any) => {
		rows.push({
			id: String(rate.id),
			name: <EditButton room_id={room.id} rate={rate} />,
			...getPriceComponent(room, rate, data),
		});

		if (show_price_per_guest) {
			const prices = getPriceByGuestRows(
				rate,
				data.date_range,
				today_iso
			);

			if (prices) {
				rows.push(...prices);
			}
		}
	});

	return rows;
}

const EditButton = ({ room_id, rate }: any) => {
	return (
		<div className={rate.independed_rate_id ? "disabled-rate-name" : ""}>
			{rate.name}
			<Button
				type="link"
				onClick={() => {
					Chat.send(
						{ rate_id: rate.id, room_id, open: true },
						"edit_pricing"
					);
				}}
			>
				<div>
					<UserOutlined />× {rate.default_guest} {t("Edit")}
				</div>
			</Button>
		</div>
	);
};
