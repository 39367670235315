import { getDataMiddleware } from "api/api.utils";
import QueryString from "qs";
import mainApiInstence from "../mainApiInstence";

export class NearbyPlacesAdminApi {
	static async getList(query: any) {
		return mainApiInstence
			.get(`/nearby-places?${QueryString.stringify(query)}`)
			.then(getDataMiddleware);
	}
}
