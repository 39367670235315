import { Col, Form, Row, Select } from "antd";
import { RoomsApi } from "api/RoomsApi";
import { t } from "i18n";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MessagesService } from "services/Messages";
import RoomForm from "./components/RoomForm";

const Option = { Select };

export default function CreateRoom() {
	const [roomType, setRoomType] = useState();
	const [loading, setloading] = useState(false);
	const history = useHistory();

	const [form] = Form.useForm();

	const onFinish = () => {
		setloading(true);
		form.validateFields()
			.then(async () => {
				await RoomsApi.create(form.getFieldValue())
					.then((data) => {
						setloading(false);
						form.resetFields();
						MessagesService.success();
						history.push("/app/room-details");
					})
					.catch((err) => {
						MessagesService.error();
						console.log("Room create error: ", err);
						setloading(false);
					});
			})
			.catch((info) => {
				console.error(t("Validate Failed:"), info);
				setloading(false);
			});
	};

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={20}>
						<h2 className="my-1 mt-2">{t("Create room")}</h2>
						<p>
							{t(
								"Start by telling us your property's name, contact details and address."
							)}
						</p>

						<RoomForm
							roomType={roomType}
							setRoomType={setRoomType}
							loading={loading}
							form={form}
							onFinish={onFinish}
							submitBtn={t("Create a room")}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}
