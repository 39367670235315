const chatIds = {
	restk: "restk",
	info_bar: "info_bar",
	aviability_popup: "aviability_popup",
	status_sell: "status_sell",
	room_edit: "room_edit",
	edit_pricing: "edit_pricing",
	map: "map",
	open_import_product_card: "open_import_product_card",
	accupancy_popup: "accupancy_popup",
	filter_drawer: "filter_drawer",
};

interface IChat {
	chats: typeof window.__chats;
	send: (message: any, chatId: keyof typeof chatIds) => any;
	inbox: (
		callBack: (message: any) => void,
		chatId: keyof typeof chatIds
	) => void;
	clear: (chatId: keyof typeof chatIds) => void;
	clearAll: () => void;
}

window.__chats = window.__chats ? window.__chats : {};

const Chat: IChat = {
	chats: window.__chats,
	/**
	 * You can send data to anywhere if you listen it with inbox, both send and inbox chatIds should be same
	 * @param {object} message should be object
	 * @param {string} chatId should be string
	 */
	send(message, chatId) {
		const callBack = this.chats[chatId];
		if (callBack) {
			return callBack(message);
		}

		console.error("No handler for " + chatId);
	},
	inbox(receiverCallback, chatId) {
		this.chats[chatId] = receiverCallback;
	},
	clear(chatId) {
		delete this.chats[chatId];
	},
	clearAll() {
		this.chats = {};
	},
};

export default Chat;
