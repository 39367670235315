import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CreditCardOutlined,
	WalletOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Tag } from "antd";
import { PaymensAdminApi } from "api/admin/PaymentAdmin";
import initial_filter from "constants/InitialFilter";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/formatCurrency";
import TableComponent from "../../components/TableComponent";

export default function Payments() {
	const columns = [
		{
			title: `${t("Reservation number:")}`,
			dataIndex: "reservation_id",
			render: (_, record) => {
				return (
					<Link
						to={`/app/admin/reservations/${record.reservation_id}`}
					>
						{record.reservation_id}
					</Link>
				);
			},
		},
		{
			title: `${t("Amount")}`,
			dataIndex: "amount",
			render: (_, record) => {
				return formatCurrency(record.amount);
			},
		},
		{
			title: `${t("Status")}`,
			dataIndex: "paid",
			render: (_, record) => {
				return (
					<Tag
						icon={
							record.paid ? (
								<CheckCircleOutlined />
							) : (
								<CloseCircleOutlined />
							)
						}
						color={record.paid ? "#87d068" : "#f50"}
					>
						{record.paid ? t("Paid") : t("Not Paid")}
					</Tag>
				);
			},
		},
		{
			title: `${t("Payment type")}`,
			dataIndex: "type",
			render: (_, record) => {
				return (
					<Tag
						icon={
							record.type === 1 ? (
								<WalletOutlined />
							) : (
								<CreditCardOutlined />
							)
						}
						color={record.type === 1 ? "blue" : "gold"}
					>
						{record.type === 1 ? t("Wallet") : t("Card")}
					</Tag>
				);
			},
		},
		{
			title: `${t("Provider transaction id")}`,
			dataIndex: "provider_transaction_id",
			render: (_, record) => {
				return record.provider_transaction_id;
			},
		},
		{
			title: `${t("Created At")}`,
			dataIndex: "createdAt",
			render: (_, record) => {
				return new Date(record.createdAt).toLocaleString("ru");
			},
		},
	];
	const [filter, setFilter] = useState(initial_filter);
	const [data, setdata] = useState([]);
	const [loading, setloading] = useState(false);

	useEffect(() => {
		fetch(filter);
	}, []);

	function fetch(filter) {
		setloading(true);
		PaymensAdminApi.getList(filter).then((data) => {
			setdata(data);
			setloading(false);
		});
	}

	return (
		<div>
			<Card>
				<Row style={{ justifyContent: "space-between" }}>
					<Col>
						<h4 className="m-0">{t("Payments")}</h4>
					</Col>
				</Row>
			</Card>
			<TableComponent
				columns={columns}
				data={data}
				filter={filter}
				setFilter={setFilter}
				fetch={fetch}
				loading={loading}
			/>
		</div>
	);
}
