import { LoginOutlined, StarFilled } from "@ant-design/icons";
import { Button, Card, Row, Table } from "antd";
import { PropertyApi } from "api/PropertyApi";
import BackButton from "components/shared-components/BackButton";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { authenticated } from "redux/actions/Auth";
import { getTOKEN } from "redux/reducers/Auth";
import { PropertyStatusComponent } from "views/admin-views/properties/AllProperties/PropertyStatus";

export default function PropertyList() {
	const columns = [
		{
			title: `${t("Id")}`,
			dataIndex: "id",
			key: "id",
			width: 50,
			fixed: "left",
		},
		{
			title: `${t("Name")}`,
			dataIndex: "name",
			key: "name",
			fixed: "left",

			render: (_, record) => {
				return (
					<>
						<h5 className="m-0 p-0">{record.name}</h5>
						{/* <p className="m-0 p-0">
							<b>Legal name:</b> {record.legal_name}
						</p> */}
					</>
				);
			},
			width: 200,
		},
		{
			title: `${t("Star")}`,
			dataIndex: "star",
			key: "star",
			width: 200,
			render: (_, record) => {
				return record?.star === 0 ? (
					"-"
				) : (
					<>
						{record?.star} <StarFilled />
					</>
				);
			},
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			key: "status",
			width: 150,
			render: (_) => {
				return <PropertyStatusComponent status={_} />;
			},
		},
		{
			title: `${t("Contact name")}`,
			dataIndex: "contact_name",
			key: "contact_name",
			width: 120,
		},
		{
			title: `${t("Phone number")}`,
			dataIndex: "phone_number",
			key: "phone_number",
			width: 200,
			render: (_, record) => {
				return (
					<>
						<span className="m-0 p-0 d-block">
							<b>{t("Main:")}</b>
							{record?.phone_number}
						</span>
						<span className="m-0 p-0 d-block">
							<b>{t("Extra:")}</b>
							{record?.extra_phone_number}
						</span>
					</>
				);
			},
		},
		{
			title: `${t("Category")}`,
			dataIndex: "category",
			key: "category",
			width: 200,
		},
		{
			title: `${t("Rating")}`,
			dataIndex: "rating",
			key: "rating",
			width: 200,
			render: (_, record) => {
				return (
					<span className="m-0 p-0 d-block">{record?.rating}</span>
				);
			},
		},
		{
			title: `${t("Percentage(%)")}`,
			dataIndex: "percentage",
			key: "percentage",
			width: 150,
		},

		{
			title: `${t("Created At")}`,
			dataIndex: "createdAt",
			key: "createdAt",
			render: (_, record) => {
				return new Date(record.createdAt).toLocaleString("ru");
			},
			width: 150,
		},
		{
			title: `${t("Enter")}`,
			dataIndex: "operations",
			key: "operations",
			render: (_, record) => {
				return (
					<Row className="">
						<Button
							type="link"
							className="p-0"
							onClick={() => changeProperty(record.id)}
						>
							<LoginOutlined style={{ marginRight: 5 }} />
							<span>{t("Enter")}</span>
						</Button>
					</Row>
				);
			},
			fixed: "right",
			width: 130,
		},
	];
	const [data, setdata] = useState();
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		fetch();
	}, []);
	function fetch() {
		PropertyApi.getPropertyList().then((data) => {
			setdata(data.data.items);
		});
	}

	function changeProperty(id) {
		PropertyApi.patchProperty({ primary_property_id: id }).then((data) => {
			dispatch(authenticated(getTOKEN(), data?.data));
			history.push("/app");
		});
	}

	return (
		<>
			<Card>
				<Row
					style={{
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div className="d-flex align-items-center">
						<BackButton />
						<h3 style={{ margin: 0, padding: 0 }}>
							{t("my_hotels")}
						</h3>
					</div>
					<div
						span={12}
						style={{ display: "flex", justifyContent: "end" }}
					>
						<Button
							type="primary"
							size="small"
							onClick={() =>
								history.push("/app/property/registration")
							}
						>
							{t("Create property")}
						</Button>
					</div>
				</Row>
			</Card>
			<Table
				dataSource={data}
				columns={columns}
				rowKey="id"
				scroll={{ x: 1300 }}
			/>
		</>
	);
}
