import { Card, Checkbox, Col, Form, InputNumber, Row, Select } from "antd";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";

import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export default function FacilitiesServices({ user, current, setCurrent }) {
	const [breakfastType, setBreakfastType] = useState(0);
	const [breakfastTypes, setBreakfastTypes] = useState([]);
	const [languages, setlanguages] = useState([]);
	const [facilities, setfacilities] = useState([]);
	const { Option } = Select;

	useEffect(() => {
		// Breakfast types API
		ResourceApi.getList("property/breakfast-types").then((data) => {
			setBreakfastTypes(data.items);
		});

		// Languages API
		ResourceApi.getList("property/languages").then((data) => {
			setlanguages(data.items);
		});

		// Facilities API
		ResourceApi.getList("facilities", {
			page: 1,
			limit: 100,
		}).then((data) => {
			setfacilities(data.items);
		});
	}, []);

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<h2 className="my-3 mt-2">{t("Facilities & services!")}</h2>
				<p>{t("tell us some general details")}</p>
				<Row justify="space-between">
					<Col xs={24} sm={24} md={24} lg={24}>
						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="space-between">
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Breakfast")}</h3>
										<Form.Item
											name={[
												"facilities",
												"breakfast",
												"aviable",
											]}
											label={t(
												"Is breakfast available to guests?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"Is breakfast available to guests is required"
													)}`,
												},
											]}
										>
											<Select
												placeholder={t(
													"Is breakfast available to guests?"
												)}
												onChange={(value) => {
													setBreakfastType(value);
												}}
												size="large"
											>
												<Option value={0}>
													{t("No")}
												</Option>
												<Option value={1}>
													{t(
														"Yes, it's included in the price"
													)}
												</Option>
												<Option value={2}>
													{t("Yes, it's optional")}
												</Option>
											</Select>
										</Form.Item>

										{breakfastType === 2 ? (
											<Form.Item
												label={t(
													"Price for breakfast (per person, per day including all fees and taxes)"
												)}
												name={[
													"facilities",
													"breakfast",
													"price",
												]}
												labelCol={{
													span: 24,
												}}
												rules={[
													{
														required: true,
														message: `${t(
															"Breakfast price is required"
														)}`,
													},
												]}
											>
												<InputNumber
													addonBefore="UZS"
													placeholder="0.00"
													size="large"
												/>
											</Form.Item>
										) : (
											""
										)}

										<Form.Item
											name={[
												"facilities",
												"breakfast",
												"breakfast_types",
											]}
											label={t(
												"What kind of breakfast is available?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"What kind of breakfast is available? is required"
													)}`,
												},
											]}
										>
											<Select
												mode="multiple"
												allowClear
												style={{
													width: "100%",
												}}
												placeholder="Please select"
												size="large"
											>
												{breakfastTypes.map((item) => {
													return (
														<Option
															value={item.id}
															key={item.id}
														>
															{getNameTranslation(
																item
															)}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={11} lg={11}>
										<h3>{t("Languages spoken")}</h3>
										{/* <p>
											{t(
												"What languages do you or your staff speak?"
											)}
										</p> */}
										<Form.Item
											name={[
												"facilities",
												"staff_languages",
											]}
											label={t(
												"Select staff language your property"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
													message: `${t(
														"What kind of breakfast is available? is required"
													)}`,
												},
											]}
										>
											<Select
												mode="multiple"
												allowClear
												style={{
													fontSize: 14,
													width: "100%",
												}}
												placeholder={t("Please select")}
												size="large"
											>
												{languages.map((item) => {
													return (
														<Option
															value={item.id}
															key={item.id}
														>
															{getNameTranslation(
																item
															)}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24}>
						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={20} lg={24}>
										<h3>
											{t(
												"Facilities that are popular with guests"
											)}
										</h3>
										<p>{t("Guests look")}</p>
										<Form.Item
											name={["facilities", "facilities"]}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													validator: (_, value) =>
														value.length > 0
															? Promise.resolve()
															: Promise.reject(
																	new Error(
																		`${t(
																			"Should accept agreement"
																		)}`
																	)
															  ),
												},
											]}
										>
											<Checkbox.Group
												style={{ width: "100%" }}
											>
												<Row>
													{facilities.map((item) => {
														return (
															<Col span={12}>
																<Checkbox
																	className="mt-2"
																	value={
																		item.id
																	}
																	key={
																		item.id
																	}
																>
																	{getNameTranslation(
																		item
																	)}
																</Checkbox>
															</Col>
														);
													})}
												</Row>
											</Checkbox.Group>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
