import { Button, Form, Input, Select } from "antd";
import { t } from "i18n";
import React from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";

export function AminitiesForm(props) {
	return (
		<Form
			name={props.title}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item
				name="name"
				label={t("Amenities name")}
				rules={[
					{
						required: true,
					},
				]}
			>
				<Input placeholder={t("Amenities name")} />
			</Form.Item>

			<ContentTranslationFormItem name="name_translations" />

			<Form.Item
				name="amenity_group_id"
				label={t("Aminity group")}
				rules={[
					{
						required: true,
						message: `${t("Aminity group is required")}`,
					},
				]}
			>
				<Select
					options={props.aminityiesGroup.map((item) => {
						return {
							label: item.name,
							value: item.id,
						};
					})}
				/>
			</Form.Item>

			<Form.Item name="show_in_filter" label={t("Show in filter")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>

			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
