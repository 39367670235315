import { Button, Form, Input, Select } from "antd";
import { t } from "i18n";
import React from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";

export function AminitiesGroupForm(props) {
	return (
		<Form
			name={props.title}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item name="name" label={t("Aminity group name")}>
				<Input placeholder={t("Aminity group name")} />
			</Form.Item>
			<ContentTranslationFormItem name="name_translations" />
			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
