import { Col, Row, Table, Typography } from "antd";
import { PropertiesAdminApi } from "api/admin/PropertiesAdminApi";
import mainApiInstence from "api/mainApiInstence";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import { FilterButton } from "./FilterButton";
import { FilterDrawer } from "./FilterDrawer";
import { getPropertiesFilterFields } from "./getPropertiesFilterFields";
import { getPropertyColumns } from "./getPropertyColumns";
import { initial_filter } from "./initial_filter";
import { AntTableSortMapRevert } from "./property.const";

export default function AllProperties({ match }) {
	const [data, setData] = useState({
		items: [],
		total: 0,
	});
	const [loading, setLoading] = useState(true);
	const [regions, setRegions] = useState([]);
	const [categories, setCategories] = useState();
	const [filter, setFilter] = useState(initial_filter);

	useEffect(() => {
		fetch(filter);
	}, []);

	useEffect(() => {
		ResourceApi.getList("regions", { limit: 50 }).then((r) => {
			setRegions(r.items);
		});
		mainApiInstence
			.get(`/property/categories`)
			.then(({ data }) => setCategories(data.items))
			.catch((error) => {
				console.log(error);
			});
	}, []);

	function fetch(filter) {
		!loading && setLoading(true);
		PropertiesAdminApi.getList(filter)
			.then((data) => {
				console.log(data);
				setData(data);
			})
			.catch((e) => {
				console.error(e);
				MessagesService.error();
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function onSubmit(new_filter) {
		const f = { ...filter, ...new_filter };
		setFilter(f);
		fetch(f);
	}

	return (
		<div>
			<Row>
				<Col span={12}>
					<Typography.Title level={1}>
						{t("Properties")}
					</Typography.Title>
				</Col>
				<Col span={12} className="d-flex justify-content-end">
					<FilterButton
						onResetFilter={() => {
							setFilter(initial_filter);
							fetch(initial_filter);
						}}
					/>
				</Col>
				{regions && categories && (
					<FilterDrawer
						filter={filter}
						onSubmit={onSubmit}
						fields={getPropertiesFilterFields(regions, categories)}
					/>
				)}
			</Row>

			<Table
				className="ant-card-bordered rounded"
				onChange={(p, _, sort) => {
					const f = {
						...filter,
						page: p.current,
						limit: p.pageSize,
						order: AntTableSortMapRevert[sort.order],
						order_by: sort.field,
					};
					setFilter(f);
					fetch(f);
				}}
				loading={loading}
				dataSource={data.items}
				columns={getPropertyColumns(match, filter)}
				rowKey="id"
				scroll={{ x: 1300 }}
				pagination={{
					current: filter.page,
					total: data.total,
					pageSize: filter.limit,
				}}
			/>
		</div>
	);
}
