import mainApiInstence from "api/mainApiInstence";

export const imageUploadCustomRequest = ({
	file,
	onError,
	onProgress,
	onSuccess,
}) => {
	const formData = new FormData();

	formData.append("file", file);
	return mainApiInstence
		.post("/files/upload", formData, {
			onUploadProgress: ({ total, loaded }) => {
				onProgress(
					{ percent: Math.round((loaded / total) * 100).toFixed(2) },
					file
				);
			},
		})
		.then(({ data }) => {
			onSuccess(data, file);

			return data;
		})
		.catch(onError);
};
