import { Button, Card, Col, Form, Input, InputNumber, Row, Select } from "antd";
import SelectResource from "components/fields/SelectResource";
import { SmokingTypes } from "constants/SmokingType";
import { t } from "i18n";
import React, { useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { RoomBedOptions } from "./RoomBedOptions";

export function CreateRoomForm({ onRoomFinish, form, rooms }) {
	const [roomType, setRoomType] = useState();
	const [formRoom] = Form.useForm();

	const onFinish = () => {
		formRoom
			.validateFields()
			.then(() => {
				onRoomFinish({
					id: Math.floor(Math.random() * 100000),
					...formRoom.getFieldsValue(),
				});
				formRoom.resetFields();
			})
			.catch((info) => {
				console.error("Validate Failed:", info);
			});
	};

	return (
		<Form name="room" form={formRoom} layout="vertical" autoComplete="off">
			<Card className="my-3">
				<div className="my-0 p-2">
					<h3>{t("Please select")}</h3>
					<Row justify="space-between">
						<Col xs={24} sm={24} md={12} lg={12}>
							<Form.Item
								label={t("Room type")}
								name="type"
								rules={[
									{
										required: true,
										message: `${t(
											"Room type is required!"
										)}`,
									},
								]}
							>
								<SelectResource
									resource="rooms/room-types"
									onExtraChange={(id, room_type) => {
										setRoomType(room_type);
										formRoom.setFieldsValue({
											name: undefined,
											custom_name:
												getNameTranslation(room_type),
										});
									}}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11}>
							<Form.Item
								label={t("Room name")}
								name={["name"]}
								rules={[
									{
										required: true,
										message: `${t(
											"Room type is required!"
										)}`,
									},
								]}
							>
								<Select
									options={roomType?.room_names.map(
										(room_name) => ({
											value: room_name.id,
											label: getNameTranslation(
												room_name
											),
										})
									)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12}>
							<Form.Item
								name={["custom_name"]}
								label={t("Create custom name")}
							>
								<Input className="w-100 p-2" />
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11}>
							<Form.Item
								label={t("Smoking policy")}
								name={["smoking"]}
								rules={[
									{
										required: true,
										message: `${t(
											"Smoking policy is required!"
										)}`,
									},
								]}
							>
								<Select
									placeholder={t("Smoking policy")}
									options={Object.entries(SmokingTypes).map(
										([key, value]) => {
											return {
												value: parseInt(key),
												label: t(value),
											};
										}
									)}
								></Select>
							</Form.Item>
						</Col>
					</Row>
				</div>
			</Card>
			<RoomBedOptions />
			<Card>
				<div className="my-0 p-2">
					<h3>{t("Room size")}</h3>
					<Row justify="space-between">
						<Col xs={24} sm={24} md={12} lg={12}>
							<Form.Item
								name={["room_size"]}
								label={t("Room size")}
							>
								<Input
									min={0}
									type={"number"}
									className="p-2"
									addonAfter={<span>&#13217;</span>}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11}>
							<Form.Item
								name={["capacity"]}
								label={t(
									"How many guests can stay in this room?"
								)}
								rules={[
									{
										required: true,
										message: `${t(
											"Room guests count is required!"
										)}`,
									},
								]}
							>
								<InputNumber className="w-100" min={1} />
							</Form.Item>
						</Col>
					</Row>
				</div>
			</Card>
			<Form.Item>
				<Button
					type="primary"
					className="m-1"
					size="large"
					onClick={onFinish}
				>
					{t("Create room")}
				</Button>
			</Form.Item>
		</Form>
	);
}
