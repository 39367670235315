import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, InputNumber, Row } from "antd";
import SelectResource from "components/fields/SelectResource";
import { t } from "i18n";
import React from "react";
import { formRules } from "utils/form";

export function RoomBedOptions() {
	return (
		<Card className="my-3">
			<div className="my-0 p-2">
				<h3>{t("Bed options")}</h3>
				<p>{t("existing beds")}</p>
				<Row justify="space-between">
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.List
							name="beds"
							initialValue={[
								{
									typeId: 1,
									count: 1,
								},
							]}
						>
							{(fields, { add, remove }) => (
								<>
									{fields.map(
										({ key, name, ...restField }) => {
											return (
												<div
													className="d-flex align-items-center"
													key={key}
												>
													<Form.Item
														name={[name, "id"]}
														hidden
													>
														<InputNumber />
													</Form.Item>
													<Form.Item
														rules={
															formRules.required
														}
														className="w-100 m-1"
														name={[name, "typeId"]}
													>
														<SelectResource resource="rooms/bed-types" />
													</Form.Item>
													<Form.Item
														rules={
															formRules.required
														}
														{...restField}
														name={[name, "count"]}
														className="m-1"
													>
														<InputNumber min={1} />
													</Form.Item>

													{fields.length > 1 ? (
														<MinusCircleOutlined
															onClick={() =>
																remove(name)
															}
														/>
													) : (
														""
													)}
												</div>
											);
										}
									)}
									<Form.Item>
										<Button
											type="dashed"
											className="m-1"
											onClick={() =>
												add({
													type: 1,
													count: 1,
												})
											}
											icon={<PlusOutlined />}
										>
											{t("Add bed")}
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</Col>
				</Row>
			</div>
		</Card>
	);
}
