import { Card, Col, Row } from "antd";
import dayjs from "dayjs";
import { t } from "i18n";
import { Link } from "react-router-dom";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export function ReservationItem(props) {
	return (
		<Card className="sync-scroll mb-0">
			<Row>
				<Col span={8} sm={8} md={8} lg={8}>
					<div>
						<h4 className="my-0 d-flex align-items-center">
							<img
								src="https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg"
								alt="Uzbek flug"
								style={{
									width: "25px",
									height: "15px",
									objectFit: "cover",
									marginRight: 5,
									borderRadius: 3,
								}}
							/>
							<span>
								{props.reservation.customer.first_name +
									" " +
									props.reservation.customer.last_name}
							</span>
							<span
								style={{
									padding: "2px 4px",
									marginLeft: 10,
									borderRadius: 3,
									background:
										"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
									color: "#fff",
									fontSize: 10,
								}}
							>
								{t("guest")}
							</span>
						</h4>

						<p className="m-0">
							{props.reservation?.records?.map((room) => {
								return (
									getNameTranslation(room.room.room_type) +
									" "
								);
							})}
						</p>
					</div>
				</Col>
				<Col span={6} sm={6} md={6} lg={10}>
					<div>
						<p className="m-0 d-flex align-items-center">
							<span>
								<b>{t("Check-in")}: </b>
							</span>
							{dayjs(props.reservation.checkin).format(
								"MMM DD, YYYY"
							)}
						</p>
						<p className="m-0 d-flex align-items-center">
							<b>{t("Check-out")}: </b>
							{dayjs(props.reservation.checkout).format(
								"MMM DD, YYYY"
							)}
						</p>

						<p className="m-0">
							<b>{t("Guest count")} :</b>{" "}
							{props.reservation?.records?.reduce(
								(accumulator, currentValue) => {
									return (
										accumulator + currentValue.guest_count
									);
								},
								0
							)}
						</p>
					</div>
				</Col>
				<Col span={6} sm={6} md={6} lg={6}>
					<div>
						<h5 className="m-0 ">
							<span>
								UZS{" "}
								{Intl.NumberFormat({
									style: "currency",
									maximumSignificantDigits: 3,
								}).format(props.reservation?.total)}
							</span>
						</h5>
						<p className="m-0 p-0">
							{dayjs(props.reservation?.createdAt).format(
								"MMM DD, YYYY"
							)}
						</p>
						<p className="m-0 p-0">
							<Link to={`reservations/${props.reservation.id}`}>
								ID: {props.reservation.id}
							</Link>
						</p>
					</div>
				</Col>
			</Row>
		</Card>
	);
}
