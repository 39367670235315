import mainApiInstence from "./mainApiInstence";

export class RoomsApi {
	static get() {
		return mainApiInstence.get("/property/rooms");
	}
	static getById(id: number) {
		return mainApiInstence.get(`/property/rooms/${id}`);
	}
	static create(data: any) {
		return mainApiInstence.post("/property/rooms", data);
	}
	static update(data: any, id: number) {
		return mainApiInstence.put(`/property/rooms/${id}`, data);
	}
	static async delete(id: any) {
		return mainApiInstence.delete(`/property/rooms/${id}`);
	}
}
