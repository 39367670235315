import { Card, Col, Modal, Row, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import TraslateService from "services/TranslateService";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";
import PhotoModal from "./Modal";

export function RoomItem(props) {
	return (
		<Card className="my-3">
			<div className="my-0 p-2">
				<Row justify="start">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h3>
							{TraslateService(
								props.item.room_type.name,
								props.item.room_type.name_translations
							)}{" "}
							({props.length} {t("photos")})
						</h3>

						<Upload
							listType="picture-card"
							fileList={props.fileList.filter((file) =>
								file.rooms_ids?.includes(props.item.id)
							)}
							accept="image/png, image/jpeg"
							onPreview={props.handlePreview}
							onChange={(info) =>
								props.handleChangeByRoom(info, props.item.id)
							}
							onRemove={props.deleteFile}
							customRequest={imageUploadCustomRequest} // multiple={true}
							beforeUpload={props.beforeUpload}
						>
							{props.uploadButton}
						</Upload>

						<Modal
							open={props.previewOpen}
							footer={null}
							onCancel={props.handleCancel}
							destroyOnClose
							centered
							width={1000}
						>
							{props.previewOpen && (
								<PhotoModal
									tags={props.tags}
									rooms={props.rooms}
									previewImage={props.previewImage}
									onFinish={props.onFinishModal}
								/>
							)}
						</Modal>
					</Col>
				</Row>
			</div>
		</Card>
	);
}
