import Loading from "components/shared-components/Loading";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CalendarApi } from "../../../../api/CalendarApi";
import CalendarActions from "../../../../redux/calendar/calendar.actions";
import { getCalendarState } from "../../../../redux/calendar/calendar.selector";
import { CalendarUpdateFieldNameEnum } from "../../../../types/calendar.types";
import { mergeUpdatedDate } from "../../../../utils/array";
import { isSunday } from "../../../../utils/date";

export function PriceCell({
	rate,
	dateIndex,
}: {
	rate?: any;
	dateIndex: number;
}) {
	const dispatch = useDispatch();
	const { data } = getCalendarState();
	const currentdate = data.date_range[dateIndex];
	const [loading, setLoading] = useState({
		loadingIndex: currentdate,
		loading: false,
	});
	const record = rate.dates;
	var dataPrice = {
		price: "",
		active: true,
		room_id: rate.room_id,
		rate_id: rate.id,
		currentdate: currentdate,
	};
	if (record[currentdate]) {
		dataPrice = {
			...dataPrice,
			price: record[currentdate].price,
			active: record[currentdate].active,
		};
	}
	const room = data.rooms.find((r) => r.id === rate.room_id);
	const isActive = room?.dates[currentdate]?.active;

	const handleKeyboardEvent = (e: any, newInfo: any) => {
		const key = "Enter";
		const isWrong = Number(e.currentTarget.value) > 0;
		const isDifferent =
			Number(e.currentTarget.value) !== Number(dataPrice.price);
		if (
			(e._reactName === "onBlur" || e.key === key) &&
			isWrong &&
			isDifferent
		) {
			setLoading({ ...loading, loading: true });
			CalendarApi.update({
				updates: [
					{
						field_name: CalendarUpdateFieldNameEnum.price,
						field_value: Number(e.currentTarget.value),
						rate_id: newInfo.rate_id,
					},
				],
				room_id: newInfo.room_id,
				from_date: newInfo.currentdate,
				until_date: newInfo.currentdate,
			})
				.then((res) => {
					const calendar_data = mergeUpdatedDate(data, res);

					dispatch(CalendarActions.updateData(calendar_data));
					setLoading({ ...loading, loading: false });
				})
				.catch((err) => {
					console.log(err);
					setLoading({ ...loading, loading: false });
				});
		}
	};

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		// const isWrong = Number(e.target.value) > 0;
		// if (!isWrong) {
		// 	return (e.target.value = e.target.value.replace(
		// 		"",
		// 		dataPrice.price
		// 	));
		// }
		if (/^0/.test(e.target.value)) {
			e.target.value = e.target.value.replace(/^0/, "");
		}
	}

	return (
		<div
			className={
				`price-cell ${rate.independed_rate_id ? `disabled` : ``} ${
					isSunday(new Date(currentdate)) ? "border-right" : ""
				}` + (isActive ? "" : " unavailable")
			}
			// onClick={() => {
			// 	Chat.send({ ...dataPrice, open: true }, "aviability_popup");
			// }}
		>
			{loading.loadingIndex === currentdate && loading.loading ? (
				<Loading size={22} />
			) : (
				<div className="tooltip">
					<span>UZS</span>
					<input
						id="price"
						type="number"
						key={dataPrice.price}
						min={1}
						disabled={rate.independed_rate_id}
						max={1000000}
						defaultValue={dataPrice.price}
						onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
							handleKeyboardEvent(e, dataPrice)
						}
						onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
							handleKeyboardEvent(e, dataPrice)
						}
						onChange={onChange}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
					<span className="tooltiptext">
						{dataPrice.price ? dataPrice.price : "empty"}
					</span>
				</div>
			)}
		</div>
	);
}
