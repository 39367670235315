import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo.svg";
export default function Navbar() {
	return (
		<div
			className="navbar"
			style={{
				background:
					"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
				padding: "10px 0",
			}}
		>
			<div className="container">
				<div className="logo">
					<Link>
						<img src={logo} alt="logo" />
					</Link>
				</div>
			</div>
		</div>
	);
}
