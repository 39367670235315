import { Col, Form, Row, Select } from "antd";
import { RoomsApi } from "api/RoomsApi";
import { RoomTypesApi } from "api/RoomTypeApi";
import BackButton from "components/shared-components/BackButton";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MessagesService } from "services/Messages";
import RoomForm from "./components/RoomForm";

const Option = { Select };

export default function EditRoom() {
	const { id } = useParams();
	const [roomType, setRoomType] = useState();
	const [data, setdata] = useState({});
	const [loading, setloading] = useState(false);
	const [initialLoading, setinitialLoading] = useState(false);
	const history = useHistory();
	const [form] = Form.useForm();

	useEffect(() => {
		setinitialLoading(true);
		RoomsApi.getById(id)
			.then((data) => {
				setdata(data.data);
				setinitialLoading(true);
				RoomTypesApi.getById(data.data.room_type.id)
					.then((res) => {
						setRoomType(res);
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {
						setinitialLoading(false);
					});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setinitialLoading(false);
			});
	}, []);
	const onFinish = () => {
		setloading(true);
		form.validateFields()
			.then(async () => {
				await RoomsApi.update(form.getFieldValue(), id)
					.then((data) => {
						setloading(false);
						form.resetFields();
						MessagesService.success();
						history.push("/app/room-details");
					})
					.catch((err) => {
						MessagesService.error();
						console.log("Room update error: ", err);
						setloading(false);
					});
			})
			.catch((info) => {
				console.error(t("Validate Failed:"), info);
			});
	};

	if (!initialLoading) {
		return (
			<div className="h-100">
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="">
						<Col xs={24} sm={24} md={24} lg={20}>
							<div className="d-flex align-items-center mb-3">
								<BackButton />
								<h2 className="m-0 p-0">{t("Edit")}</h2>
							</div>

							<p>
								{t(
									"Start by telling us your property's name, contact details and address."
								)}
							</p>

							<RoomForm
								initialValues={data}
								roomType={roomType}
								setRoomType={setRoomType}
								loading={loading}
								form={form}
								onFinish={onFinish}
								submitBtn={t("Save")}
							/>
						</Col>
					</Row>
				</div>
			</div>
		);
	} else {
		return <Loading />;
	}
}
