import { Button, Checkbox, Col, Form, Image, Row, Select } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { t } from "i18n";
import React from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { MessagesService } from "services/Messages";

export default function PhotoModal({ previewImage, onFinish, tags, rooms }) {
	const [form] = Form.useForm();

	const onFinishInner = async () => {
		await PropertyApi.updatePhotos(
			{
				id: previewImage.id,
				sort: 1,
				tags_ids: form.getFieldValue().tags_ids,
				rooms_ids: form.getFieldValue().rooms_ids,
			},
			previewImage.id
		)
			.then((data) => {
				onFinish(data);
				MessagesService.success();
			})
			.catch(MessagesService.error);
	};
	return (
		<Row
			style={{
				justifyContent: "space-between",
				alignItems: "center",
				gap: "20px",
			}}
		>
			<Col xs={24} sm={24} md={11} lg={11}>
				<Form
					name="photoItem"
					form={form}
					onFinish={onFinishInner}
					initialValues={previewImage}
				>
					<Form.Item
						name={"tags_ids"}
						label={t("Select tags for this photo")}
						labelCol={{
							span: 24,
						}}
					>
						<Select
							mode="multiple"
							allowClear
							style={{
								width: "100%",
							}}
							placeholder="Please select"
							options={tags.map((item) => {
								return {
									value: item.id,
									label: getNameTranslation(item),
								};
							})}
						/>
					</Form.Item>
					<Form.Item
						name={"rooms_ids"}
						label={t("Indicate if this is a room photo:")}
						labelCol={{
							span: 24,
						}}
					>
						<Checkbox.Group>
							<Row>
								{rooms.map((item, index) => {
									return (
										<Col span={24}>
											<Checkbox
												key={index}
												value={item.id}
											>
												{getNameTranslation(
													item.room_type
												)}
											</Checkbox>
										</Col>
									);
								})}
							</Row>
						</Checkbox.Group>
					</Form.Item>

					<Form.Item>
						<Button htmlType="submit" type="primary">
							{t("Finish")}
						</Button>
					</Form.Item>
				</Form>
			</Col>
			<Col xs={24} sm={24} md={13} lg={12}>
				<Image
					alt="example"
					style={{
						width: "100%",
						height: "100%",
						objectFit: "contain",
					}}
					src={previewImage.url || previewImage.preview}
				/>
			</Col>
		</Row>
	);
}
