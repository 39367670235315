import NotFound from "components/pages/404";
import TermsOfUse from "components/pages/terms-of-use";
import "i18n";
import AuthLayout from "layouts/auth-layout";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "../configs/AppConfig";
import AppLayout from "../layouts/app-layout";
import Properties from "./app-views/properties";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				isAuthenticated ? (
					children
				) : (
					<Redirect
						to={{
							pathname: AUTH_PREFIX_PATH,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
}

export const Views = (props) => {
	const { token, location, direction } = props;

	return (
		<Switch>
			<Route exact path="/">
				<Redirect to={APP_PREFIX_PATH} />
			</Route>

			<Route exact path="/terms-of-use" component={TermsOfUse} />
			<Route path={AUTH_PREFIX_PATH}>
				<AuthLayout direction={direction} />
			</Route>

			<RouteInterceptor
				path={APP_PREFIX_PATH + "/property"}
				isAuthenticated={token}
			>
				<Properties />
			</RouteInterceptor>

			<RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
				<AppLayout direction={direction} location={location} />
			</RouteInterceptor>

			<Route exact path="/404" component={NotFound} />
		</Switch>
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const { locale, direction } = theme;
	const { token } = auth;
	return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
