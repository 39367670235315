import { FileImageOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";
import { DragableUploadListItem } from "../index";

export function UploadPhotoForm(props) {
	return (
		<Card className="my-3">
			<div className="my-0 p-2">
				<Row justify="start">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h3>
							{t("Main gallery")} ({props.fileList.length}{" "}
							{t("photos")})
						</h3>

						<Upload
							accept="image/png, image/jpeg"
							onChange={props.onChange}
							fileList={props.fileList}
							customRequest={imageUploadCustomRequest}
							multiple={true}
							showUploadList={false}
							beforeUpload={props.beforeUpload}
						>
							<Button
								type="primary"
								size="middle"
								icon={<FileImageOutlined />}
							>
								{t("Add photos")}
							</Button>
						</Upload>

						<h5 className="mt-4">{t("Upload photos")}</h5>
						<DndProvider backend={HTML5Backend}>
							<Upload
								listType="picture-card"
								fileList={props.fileList}
								accept="image/png, image/jpeg"
								onPreview={props.handlePreview}
								customRequest={imageUploadCustomRequest}
								onRemove={props.deleteFile}
								multiple={true}
								onChange={props.onChange}
								beforeUpload={props.beforeUpload}
								itemRender={(
									originNode,
									file,
									currFileList
								) => (
									<DragableUploadListItem
										originNode={originNode}
										file={file}
										fileList={currFileList}
										moveRow={props.moveRow}
									/>
								)}
							>
								{props.uploadButton}
							</Upload>
						</DndProvider>
					</Col>
				</Row>
			</div>
		</Card>
	);
}
