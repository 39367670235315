import { Card, Col, Form, Input, Row, Select } from "antd";
import { ResourceApi } from "api/ResourceApi";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export default function PropertyDetails() {
	const [regions, setRegions] = useState([]);

	useEffect(() => {
		ResourceApi.getList("regions", { limit: 100 }).then((r) => {
			setRegions(r.items);
		});
	}, []);

	return (
		<Card>
			<Row gutter={10}>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"property_name"}
						label={t("Full name of the accommodation")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please input the name of the property"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"region"}
						label={t("Region")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please select the region of the property"
								),
							},
						]}
					>
						<Select
							options={regions.map((r) => ({
								label: getNameTranslation(r),
								value: r.id,
							}))}
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? "")
									.toLowerCase()
									.localeCompare(
										(optionB?.label ?? "").toLowerCase()
									)
							}
							showSearch
						/>
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					{/* <Alert
						message={t("Property address")}
						description={t(
							"Please make sure you provide us with your property's registered address."
						)}
						type="info"
						showIcon
						closable
					/> */}

					<Form.Item
						name={"address"}
						label={t("Address (street name and house number)")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please input the address of the property"
								),
							},
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						label={t("Phone Number")}
						name={"phone_number"}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								len: 13,
								message: `${t(
									"Please input your Phone Number!"
								)}`,
							},
						]}
					>
						<PhoneNumberWithPrefix />
					</Form.Item>
				</Col>
			</Row>
		</Card>
	);
}
