import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import update from "immutability-helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { MessagesService } from "services/Messages";
import { RoomItem } from "./components/RoomItem";
import { UploadPhotoForm } from "./components/UploadPhotoForm";
function ValidateFile(file, img) {
	const isLargeFile = file.size && file.size > 5 * 1024 * 1024;
	if (isLargeFile) {
		throw new Error(
			`${file.name} ${t("Image size should not exceed 5mb.")}`
		);
	}
}

const type = "DragableUploadList";

export const DragableUploadListItem = ({
	originNode,
	moveRow,
	file,
	fileList,
}) => {
	const ref = useRef();
	const index = fileList.indexOf(file);
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: type,
		collect: (monitor) => {
			const { index: dragIndex } = monitor.getItem() || {};
			if (dragIndex === index) {
				return {};
			}
			return {
				isOver: monitor.isOver(),
				dropClassName:
					dragIndex < index
						? " drop-over-downward"
						: " drop-over-upward",
			};
		},
		drop: (item) => {
			moveRow(item.index, index);
		},
	});
	const [, drag] = useDrag({
		type,
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	drop(drag(ref));
	const errorNode = (
		<Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
	);
	return (
		<div
			ref={ref}
			className={`ant-upload-draggable-list-item ${
				isOver ? dropClassName : ""
			}`}
			style={{ cursor: "move" }}
		>
			{file.status === "error" ? errorNode : originNode}
		</div>
	);
};

export default function UploadPhotos() {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [tags, setTags] = useState([]);
	const [rooms, setrooms] = useState([]);
	const [previewImage, setPreviewImage] = useState();
	const [fileList, setFileList] = useState([]);

	const handleCancel = () => {
		setPreviewOpen(false);
		setPreviewImage(null);
	};

	function updateOneFile(data) {
		setFileList(
			fileList.map((f) => {
				if (f.id === data.id) {
					return serilizePhoto(data);
				}
				return f;
			})
		);
	}

	const moveRow = useCallback(
		(dragIndex, hoverIndex) => {
			const dragRow = fileList[dragIndex];
			setFileList(
				update(fileList, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragRow],
					],
				})
			);
		},
		[fileList]
	);

	useEffect(() => {
		if (fileList.length && fileList.every((file) => file.id)) {
			const sorts = fileList.map((v, i) => {
				return {
					sort: i + 1,
					id: v.id,
				};
			});
			if (sorts.length) {
				PropertyApi.updatePhtosSort({ sorts: sorts });
			}
		}
	}, [fileList]);

	function onFinishModal(data) {
		updateOneFile(data);
		setPreviewOpen(false);
		setPreviewImage(null);
	}

	function fetchPhotos() {
		ResourceApi.getList("property/photos").then((data) => {
			setFileList(data.items.map(serilizePhoto));
		});
	}

	async function fetchAllData() {
		fetchPhotos();
		await ResourceApi.getList("photo-tags").then((data) => {
			setTags(data.items);
		});

		await ResourceApi.getList("property/rooms").then((data) => {
			setrooms(data.items);
		});
	}

	useEffect(() => {
		fetchAllData();
	}, []);

	// HANDLERS
	const handlePreview = async (file) => {
		setPreviewImage(file);
		setPreviewOpen(true);
	};

	const onChange = ({ fileList, file }) => {
		try {
			if (file.status === "removed") {
				return;
			}
			let img = new Image(file);
			img.src = file?.response?.path;
			img.className = file.name;
			ValidateFile(file, img);
			beforeUpload(file);
			if (file.status === "done") {
				file.url = file.response.path;
				file.id = file.response.id;
				MessagesService.success();
			}
			setFileList(fileList);
		} catch (error) {
			MessagesService.error(error);
		}
	};

	const handleChangeByRoom = async (info, room_id) => {
		try {
			if (info.file.status === "removed") {
				return;
			}
			ValidateFile(info.file);
			info.file.rooms_ids = [room_id];

			if (info.file.status === "done") {
				info.file.url = info.file.response.path;
				info.file.id = info.file.response.id;
				if (room_id) {
					await PropertyApi.updatePhotos(
						{
							rooms_ids: [room_id],
							sort: 1,
						},
						info.file.response.id
					).then(updateOneFile);
				}
				MessagesService.success();
			}

			if (!fileList.find((f) => f.uid === info.file.uid)) {
				setFileList([...fileList, info.file]);
			} else {
				setFileList(
					fileList.map((f) => {
						return f.uid === info.file.uid ? info.file : f;
					})
				);
			}
		} catch (error) {
			MessagesService.error(error);
		}
	};

	const deleteFile = async (info) => {
		await PropertyApi.deletePhoto(info.id)
			.then(() => {
				MessagesService.success();
				setFileList(fileList.filter((item) => item.id !== info.id));
			})
			.catch(MessagesService.error);
	};

	const beforeUpload = (file) => {
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.addEventListener("load", (event) => {
				const _loadedImageUrl = event.target?.result;
				const image = document.createElement("img");
				image.src = _loadedImageUrl;

				image.addEventListener("load", () => {
					const { width, height } = image;

					if (width < 1024 || height < 512) {
						MessagesService.error(
							t("Image measurement must be greater than 1024x512")
						);
						setFileList(
							fileList.filter(
								(item) => item?.originFileObj?.uid !== file?.uid
							)
						);
						resolve(false);
					}
					resolve(true);
				});
			});
		});
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				{t("Upload")}
			</div>
		</div>
	);

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-3 mt-2">{t("Property photos")}</h2>
						<p>{t("Great photos")}</p>

						<UploadPhotoForm
							previewOpen={previewOpen}
							tags={tags}
							rooms={rooms}
							previewImage={previewImage}
							fileList={fileList}
							handleCancel={handleCancel}
							moveRow={moveRow}
							handlePreview={handlePreview}
							onChange={onChange}
							deleteFile={deleteFile}
							uploadButton={uploadButton}
							onFinishModal={onFinishModal}
							beforeUpload={beforeUpload}
						></UploadPhotoForm>

						{rooms.map((item, i) => {
							return (
								<RoomItem
									key={i}
									previewOpen={previewOpen}
									tags={tags}
									rooms={rooms}
									previewImage={previewImage}
									length={
										fileList.filter((file) =>
											file?.rooms_ids?.includes(item.id)
										).length
									}
									fileList={fileList}
									handleCancel={handleCancel}
									handlePreview={handlePreview}
									handleChangeByRoom={handleChangeByRoom}
									deleteFile={deleteFile}
									uploadButton={uploadButton}
									item={item}
									onFinishModal={onFinishModal}
									beforeUpload={beforeUpload}
								></RoomItem>
							);
						})}
					</Col>
				</Row>
			</div>
		</div>
	);
}

function serilizePhoto(item) {
	return {
		id: item.id,
		url: item.path,
		sort: item.sort,
		rooms_ids: item.rooms,
		tags_ids: item.tags,
	};
}
