import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Popconfirm, Row, Tag } from "antd";
import { CustomersApi } from "api/Customers";
import { PropertyApi } from "api/PropertyApi";
import initial_filter from "constants/InitialFilter";
import { t } from "i18n";
import React, { useEffect, useRef, useState } from "react";
import { MessagesService } from "services/Messages";
import { formatCurrency } from "utils/formatCurrency";
import TableComponent from "../../components/TableComponent";
import { CorporateModal } from "./CorporateModal";

export default function CorporateTransaction() {
	const columns = [
		{
			title: `${t("Legal name")}`,
			dataIndex: "legal_data",
			render: (_, record) => {
				return record.legal_data.name;
			},
		},
		{
			title: `${t("TIN")}`,
			dataIndex: "tin",
			render: (_, record) => {
				return record.legal_data.tin;
			},
		},
		{
			title: `${t("Sum")}`,
			dataIndex: "amount",
			render: (_, record) => {
				return formatCurrency(record.amount / 100);
			},
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			render: (status) => {
				return (
					<>
						{status === 0 ? (
							<Tag color="red">{t("Disactive")}</Tag>
						) : (
							<Tag color="green">{t("Active")}</Tag>
						)}
					</>
				);
			},
		},
		{
			title: `${t("Document")}`,
			dataIndex: "file",
			render: (_, record) => {
				if (record?.file) {
					return (
						<Button icon={<DownloadOutlined />}>
							<a
								href={record?.file?.path}
								style={{ color: "#000" }}
							>
								{t("Download")}
							</a>
						</Button>
					);
				}
			},
		},
		{
			title: `${t("Created")}`,
			dataIndex: "createdAt",
			sorter: true,
			render: (_, record) => {
				return new Date(record.createdAt).toLocaleString("ru");
			},
		},
		{
			title: `${t("Action")}`,
			dataIndex: "action",
			render: (_, record) => {
				if (record.status === 1) {
					return (
						<Popconfirm
							title={t("Disactive transaction?")}
							description={t("Disactive transaction?")}
							okText={t("Yes")}
							cancelText={t("No")}
							onConfirm={() => {
								disActiveTransaction(record.id);
							}}
						>
							<Button type="danger" color="orange">
								{t("Disactive")}
							</Button>
						</Popconfirm>
					);
				}
			},
		},
	];
	const [filter, setFilter] = useState(initial_filter);
	const [data, setdata] = useState([]);
	const [legalData, setLegalData] = useState([]);
	const [loading, setloading] = useState(false);
	const [open, setOpen] = useState(false);
	const [fileList, setFileList] = useState([]);

	useEffect(() => {
		fetch(filter);
	}, []);

	const timeoutId = useRef();
	function getLegalCustomers(value, setLegalData) {
		clearTimeout(timeoutId.current);

		timeoutId.current = setTimeout(async () => {
			CustomersApi.getLegalCustomers({ tin: value })
				.then(({ data }) => {
					setLegalData(data.items);
				})
				.catch((err) => {
					console.log(err);
				});
		}, 50);
	}
	const handleSearch = (newValue) => {
		if (newValue) {
			getLegalCustomers(newValue, setLegalData);
		} else {
			setLegalData([]);
		}
	};
	function fetch(filter) {
		setloading(true);
		CustomersApi.getCorporateTransaction(filter).then(({ data }) => {
			setdata(data);
			setloading(false);
		});
	}
	const deleteFile = async (info) => {
		await PropertyApi.deletePhoto(info.id)
			.then(() => {
				MessagesService.success();
				setFileList(fileList.filter((item) => item.id !== info.id));
			})
			.catch(MessagesService.error);
	};
	function ValidateFile(file) {
		const isLargeFile = file.size && file.size > 5 * 1024 * 1024;

		if (isLargeFile) {
			throw new Error(
				`${file.name} ${t("Image size should not exceed 5mb.")}`
			);
		}
	}
	const handleChange = async (info) => {
		try {
			ValidateFile(info.file);
			if (info.file.status === "done") {
				info.file.url = info.file.response.path;
				info.file.id = info.file.response.id;
			}
			setFileList(info.fileList);
		} catch (error) {
			MessagesService.error(error);
		}
	};
	const [form] = Form.useForm();
	const onFinish = (values) => {
		const { legal_data_id, amount } = values;
		CustomersApi.createCorporateTransaction({
			legal_data_id,
			amount,
			file_id: fileList[0] && fileList[0].id,
		})
			.then(() => {
				MessagesService.success();
				setOpen(false);
				form.resetFields();
				setFileList([]);
				fetch(filter);
			})
			.catch((err) => {});
	};

	const disActiveTransaction = (id) => {
		CustomersApi.disActiveCorporateTransaction(id)
			.then(() => {
				MessagesService.success();
				fetch(filter);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Card>
				<Row style={{ justifyContent: "space-between" }}>
					<Col>
						<h4 className="m-0">{t("Corporate transaction")}</h4>
					</Col>
					<Col>
						<Button type="primary" onClick={() => setOpen(true)}>
							{t("Create corporate transaction")}
						</Button>
					</Col>
				</Row>
			</Card>
			<TableComponent
				columns={columns}
				data={data}
				filter={filter}
				setFilter={setFilter}
				fetch={fetch}
				loading={loading}
			/>
			<CorporateModal
				legalData={legalData}
				open={open}
				setOpen={setOpen}
				fileList={fileList}
				handleSearch={handleSearch}
				deleteFile={deleteFile}
				handleChange={handleChange}
				form={form}
				onFinish={onFinish}
			></CorporateModal>
		</div>
	);
}
