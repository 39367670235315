import {
	Card,
	Checkbox,
	Col,
	Form,
	InputNumber,
	Radio,
	Row,
	Select
} from "antd";
import { ResourceApi } from "api/ResourceApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export default function Amenities({ current, setCurrent }) {
	const [extraBad, setExtraBad] = useState(false);
	const [amenities, setamenities] = useState([]);
	const [child, setchild] = useState(false);
	const [childTwo, setchildTwo] = useState(false);
	const [adult, setadult] = useState(false);
	const onChange = (e) => {
		setExtraBad(e.target.value);
	};

	useEffect(() => {
		ResourceApi.getList("amenities", { limit: 100, page: 1 }).then(
			(data) => {
				setamenities(data.items);
			}
		);
	}, []);

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-3 mt-2">{t("Amenities")}</h2>
						<p>{t("features and services")}</p>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={24} lg={24}>
										<h3>{t("Extra bed options")}</h3>
										<p>{t("bed options")}</p>
										<Form.Item
											name={[
												"amenities",
												"extra_bed",
												"aviable",
											]}
											label={t(
												"Can you provide extra beds?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											initialValue={false}
										>
											<Radio.Group
												buttonStyle="solid"
												onChange={(e) => onChange(e)}
											>
												<Radio.Button value={true}>
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value={false}>
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item>
										{extraBad === true ? (
											<>
												<Form.Item
													name={[
														"amenities",
														"extra_bed",
														"number_beds",
													]}
													label={t(
														"Select the number of extra beds that can be added."
													)}
													className="mt-4"
													labelCol={{
														span: 24,
													}}
													rules={[
														{
															required: true,
														},
													]}
												>
													<Select
														placeholder={t(
															"Extra beds count"
														)}
														options={[
															{
																value: 1,
																label: "1",
															},
															{
																value: 2,
																label: "2",
															},
															{
																value: 3,
																label: "3",
															},
															{
																value: 4,
																label: "4",
															},
														]}
													/>
												</Form.Item>

												<Row>
													<Col span={24}>
														<Checkbox
															name="childtwo"
															className="mb-2"
															onChange={(e) =>
																setchildTwo(
																	e.target
																		.checked
																)
															}
														>
															{t("Children up")}
														</Checkbox>
													</Col>
													{childTwo ? (
														<Row
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<span
																style={{
																	margin: "0 15px",
																}}
															>
																{t("per child")}
															</span>
															<Form.Item
																name={[
																	"amenities",
																	"extra_bed",
																	"baby_price",
																]}
																className="mt-4"
															>
																<InputNumber
																	addonAfter={
																		"UZS"
																	}
																	min={1}
																></InputNumber>
															</Form.Item>
														</Row>
													) : (
														""
													)}

													<Col span={24}>
														<Checkbox
															name="child"
															className="mb-2"
															onChange={(e) =>
																setchild(
																	e.target
																		.checked
																)
															}
														>
															{t("Children")}
														</Checkbox>
													</Col>

													{child ? (
														<Row
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<Form.Item
																name={[
																	"amenities",
																	"extra_bed",
																	"children_up_to",
																]}
																className="mt-4"
																labelCol={{
																	span: 24,
																}}
																initialValue={
																	16
																}
															>
																<Select>
																	<Select.Option
																		value={
																			16
																		}
																	>
																		{t(
																			"Up to 16 years old"
																		)}
																	</Select.Option>
																	<Select.Option
																		value={
																			12
																		}
																	>
																		{t(
																			"Up to 12 years old"
																		)}
																	</Select.Option>
																	<Select.Option
																		value={
																			6
																		}
																	>
																		{t(
																			"Up to 6 years old"
																		)}
																	</Select.Option>
																</Select>
															</Form.Item>
															<span
																style={{
																	margin: "0 15px",
																}}
															>
																{t("per child")}
															</span>
															<Form.Item
																name={[
																	"amenities",
																	"extra_bed",
																	"children_up_to_price",
																]}
																className="mt-4"
															>
																<InputNumber
																	addonAfter={
																		"UZS"
																	}
																	min={1}
																></InputNumber>
															</Form.Item>
														</Row>
													) : (
														""
													)}

													<Col span={24}>
														<Checkbox
															name="adult"
															className="mb-2"
															onChange={(e) =>
																setadult(
																	e.target
																		.checked
																)
															}
														>
															{t("Adults")}
														</Checkbox>
													</Col>
													{adult ? (
														<Row
															style={{
																display: "flex",
																alignItems:
																	"center",
															}}
														>
															<span
																style={{
																	margin: "0 15px",
																}}
															>
																{t("per child")}
															</span>
															<Form.Item
																name={[
																	"amenities",
																	"extra_bed",
																	"adult",
																]}
																className="mt-4"
															>
																<InputNumber
																	addonAfter={
																		"UZS"
																	}
																	min={1}
																></InputNumber>
															</Form.Item>
														</Row>
													) : (
														""
													)}
												</Row>
											</>
										) : (
											""
										)}
									</Col>
								</Row>
							</div>
						</Card>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start">
									<Col xs={24} sm={24} md={20} lg={24}>
										<h3>{t("Amenities")}</h3>
										<p>
											{t("Tell us about your amenities")}
										</p>
										<Form.Item
											name={["amenities", "amenities"]}
											label={t(
												"Most requested by guests"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													validator: (_, value) =>
														value.length > 0
															? Promise.resolve()
															: Promise.reject(
																	new Error(
																		`${t(
																			"Should accept agreement"
																		)}`
																	)
															  ),
												},
											]}
										>
											<Checkbox.Group
												style={{ width: "100%" }}
											>
												<Row>
													{amenities.map((item) => {
														return (
															<Col
																span={12}
																key={item.id}
															>
																<Checkbox
																	value={
																		item.id
																	}
																	className="mb-2"
																>
																	{getNameTranslation(
																		item
																	)}
																</Checkbox>
															</Col>
														);
													})}
												</Row>
											</Checkbox.Group>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
