import React from "react";

const MapIcon = () => {
	return (
		<svg
			width="25"
			height="25"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 64 64"
			fill="fff"
		>
			<path
				d="M39 20H27v1h13a1 1 0 0 0-1-1zM24 24h1V14h-1zM39 23h1v1h-1zM28 17h-1v1h2a1 1 0 0 0-1-1z"
				style={{
					fill: "#3e79f7",
				}}
			/>
			<path
				d="M32 48.7A71.146 71.146 0 0 0 42.93 37C46.74 31.8 50 25.45 50 19a18 18 0 0 0-36 0c0 6.45 3.26 12.8 7.05 18A71.43 71.43 0 0 0 32 48.7zM16 19a16 16 0 1 1 16 16 16.018 16.018 0 0 1-16-16z"
				style={{
					fill: "#3e79f7",
				}}
			/>
			<path
				d="M46 19a14 14 0 1 0-14 14 14.016 14.016 0 0 0 14-14zm-19 6a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V13a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v2h1a3.003 3.003 0 0 1 3 3h8a3.003 3.003 0 0 1 3 3v4a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-2H27zM5.904 38.265 2.22 53h16.55L5.904 38.265z"
				style={{
					fill: "#3e79f7",
				}}
			/>
			<path
				d="m46.148 49.883 16.376 6.094-.587-2.35-13.675-5.08a1 1 0 0 1-.405-1.595L56.546 37H53.89L38.473 54.658a.999.999 0 0 1-.753.342H26.28a.999.999 0 0 1-.753-.342L10.109 37H7.454l14.269 16.342A1 1 0 0 1 20.97 55H1.72l-.5 2h33.01a1 1 0 0 1 .753 1.659L31.184 63h2.667l11.195-12.837a1 1 0 0 1 1.102-.28z"
				style={{
					fill: "#3e79f7",
				}}
			/>
			<path
				style={{
					fill: "#3e79f7",
				}}
				d="m61.349 51.274-3.253-13.009-7.78 8.912 11.033 4.097zM63.97 61.76l-.858-3.43L46.1 51.998 36.505 63H63a1.005 1.005 0 0 0 .79-.38 1.022 1.022 0 0 0 .18-.86zM.72 59l-.69 2.76a1.022 1.022 0 0 0 .18.86A1.005 1.005 0 0 0 1 63h27.526l3.5-4zM37.266 53l13.97-16H45.44a69.966 69.966 0 0 1-12.82 13.78.984.984 0 0 1-1.24 0A69.966 69.966 0 0 1 18.56 37h-5.796l13.97 16z"
			/>
		</svg>
	);
};

export default MapIcon;
