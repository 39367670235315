import { FileImageOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber, Modal, Select, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";

export function CorporateModal(props) {
	return (
		<Modal
			title={t("Create corporate transaction")}
			open={props.open}
			onCancel={() => props.setOpen(false)}
			footer={null}
		>
			<Form
				name="corporate transaction"
				form={props.form}
				onFinish={props.onFinish}
			>
				<Form.Item
					label={t("TIN")}
					name="legal_data_id"
					rules={[
						{
							required: true,
							message: t("Please select legal tin"),
						},
					]}
					labelCol={{
						span: 24,
					}}
				>
					<Select
						placeholder={t("Please select legal")}
						allowClear
						showSearch
						onSearch={props.handleSearch}
						notFoundContent={null}
						defaultActiveFirstOption={false}
						showArrow={false}
						filterOption={false}
						options={props.legalData.map((item) => {
							return {
								label: item.tin,
								value: item.id,
							};
						})}
					/>
				</Form.Item>
				<Form.Item
					label={t("Amount")}
					name="amount"
					labelCol={{
						span: 24,
					}}
					rules={[
						{
							required: true,
							message: t("Please input amount"),
						},
					]}
					type="number"
				>
					<InputNumber
						placeholder={t("Amount")}
						style={{
							width: "100%",
						}}
					/>
				</Form.Item>
				<Form.Item
					label={t("Transaction document")}
					name="file_id"
					labelCol={{
						span: 24,
					}}
				>
					<Upload
						accept=".pdf, .doc, .docx, .xls, .xlsx"
						onChange={props.handleChange}
						fileList={props.fileList}
						customRequest={imageUploadCustomRequest}
						showUploadList={true}
						onRemove={props.deleteFile}
						maxCount={1}
						style={{
							width: "100%",
						}}
					>
						{props.fileList.length < 1 ? (
							<Button
								style={{
									width: "100%",
								}}
							>
								<FileImageOutlined /> {t("Upload")}
							</Button>
						) : (
							""
						)}
					</Upload>
				</Form.Item>

				<Form.Item
					style={{
						display: "flex",
						justifyContent: "end",
						width: "100%",
					}}
				>
					<Button type="primary" htmlType="submit">
						{t("Save")}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
