import { t } from "i18n";

export const PolicyTypes = {
	0: `${t("Non-refundable")}`,
	1: `${t("Flexible - 18:00 on arrival day")}`,
	2: `${t("Flexible - 1 day")}`,
	3: `${t("Flexible - 2 day")}`,
	4: `${t("Flexible - 4 day")}`,
	5: `${t("Flexible - 5 day")}`,
	6: `${t("Flexible - 6 day")}`,
	7: `${t("Flexible - 7 day")}`,
	8: `${t("Flexible - 8 day")}`,
	9: `${t("Flexible - 9 day")}`,
	10: `${t("Flexible - 10 day")}`,
	14: `${t("Flexible - 14 day")}`,
	30: `${t("Flexible - 30 day")}`,
	99: `${t("Any time")}`,
};
