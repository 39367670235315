import React from "react";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store";
import Views from "./views";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import cacheClear from "utils/cacheClear";
import { PersistGate } from "redux-persist/integration/react";

cacheClear();

//style={{ backgroundColor: "rgb(237 237 237)" }}
function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<Router>
						<Switch>
							<Route path="/" component={Views} />
						</Switch>
					</Router>
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
