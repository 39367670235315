import { t } from "i18n";

const ReviewsCatalog = () => {
	return {
		0: t("Stuff"),
		1: t("Cleanliness"),
		2: t("Location "),
		3: t("Facilities"),
		4: t("Comfort"),
		5: t("Value of money "),
	};
};
export default ReviewsCatalog;
