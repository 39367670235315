import { getLang } from "i18n";
import { TItemWithNameTranslations } from ".";

const lang = getLang();

export function getTranslation(translations: any) {
	return translations[lang];
}

export function getNameTranslation(item: TItemWithNameTranslations) {
	if (item?.name_translations) {
		return getTranslation(item?.name_translations) || item.name;
	}
	return item.name;
}

export function getDescriptionTranslation(item: TItemWithNameTranslations) {
	if (item?.description_translations) {
		return (
			getTranslation(item?.description_translations) || item.description
		);
	}
	return item.description;
}
