import { CheckOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Row, Statistic } from "antd";
import { t } from "i18n";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import thanskImg from "../../../../assets/images/thansk_hotel.png";
import Navbar from "../components/Navbar";

export default function ThanksProperty() {
	const [activeBtn, setActiveBtn] = useState(false);
	const history = useHistory();
	const { Countdown } = Statistic;
	const onFinish = () => {
		setActiveBtn(true);
	};
	return (
		<div className="h-100">
			<Navbar />
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row>
					<Col xs={24} sm={24} md={24} lg={16}>
						<Alert
							type="success"
							className="mt-3"
							message={
								<div>
									<CheckOutlined
										twoToneColor="#eb2f96"
										style={{ fontSize: "16px" }}
									/>
									{"  "}
									<span style={{ fontSize: "16px" }}>
										{t("copy of your agreement")}
									</span>
								</div>
							}
						></Alert>
						<Card className="my-4">
							<Row className="justify-content-between align-items-center">
								<Col xs={24} sm={24} md={24} lg={15}>
									<h2 style={{ fontWeight: "300" }}>
										{t("Whe're building your property")}
									</h2>
									<p>{t("lorem_text")}</p>
									{activeBtn === false ? (
										<div className="d-flex align-items-center">
											<h5>
												{t(
													"The current setup time right now is"
												)}
												:{" "}
											</h5>

											<Countdown
												valueStyle={{
													fontSize: "16px",
													marginLeft: "10px",
													marginTop: "-6px",
													color: "green",
												}}
												format="mm:ss"
												value={Date.now() + 10 * 6000}
												onFinish={onFinish}
											/>
										</div>
									) : (
										""
									)}

									<p>{t("lorem_text2")}</p>
									<Button
										disabled={activeBtn === false}
										type="primary"
										size="large"
										onClick={() => {
											history.push("/app");
										}}
									>
										{t("Take me to my property")}
									</Button>
								</Col>
								<Col xs={24} sm={24} md={24} lg={8}>
									<img
										src={thanskImg}
										alt=""
										style={{ width: "100%" }}
									/>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
