import { Badge, Button, Card, Col, Empty, Menu, Row, Select } from "antd";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReservationItem } from "./ReservationItem";

const menu_types = {
	arrivals: "arrivals",
	departures: "departures",
	new_bookings: "new_bookings",
	stay_overs: "stay_overs",
	// guests_requests: "guests_requests",
};
export function ConvertLocalDateString(data) {
	return new Date(data).toLocaleDateString("ru");
}
function filterBy(reservations, filter) {
	const today = new Date().toLocaleDateString("ru");

	switch (filter) {
		case menu_types.arrivals:
			return reservations.filter(
				(reservation) =>
					ConvertLocalDateString(reservation.checkin) === today
			);
		case menu_types.departures:
			return reservations.filter(
				(reservation) =>
					ConvertLocalDateString(reservation.checkout) === today
			);
		case menu_types.new_bookings:
			return reservations.filter(
				(reservation) =>
					ConvertLocalDateString(reservation.createdAt) === today
			);
		case menu_types.stay_overs:
			return reservations.filter(
				(reservation) =>
					ConvertLocalDateString(reservation.checkin) > today &&
					ConvertLocalDateString(reservation.checkout) < today
			);
		default:
			return reservations;
			break;
	}
}
const getItems = (reservations) => {
	return Object.keys(menu_types).map((key) => ({
		label: (
			<Badge
				count={filterBy(reservations, key).length || "O"}
				style={{ backgroundColor: "#2378DF" }}
				offset={[0, 5]}
			>
				<div style={{ margin: "0px 14px" }}>{t(key)} </div>
			</Badge>
		),
		key,
	}));
};
export default function NavigationMenu() {
	const [step, setStep] = useState("arrivals");
	const [reservations, setReservations] = useState([]);
	const [loading, setloading] = useState(false);
	const [day, setday] = useState("today");
	const onClick = (e) => {
		setStep(e.key);
	};
	const today = new Date().toLocaleDateString("ru");

	useEffect(() => {
		setloading(true);
		ResourceApi.getList("property/reservations/dashboard", {
			day: day,
		}).then((data) => {
			setloading(false);
			setReservations(data);
		});
	}, [day]);

	return (
		<div>
			<Card>
				<Row className="d-flex justify-content-between align-items-center">
					<Col xs={24} sm={24} md={24} lg={21}></Col>
					<div className="d-flex justify-content-between align-items-center">
						<h4 style={{ margin: 0, padding: 0 }}>
							{t("Reservations overview:")}
						</h4>
						<Select
							onChange={(day) => setday(day)}
							size="small"
							style={{ width: 100, marginLeft: 10 }}
							defaultValue={day}
						>
							<Select.Option value="today">
								{t("Today")}
							</Select.Option>
							<Select.Option value="tomorrow">
								{t("Tomorrow")}
							</Select.Option>
						</Select>
					</div>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={3}
						className="d-flex justify-content-end"
					>
						<Button type="link">
							<Link to="/app/reservations">
								{t("All reservations")}
							</Link>
						</Button>
					</Col>
				</Row>
				{!loading ? (
					<Menu
						onClick={onClick}
						mode="horizontal"
						defaultActiveFirst={"active"}
						items={getItems(reservations)}
					/>
				) : (
					<Loading />
				)}
				{menuItems(step)}
			</Card>
		</div>
	);

	function menuItems(param) {
		switch (param) {
			case "arrivals":
				return (
					<>
						{filterBy(reservations, menu_types.arrivals).length >
						0 ? (
							filterBy(reservations, menu_types.arrivals).map(
								(reservation, index) => (
									<ReservationItem
										key={index}
										reservation={reservation}
									></ReservationItem>
								)
							)
						) : (
							<Empty />
						)}
					</>
				);
			case "departures":
				return (
					<>
						{filterBy(reservations, menu_types.departures).length >
						0 ? (
							filterBy(reservations, menu_types.departures).map(
								(reservation, index) => (
									<ReservationItem
										key={index}
										reservation={reservation}
									></ReservationItem>
								)
							)
						) : (
							<Empty />
						)}
					</>
				);
			case "new_bookings":
				return (
					<>
						{filterBy(reservations, menu_types.new_bookings)
							.length > 0 ? (
							filterBy(reservations, menu_types.new_bookings).map(
								(reservation, index) => (
									<ReservationItem
										key={index}
										reservation={reservation}
									></ReservationItem>
								)
							)
						) : (
							<Empty />
						)}
					</>
				);
			case "stay_overs":
				return (
					<>
						{filterBy(reservations, menu_types.stay_overs).length >
						0 ? (
							filterBy(reservations, menu_types.stay_overs).map(
								(reservation, index) => (
									<ReservationItem
										key={index}
										reservation={reservation}
									></ReservationItem>
								)
							)
						) : (
							<Empty />
						)}
					</>
				);
		}
	}
}
