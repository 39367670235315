export const HotelStatus = {
	0: "INACTIVE",
	1: "ACTIVE",
	2: "DRAFT",
	3: "MODERATION",
};
export const HotelStatusColors = {
	0: "red",
	1: "green",
	2: "default",
	3: "warning",
};
