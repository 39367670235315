import Input from "antd/lib/input/Input";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";

export function getCustomersFilterFields() {
	return [
		{
			name: ["customer", "first_name"],
			label: t("first_name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: ["customer", "last_name"],
			label: t("last_name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: ["customer", "phone_number"],
			label: t("Phone number"),
			span: 4,
			type: "number",
			InputComponent: PhoneNumberWithPrefix,
		},
		{
			name: "tin",
			label: t("TIN"),
			span: 4,
			InputComponent: Input,
		},
	];
}
