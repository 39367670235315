import { Card, Checkbox, Col, Row, Table } from "antd";
import Column from "antd/lib/table/Column";
import { CustomersApi } from "api/Customers";
import { FilterView } from "components/FilterView";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCustomersFilterFields } from "./getCustomersFilterFields";

const initial_filter = {
	first_name: "",
	last_name: "",
};

export default function Customers() {
	const [filter, setfilter] = useState(initial_filter);
	const [data, setdata] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		fetch(filter);
	}, []);

	const fetch = (filter) => {
		setLoading(true);
		CustomersApi.getCustomers({
			...filter,
		})
			.then(({ data }) => {
				setdata(data.items);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Row
				style={{
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Col>
					<h2>{t("Customers")}</h2>
				</Col>
			</Row>
			<Row style={{ justifyContnet: "start" }}>
				<Col xs={12} sm={6} md={6} lg={5}>
					<Checkbox
						onChange={(e) => {
							setfilter({
								...filter,
								is_corporate_customer: e.target.checked
									? true
									: "",
							});
						}}
						style={{ fontWeight: 600 }}
					>
						{t("Is corporate customer")}
					</Checkbox>
				</Col>
				<FilterView
					filter={filter}
					onSubmit={(new_filter) => {
						const f = { ...filter, ...new_filter };
						setfilter(f);
						fetch(f);
					}}
					fields={getCustomersFilterFields()}
				/>
			</Row>

			<Card>
				<Table rowKey={"id"} dataSource={data} loading={loading}>
					<Column
						title={t("Guest name")}
						dataIndex="customer"
						key="customer"
						render={(_, alldata) => {
							return (
								<Link to={`customers/${alldata.id}`}>
									{alldata.first_name +
										" " +
										alldata.last_name}
								</Link>
							);
						}}
					/>
					<Column
						title={t("Phone number")}
						dataIndex="phone_number"
						key="phone_number"
						render={(_, alldata) => {
							return alldata.phone_number;
						}}
					/>
					<Column
						title={t("Email")}
						dataIndex="email"
						key="email"
						render={(_, alldata) => {
							return alldata.email;
						}}
					/>
					<Column
						title={t("Booked on")}
						dataIndex="createdAt"
						key="createdAt"
						render={(date) => {
							return dayjs(date).format("MMM DD, YYYY");
						}}
					/>
					<Column
						title={t("Updated on")}
						dataIndex="updatedAt"
						key="updatedAt"
						render={(date) => {
							return dayjs(date).format("MMM DD, YYYY");
						}}
					/>
				</Table>
			</Card>
		</div>
	);
}
