import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import { getDateISOString } from "utils/date";

const { RangePicker } = DatePicker;

type Props = {
	onChange: (range: string[]) => void;
	value: string[];
	disabledDate: any;
};

export default function DataRanger({ onChange, value, disabledDate }: Props) {
	return (
		<RangePicker
			className="mb-4"
			allowClear={false}
			format={"YYYY-MM-DD"}
			value={[moment(new Date(value[0])), moment(new Date(value[1]))]}
			onCalendarChange={(val) => {
				if (val && val[0] && val[1]) {
					const d = val.map((v: any) => getDateISOString(v!._d));
					onChange(d);
				}
			}}
			disabledDate={disabledDate}
		/>
	);
}
