import QueryString from "qs";
import mainApiInstence from "./mainApiInstence";
export class CustomersApi {
	//Reservation
	static getCustomers(query: any) {
		return mainApiInstence.get(
			`/customers?${QueryString.stringify(query)}`
		);
	}
	static getCustomer(id: number) {
		return mainApiInstence.get(`/customers/${String(id)}`);
	}

	static getLegalCustomer(id: number) {
		return mainApiInstence.get(`/legal-data/${String(id)}`);
	}

	static getLegalCustomers(query: any) {
		return mainApiInstence.get(
			`/legal-data?${QueryString.stringify(query)}`
		);
	}
	static editLegalCustomer(id: number, data: any) {
		return mainApiInstence.patch(`/legal-data/${String(id)}`, data);
	}

	static disActiveCorporateTransaction(id: number) {
		return mainApiInstence.put(
			`/corporate-transactions/${String(id)}/deactivate`
		);
	}

	static getCorporateTransaction(query: any) {
		return mainApiInstence.get(
			`/corporate-transactions?${QueryString.stringify(query)}`
		);
	}
	static createCorporateTransaction(data: any) {
		return mainApiInstence.post(`/corporate-transactions`, data);
	}
}
