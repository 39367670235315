import { Card, Table } from "antd";
import { getLang } from "i18n";
import React from "react";

export interface DataType {
	data: any;
	loading?: boolean;
	fetchCalendar?: Function;
	showPricePerGuest?: boolean;
}

export default function BookingTopCalendar({ data }: DataType) {
	const date = new Date(Date.now());
	const lang = getLang();

	const monthYear = date.toLocaleString(lang === "ru" ? "ru-RU" : "en-US", {
		month: "short",
		year: "numeric",
	});

	function DateInfo({ date }: { date?: string }) {
		const paresd_date = new Date(String(date));
		const dayInfo = {
			dayName: paresd_date.toLocaleString(
				lang === "ru" ? "ru-RU" : "en-US",
				{
					weekday: "short",
				}
			),
			dayNum: paresd_date.toLocaleString(
				lang === "ru" ? "ru-RU" : "en-US",
				{
					day: "numeric",
				}
			),
		};

		return (
			<div
				className={`day-info ${
					dayInfo.dayName === "Sun"
						? `bold border-right`
						: dayInfo.dayName === "Sat"
						? `bold`
						: ``
				}`}
			>
				<p>{dayInfo.dayName}</p>
				<p>{dayInfo.dayNum}</p>
			</div>
		);
	}

	function getDate(dates: string[]) {
		const newr: any = {};

		dates.forEach((date) => {
			newr[date] = <DateInfo date={date} />;
		});

		return newr;
	}
	return (
		data && (
			<div className="booking-cal-top">
				<Card bordered={false}>
					<Table
						pagination={false}
						columns={[
							{
								fixed: "left",
								dataIndex: "name",
							},
							{ dataIndex: "date" },
						]}
						dataSource={[
							{
								id: 1,
								fixed: "left",
								dataIndex: "name",
							},
							{ id: 2, date: monthYear },
						]}
						rowKey="id"
						showHeader={false}
					/>
					<Table
						bordered
						pagination={false}
						columns={[
							{
								fixed: "left",
								dataIndex: "name",
								render: (_) => {
									return _;
								},
							},
							...data.date_range.map((data: string) => ({
								dataIndex: data,
								render: (_: any) => (_ ? _ : ""),
							})),
						]}
						scroll={{ x: 500 }}
						dataSource={[
							{
								id: "empty",
								name: "",
								...getDate(data.date_range),
							},
						]}
						rowKey="id"
						className="sync-scroll"
						showHeader={false}
					/>
				</Card>
			</div>
		)
	);
}
