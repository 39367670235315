import { Tag } from "antd";
import { ReservationStatus } from "constants/ReservationStatus";
import { t } from "i18n";
import React from "react";

const StatusColors = {
	0: "red",
	1: "blue",
	2: "green",
};

export default function Status({ status }) {
	return (
		<Tag color={StatusColors[status]}>{t(ReservationStatus[status])}</Tag>
	);
}
