import { Button, Card } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import AmenitieItems from "./components/AmenitieItems";

export default function RoomAmenities() {
	const [amenities, setAmenities] = useState();
	const [rooms, setRooms] = useState();
	const [loading, setLoading] = useState(false);

	function onFinish() {
		setLoading(true);
		const data = { amenities: amenities.map((p) => p.prop) };
		PropertyApi.update(data)
			.then(() => {
				MessagesService.success();
			})
			.catch((e) => MessagesService.error(e.message))
			.finally(() => setLoading(false));
	}

	function margeAmenityWithProp(amenities, amenitiesProps) {
		const newData = [];
		for (let item of amenities.items) {
			const prop = amenitiesProps.amenities.find(
				(a) => item.id === a.amenity_id
			);
			newData.push({
				...item,
				prop: prop || {
					amenity_id: item.id,
					rooms: [],
				},
			});
		}
		return newData;
	}

	useEffect(() => {
		ResourceApi.getList("amenities").then((amenities) => {
			ResourceApi.getList("property/amenities").then((amenitiesProps) => {
				setAmenities(margeAmenityWithProp(amenities, amenitiesProps));
			});
		});
		ResourceApi.getList("property/rooms").then((rooms) => {
			setRooms(rooms.items);
		});
	}, []);

	if (!amenities) return <Loading />;
	return (
		<div>
			<Card>
				<h2>{t("Top amenities")}</h2>
				<p>{t("We know that these")}</p>
				{amenities.map((item) => {
					return (
						<AmenitieItems
							key={item.id}
							amenity={item}
							rooms={rooms}
							amenities={amenities}
							setAmenities={setAmenities}
						/>
					);
				})}
				<Button loading={loading} type="primary" onClick={onFinish}>
					{t("Save")}
				</Button>
			</Card>
		</div>
	);
}
