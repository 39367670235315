import { FileImageOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";

export function PaymentMethondForm(props) {
	return (
		<Form
			name={props.title || "PaymentMethondForm"}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item
				name="name"
				label={t("Payment methods name")}
				rules={[
					{
						required: true,
						message: `${t("Name is required")}`,
					},
				]}
			>
				<Input placeholder={t("Payment methods name")} />
			</Form.Item>
			<Form.Item
				name="image"
				label={t("Upload payment method image")}
				rules={[
					{
						required: true,
						message: `${t("Image is required")}`,
					},
				]}
			>
				<Upload
					accept="image/png, image/jpeg, image/svg"
					onChange={props.handleChange}
					listType="picture-card"
					fileList={props.fileList}
					customRequest={imageUploadCustomRequest}
					showUploadList={true}
					onRemove={props.deleteFile}
				>
					{props.fileList.length < 1 ? (
						<>
							<FileImageOutlined /> {t("Upload")}
						</>
					) : (
						""
					)}
				</Upload>
			</Form.Item>
			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
