import { Button, Card, Input, Select, Table, Tag, Typography } from "antd";
import { LegalDataApi } from "api/LegalDataApi";
import { FilterView } from "components/FilterView";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AntTableSortMapRevert } from "../AllProperties/property.const";

export default function AllLegalDatas() {
	const [data, setData] = useState({
		items: [],
		total: 0,
	});
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState({
		page: 1,
		limit: 20,
		order: "DESC",
		order_by: "createdAt",
		status: 0,
	});
	const columns = [
		{
			title: t("Legal name"),
			dataIndex: "name",
			key: "name",
			render: (text, record) => (
				<Button
					type="link"
					onClick={() =>
						history.push(
							`/app/admin/all-properties/${record.property.id}/legal-data`
						)
					}
				>
					{text}
				</Button>
			),
		},
		{
			title: t("Phone number"),
			dataIndex: "property",
			key: "property",
			render: (text, record) => record.property.phone_number,
		},
		{
			title: t("TIN"),
			dataIndex: "tin",
			key: "tin",
		},
		{
			title: t("MFI"),
			dataIndex: "mfi",
			key: "mfi",
		},
		{
			title: t("OKONX"),
			dataIndex: "okonx",
			key: "okonx",
		},
		{
			title: t("Account"),
			dataIndex: "account",
			key: "account",
		},
		{
			title: t("Bank name"),
			dataIndex: "bank_name",
			key: "bank_name",
		},
		{
			title: t("Status"),
			dataIndex: "status",
			key: "status",
			render: (text, record) => (
				<span>
					{record.status === 1 ? (
						<Tag color="green">{t("Active")}</Tag>
					) : (
						<Tag color="orange">{t("Pending")}</Tag>
					)}
				</span>
			),
		},
		{
			title: `${t("Created")}`,
			dataIndex: "createdAt",
			key: "createdAt",
			render: (value) => {
				return new Date(value).toLocaleString("ru");
			},
			width: 150,
			sorter: true,
		},
		// {
		// 	title: t("Action"),
		// 	dataIndex: "action",
		// 	key: "action",
		// 	render: (text, record) => (
		// 		<a href={`/admin/properties/${record.id}`}>{t("Edit")}</a>
		// 	),
		// },
	];
	const history = useHistory();

	useEffect(() => {
		fetch(filter);
	}, []);

	function fetch(filter) {
		!loading && setLoading(true);
		LegalDataApi.getAllLegalDatas(filter)
			.then(({ data }) =>
				setData({
					items: data.items,
					total: data.total,
				})
			)
			.catch((e) => {
				console.error(e);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div>
			<div>
				<Typography.Title level={1}>
					{t("All legal datas")}
				</Typography.Title>
				<Card>
					<FilterView
						filter={filter}
						onSubmit={(new_filter) => {
							const f = { ...filter, ...new_filter };
							setFilter(f);
							fetch(f);
						}}
						fields={[
							{
								name: "name",
								label: t("Legal name"),
								span: 4,
								InputComponent: Input,
							},
							{
								name: "phone_number",
								label: t("Phone number"),
								span: 4,
								InputComponent: PhoneNumberWithPrefix,
							},
							{
								name: "tin",
								label: t("TIN"),
								span: 4,
								InputComponent: Input,
							},
							{
								name: "status",
								label: t("Status"),
								inputProps: {
									defaultValue: 1,
									options: [
										{
											label: t("Active"),
											value: 1,
										},
										{
											label: t("Pending"),
											value: 0,
										},
									],
									allowClear: true,
								},
								span: 4,
								InputComponent: Select,
							},
						]}
					/>
				</Card>
				<Table
					className="ant-card-bordered rounded"
					onChange={(p, _, sort) => {
						const f = {
							...filter,
							page: p.current,
							limit: p.pageSize,
							order: AntTableSortMapRevert[sort.order],
							order_by: sort.field,
						};
						setFilter(f);
						fetch(f);
					}}
					loading={loading}
					dataSource={data.items}
					columns={columns}
					rowKey="id"
					scroll={{ x: 1300 }}
					pagination={{
						current: filter.page,
						total: data.total,
						pageSize: filter.limit,
					}}
				/>
			</div>
		</div>
	);
}
