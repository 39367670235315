import { Button, Card, Col, Form, Input, Row, Select, Tag } from "antd";
import { ResourceAdminApi } from "api/ResourceAdminApi";
import { ResourceApi } from "api/ResourceApi";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";
import { MessagesService } from "services/Messages";
import TableComponent from "../components/TableComponent";
import ResourseModal from "./Modal";

export default function FacilitiesGroup() {
	const columns = [
		{
			title: `${t("Id")}`,
			dataIndex: "id",
		},
		{
			title: `${t("Name")}`,
			dataIndex: "name",
		},
		{
			title: `${t("Created At")}`,
			dataIndex: "createdAt",
			render: (_, record) => {
				return dayjs(record.createdAt).format("MMM Do YY");
			},
		},
		{
			title: `${t("Status")}`,
			dataIndex: "status",
			render: (_, record) => {
				if (record.status === 0) {
					return <Tag color="red">{t("Inactive")}</Tag>;
				} else if (record.status === 1) {
					return <Tag color="green">{t("Active")}</Tag>;
				}
			},
		},
		{
			title: `${t("Operations")}`,
			dataIndex: "operations",
			render: (_, record) => {
				return (
					<Row>
						<Button onClick={() => EditHandler(record)}>
							{t("Edit")}
						</Button>
						<Button
							onClick={() => deleteHandler(record)}
							type={"primary"}
							danger
							style={{ marginLeft: 10 }}
						>
							{t("Delete")}
						</Button>
					</Row>
				);
			},
		},
	];
	const [data, setdata] = useState([]);
	const [isEditModalOpen, setisEditModalOpen] = useState(false);
	const [isCreateModalOpen, setisCreateModalOpen] = useState(false);
	const [loading, setloading] = useState(false);
	const [form] = Form.useForm();
	const [createForm] = Form.useForm();

	useEffect(() => {
		getAllData();
	}, []);
	function getAllData() {
		setloading(true);
		ResourceApi.getList("facilities/groups", {
			page: 1,
			limit: 1000,
		}).then((data) => {
			setloading(false);
			setdata(
				data.items.map((item) => {
					return { ...item, key: item.id };
				})
			);
		});
	}
	const handleCancel = () => {
		setisCreateModalOpen(false);
		setisEditModalOpen(false);
	};
	const EditHandler = (record) => {
		form.setFieldsValue(record);
		setisEditModalOpen(true);
	};
	const CreateHandler = () => {
		setisCreateModalOpen(true);
	};
	const onFinish = () => {
		ResourceAdminApi.facilitiesGroupPatch(
			{ name: form.getFieldValue().name },
			form.getFieldValue().id
		)
			.then(({ data: newData }) => {
				MessagesService.success();
				const newState = data.map((obj) => {
					if (obj.id === newData.id) {
						return { ...newData, sort_order: obj.sort_order };
					}
					return obj;
				});
				setdata(newState);
				setisEditModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
				setisEditModalOpen(false);
			});
	};
	const onCreate = () => {
		ResourceAdminApi.facilitiesGroupPost({
			...createForm.getFieldValue(),
			sort_order: data.length + 1,
		})
			.then(({ data: newData }) => {
				MessagesService.success();
				setdata([...data, newData]);
				setisCreateModalOpen(false);
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	const deleteHandler = (record) => {
		ResourceAdminApi.facilitiesGroupDelete(record.id)
			.then((f) => {
				setdata(data.filter((item) => item.id != record.id));
				MessagesService.success();
			})
			.catch((err) => {
				MessagesService.error();
				console.log(err);
			});
	};
	return (
		<div>
			<Card>
				<Row style={{ justifyContent: "space-between" }}>
					<Col>
						<h4 className="m-0">{t("Facilities group list")}</h4>
					</Col>
					<Row gutter={10}>
						<Col>
							<Button
								type="primary"
								size="small"
								onClick={() => CreateHandler()}
							>
								{t("Create")}
							</Button>
						</Col>
					</Row>
				</Row>
			</Card>
			<TableComponent columns={columns} data={data} loading={loading} />
			<ResourseModal
				title={t("Edit")}
				isModalOpen={isEditModalOpen}
				handleCancel={handleCancel}
				content={
					<Form
						name="edit"
						layout="vertical"
						form={form}
						onFinish={onFinish}
					>
						<Form.Item name="name" label={t("Facility group name")}>
							<Input placeholder={t("Facility group name")} />
						</Form.Item>

						<ContentTranslationFormItem name="name_translations" />

						<Form.Item name="status" label={t("Status")}>
							<Select
								options={[
									{
										label: `${t("In Active")}`,
										value: 0,
									},
									{
										label: `${t("Active")}`,
										value: 1,
									},
								]}
							/>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit">
								{t("Save")}
							</Button>
						</Form.Item>
					</Form>
				}
			></ResourseModal>

			<ResourseModal
				title={t("Create")}
				isModalOpen={isCreateModalOpen}
				handleCancel={handleCancel}
				content={
					<Form
						name="create"
						layout="vertical"
						form={createForm}
						onFinish={onCreate}
					>
						<Form.Item name="name" label={t("Facility group name")}>
							<Input placeholder={t("Facility group name")} />
						</Form.Item>
						<Form.Item name="status" label={t("Status")}>
							<Select
								options={[
									{
										label: `${t("In Active")}`,
										value: 0,
									},
									{
										label: `${t("Active")}`,
										value: 1,
									},
								]}
							/>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								{t("Create")}
							</Button>
						</Form.Item>
					</Form>
				}
			></ResourseModal>
		</div>
	);
}
