import { Button, Input, Select, Switch } from "antd";
import { CalendarApi } from "api/CalendarApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calendarSelector } from "redux/calendar/calendar.selector";
import Chat from "services/Chat";
import EditOccupancyModal from "./EditOccupancyModal";

var initialData: any = [];

function onCancel() {
	Chat.send({ open: false }, "edit_pricing");
}

export default function EditPriceModalContent({
	strategy,
	fetchCalendar,
}: any) {
	let itemList: any = [];
	const calendar = useSelector(calendarSelector);
	const [priceData, setPriceData] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const default_guest =
		calendar.data.rooms
			.find((item) => item.id === strategy.room_id)
			?.rates.find((r) => r.id === strategy.rate_id)?.default_guest || 0;

	const strategyy: any = calendar.data.rooms
		.find((item) => item.id === strategy.room_id)
		?.rates.find((r) => r.id === strategy.rate_id)?.strategy;

	useEffect(() => {
		initialData = [];
		for (let index = 0; index < strategy.max_capacity; index++) {
			const str = strategyy?.find(
				(item: any) => item.capacity - 1 === index
			);
			if (str) {
				initialData[index] = { ...str, active: true };
			} else {
				initialData.push({
					value: 0,
					add: false,
					capacity: 0,
					active: false,
				});
			}
		}
		setPriceData(initialData);
	}, [strategyy, strategy]);

	function onChange(e: any, index: number) {
		setPriceData((current: Object[]) =>
			current.map((obj, i) => {
				if (i === index) {
					return {
						...obj,
						value: Number(e.target.value),
						capacity: index + 1,
					};
				}
				return obj;
			})
		);
	}
	function checkOnChange(checked: boolean, index: number) {
		if (checked === false) {
			setPriceData((current: Object[]) =>
				current.map((obj, i) => {
					if (i === index) {
						return (current[i] = initialData[index]);
					}
					return obj;
				})
			);
		}
		setPriceData((current: Object[]) =>
			current.map((obj, i) => {
				if (i === index) {
					return {
						...obj,
						active: checked,
						capacity: index + 1,
					};
				}
				return obj;
			})
		);
	}
	const handleChange = (value: string, index: number) => {
		setPriceData((current: Object[]) =>
			current.map((obj, i) => {
				if (i === index) {
					return {
						...obj,
						add: value === "%" ? false : true,
						capacity: index + 1,
					};
				}
				return obj;
			})
		);
	};

	async function onFinish() {
		const haveChanged = priceData
			.filter((item: any) => item.active !== false && item.value !== 0)
			.map(function (v: any) {
				delete v.active;
				return v;
			});

		if (haveChanged.length) {
			setLoading(true);
			await CalendarApi.updateStrategy({
				room_id: strategy.room_id,
				rate_id: strategy.rate_id,
				default_guest: default_guest || strategy.default_guest,
				price_by_guest_strategy: haveChanged,
			})
				.then(() => {
					setLoading(false);
					Chat.send({ open: false }, "edit_pricing");
					// const new_calendar = margeCalendarUpdatedStrategy({
					// 	...res,
					// 	room_id: strategy.room_id,
					// 	rate_id: strategy.rate_id,
					// 	calendar: calendar,
					// });
					// dispatch(CalendarActions.updateData(new_calendar));
					fetchCalendar();
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		} else {
			Chat.send({ open: false }, "edit_pricing");
		}
	}

	if (priceData.length > 0) {
		itemList.push(
			<div key={"asdasd"} className="row content">
				<div className="d-flex">
					<div className="first-col">
						<h4 className="p-4 m-0">{t("Occupancy")}</h4>
					</div>
					<div className="second-col">
						<h4 className="p-4 m-0">{t("Price")}</h4>
					</div>
				</div>

				{Array.from({ length: strategy.max_capacity }, (_, key) => {
					const isActive = priceData[key].active
						? ``
						: `disabled-row`;

					return default_guest === key + 1 ? (
						<div key={key} className="d-flex active-row">
							<div className="first-col p-4 py-3">
								<h5 className="font-weight-normal text-nowrap m-0">
									{default_guest} {t("guest")}
								</h5>
								<Button
									className="p-0"
									type="link"
									onClick={() => {
										Chat.send(
											{
												...strategy,
												default_guest: default_guest,
												open: true,
											},
											"accupancy_popup"
										);
									}}
								>
									{t("Edit base occupancy")}
								</Button>
							</div>
							<div className="second-col">
								<h5 className="font-weight-normal p-4 py-3 m-0">
									{t("Normal price")}
								</h5>
							</div>
						</div>
					) : (
						<div
							key={key}
							className="d-flex justify-content-between"
						>
							<div className="first-col">
								<h5
									className={`font-weight-normal text-nowrap m-0 p-4 py-3 ${isActive}`}
								>
									{key + 1} {t("guest")}
								</h5>
							</div>
							<div className="second-col">
								<div className={`p-4 py-3 ${isActive}`}>
									<h5 className="font-weight-normal m-0">
										{default_guest > key + 1
											? `${t("reduced")}`
											: `${t("increased")}`}{" "}
										{t("by")}
									</h5>
									<Input
										type="number"
										onChange={(e) => onChange(e, key)}
										defaultValue={priceData[key].value}
										key={String(priceData[key].active)}
										addonAfter={
											<Select
												defaultValue={
													priceData[key].add
														? "UZS"
														: "%"
												}
												className="select-after"
												onChange={(e) =>
													handleChange(e, key)
												}
											>
												<Select.Option value="%">
													%
												</Select.Option>
												<Select.Option value="UZS">
													UZS
												</Select.Option>
											</Select>
										}
									/>
								</div>
								<div className="p-4 py-3">
									<Switch
										className="mw-auto"
										key={priceData[key].active}
										checkedChildren="On"
										unCheckedChildren="Off"
										onChange={(e) => checkOnChange(e, key)}
										defaultChecked={priceData[key].active}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
	return priceData.length > 0 ? (
		<>
			{itemList}
			<div className="buttons p-4">
				<Button type="primary" loading={loading} onClick={onFinish}>
					{t("Save")}
				</Button>
				<Button type="ghost" onClick={onCancel}>
					{t("Cancel")}
				</Button>
			</div>
			<EditOccupancyModal />
		</>
	) : null;
}
