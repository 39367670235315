import mainApiInstence from "api/mainApiInstence";
import NotFound from "components/pages/404";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Calendar from "views/app-views/property/calendar";
import FacilitiesService from "views/app-views/property/facilities-services";
import NearbyPlaces from "views/app-views/property/nearby-places";
import UploadPhotos from "views/app-views/property/photos";
import RoomAmenities from "views/app-views/property/room-aminities";
import RoomDetails from "views/app-views/property/room-details";
import RatePlanes from "views/app-views/rates/rate-planes";
import LegalData from "./LegalData";
import Main from "./Main";

export default function PropertyMenuLayout({ match, setProperty }) {
	mainApiInstence.defaults.headers.common.property = match.params.id;
	return (
		<Switch>
			<Route path={match.url + "/main"}>
				<Main id={match.params.id} setProperties={setProperty} />
			</Route>
			<Route path={match.url + "/calendar"} component={Calendar} />
			<Route path={match.url + "/rates"} component={RatePlanes} />
			<Route path={match.url + "/photos"} component={UploadPhotos} />
			<Route
				path={match.url + "/facilities"}
				component={FacilitiesService}
			/>
			<Route path={match.url + "/rooms"} component={RoomDetails} />
			<Route
				path={match.url + "/room-amenities"}
				component={RoomAmenities}
			/>
			<Route
				path={match.url + "/nearby-places"}
				component={NearbyPlaces}
			/>
			<Route path={match.url + "/legal-data"} component={LegalData} />
			<Route exact path="/404" component={NotFound} />
		</Switch>
	);
}
