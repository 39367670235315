import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
	Button,
	Input,
	InputNumber,
	Modal,
	Radio,
	RadioChangeEvent
} from "antd";
import { CalendarApi } from "api/CalendarApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "redux/calendar/calendar.actions";
import { calendarSelector } from "redux/calendar/calendar.selector";
import Chat from "services/Chat";
import { mergeUpdatedDate } from "utils/array";

const initialData = {
	open: false,
	data: {
		active: false,
		count: 0,
		currentdate: "",
		day: 0,
		dayInfo: { price: 0, active: false, rate_id: 0 },
		min_price: 0,
		net_booked: 0,
		room: {
			id: 0,
			name: "",
			rates: [
				{
					id: 0,
					name: "",
					default_guest: 0,
				},
			],
		},
		room_id: 0,
		open: false,
	},
};
export function AviablityPopup() {
	const [initData, setInitData] = useState(initialData);
	const [value, setValue] = useState(Boolean);
	const calendar = useSelector(calendarSelector);
	const [isDisabled, setIsDisabled] = useState(true);
	const dispatch = useDispatch();
	const [modal, setModal] = useState(initialData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		Chat.inbox((info) => {
			setValue(info.active);
			if (info.open) {
				setModal({
					open: info.open,
					data: { ...info },
				});
				setInitData({
					open: info.open,
					data: { ...info },
				});
			}
		}, "aviability_popup");
	}, []);

	const onChangeCount = (e: React.FormEvent<HTMLInputElement>) => {
		const value = Number(e.currentTarget.value);
		if (value !== modal.data.count) {
			setIsDisabled(false);
			setModal((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
					count: value,
				},
			}));
		}
	};
	const onChangePrice = (value: number | null) => {
		if (value !== modal.data.dayInfo.price) {
			setIsDisabled(false);
		}
		setModal((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				dayInfo: {
					...prevState.data.dayInfo,
					price: value ? value : prevState.data.dayInfo.price,
				},
			},
		}));
	};

	const onChange = (e: RadioChangeEvent) => {
		setValue(e.target.value);
		setIsDisabled(false);
	};
	const onFinish = () => {
		let newResponse: any[] = [];
		if (
			initData.data.active !== value &&
			initData.data.dayInfo.price !== modal.data.dayInfo.price &&
			initData.data.count !== modal.data.count
		) {
			newResponse = [
				{
					field_name: "price",
					field_value: modal.data.dayInfo.price,
					rate_id: modal.data.dayInfo.rate_id,
				},
				{
					field_name: "rooms_to_sell",
					field_value: modal.data.count,
					rate_id: modal.data.dayInfo.rate_id,
				},
				{
					field_name: "status_day",
					field_value: value,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (
			initData.data.dayInfo.price !== modal.data.dayInfo.price &&
			initData.data.active !== value
		) {
			newResponse = [
				{
					field_name: "price",
					field_value: modal.data.dayInfo.price,
					rate_id: modal.data.dayInfo.rate_id,
				},
				{
					field_name: "status_day",
					field_value: value,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (
			initData.data.count !== modal.data.count &&
			initData.data.active !== value
		) {
			newResponse = [
				{
					field_name: "rooms_to_sell",
					field_value: modal.data.count,
					rate_id: modal.data.dayInfo.rate_id,
				},
				{
					field_name: "status_day",
					field_value: value,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (
			initData.data.count !== modal.data.count &&
			initData.data.dayInfo.price !== modal.data.dayInfo.price
		) {
			newResponse = [
				{
					field_name: "rooms_to_sell",
					field_value: modal.data.count,
					rate_id: modal.data.dayInfo.rate_id,
				},
				{
					field_name: "price",
					field_value: modal.data.dayInfo.price,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (initData.data.dayInfo.price !== modal.data.dayInfo.price) {
			newResponse = [
				{
					field_name: "price",
					field_value: modal.data.dayInfo.price,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (initData.data.count !== modal.data.count) {
			newResponse = [
				{
					field_name: "rooms_to_sell",
					field_value: modal.data.count,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		} else if (initData.data.active !== value) {
			newResponse = [
				{
					field_name: "status_day",
					field_value: value,
					rate_id: modal.data.dayInfo.rate_id,
				},
			];
		}
		if (newResponse.length) {
			setLoading(true);
			CalendarApi.update({
				updates: newResponse,
				room_id: modal.data.room.id,
				from_date: modal.data.currentdate,
				until_date: modal.data.currentdate,
			})
				.then((res) => {
					const calendar_data = mergeUpdatedDate(calendar.data, res);
					dispatch(CalendarActions.updateData(calendar_data));
					setLoading(false);
					setModal(initialData);
					setIsDisabled(true);
				})
				.catch((err) => {
					setLoading(false);
					console.log(err);
				});
		}
	};

	function onCancel() {
		setModal(initialData);
	}

	const rate = modal.data.room.rates.find(
		(item) => item.id === modal.data.dayInfo.rate_id
	);

	const date = new Date(modal.data.currentdate);
	const monthYear = date.toLocaleString("en-US", {
		day: "numeric",
		month: "short",
		year: "numeric",
	});

	if (!modal.open) {
		return <></>;
	}
	const roomsToSell = !modal.data.count ? 0 : modal.data.count;
	const sellPrice = !modal.data.dayInfo.price ? 0 : modal.data.dayInfo.price;
	return (
		<Modal
			className={`aviability-popup`}
			title={modal.data.room.name}
			open={modal.open}
			onCancel={onCancel}
			destroyOnClose
			footer={
				<div className="d-flex justify-content-between">
					<Button
						type="primary"
						onClick={onFinish}
						disabled={isDisabled}
						loading={loading}
					>
						{t("Save")}
					</Button>
					<Button onClick={onCancel} type="ghost">
						{"Cancel"}
					</Button>
				</div>
			}
		>
			<h6>{monthYear}</h6>
			<Button type="link" className="bulk-edit">
				{t("Bulk edit")}
			</Button>
			<div className="open-close">
				<Radio.Group onChange={onChange} value={value}>
					<Radio value={true}>{t("Open")}</Radio>
					<Radio value={false}>{t("Close")}</Radio>
				</Radio.Group>
			</div>
			<div className="warning">
				<h5 className="mb-0">{t("To make this room bookable:")}</h5>
				{!modal.data.dayInfo.price && (
					<h5 className="danger">
						<InfoCircleOutlined />{" "}
						{t("Add price to a rate plan without prices")}
					</h5>
				)}

				{!modal.data.count && (
					<h5 className="danger">
						<InfoCircleOutlined /> {t("Add rooms to sell")}
					</h5>
				)}
			</div>
			<div className="rooms-cell">
				<h5 className="mb-0">{t("Rooms to sell")}</h5>
				<Input
					defaultValue={roomsToSell}
					onChange={(e: React.FormEvent<HTMLInputElement>) =>
						onChangeCount(e)
					}
					type="number"
				/>
			</div>
			<div className="standart-rate">
				<h5 className="mb-0">
					{rate?.name} <UserOutlined />× {rate?.default_guest}
				</h5>
				<div className="rooms-to-sell">
					<InputNumber
						addonAfter="UZS"
						onChange={onChangePrice}
						defaultValue={sellPrice}
					/>
				</div>
			</div>
		</Modal>
	);
}
