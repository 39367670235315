import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { t } from "i18n";
import React from "react";

export function ConcelationModal(props) {
	return (
		<Modal
			title={t("Enter the reason")}
			open={props.open}
			style={{
				top: 20,
			}}
			onCancel={() => props.setOpen(false)}
			footer={null}
		>
			<Form
				form={props.form}
				name="cancel-reservation"
				onFinish={props.handleOk}
			>
				<Form.Item
					name={"reason"}
					label={t("Enter the reason")}
					labelCol={{
						span: 24,
					}}
					rules={[
						{
							required: true,
							message: t("Reason is required"),
						},
						{
							min: 100,
							message: t(
								"Reason must be longer than or equal to 100 characters"
							),
						},
					]}
				>
					<TextArea />
				</Form.Item>

				<Form.Item
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "end",
					}}
				>
					<Button
						key="submit"
						type="primary"
						loading={props.confirmLoading}
						htmlType="submit"
					>
						{t("Submit")}
					</Button>
				</Form.Item>
			</Form>
		</Modal>
	);
}
