import { ArrowDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Map, YMaps } from "react-yandex-maps";
import Chat from "services/Chat";
import "./map.scss";
import { getAddressByCoordinate } from "./mapApi";
export default function YandexMap({
	value,
	onChange,
}: {
	value: number[];
	onChange: Function;
}) {
	const [address, setAddress] = useState<any>();
	function onBoundsChange(e: any) {
		const newCenter = e.originalEvent.newCenter;
		getAddressByCoordinate(newCenter[0], newCenter[1])
			.then((data) => {
				if (data.countryCode === "uz" || "UZ") {
					setAddress(data);
				}
			})
			.catch((e) => console.log(e));
	}
	function saveAddress() {
		onChange(address);
		Chat.send({ open: false }, "map");
	}
	useEffect(() => {
		if (value) {
			getAddressByCoordinate(value[0], value[1])
				.then((data) => {
					if (data.countryCode === "uz" || "UZ") {
						setAddress(data);
					}
				})
				.catch((e) => console.log(e));
		}
	}, [value, onChange]);

	return (
		<YMaps>
			<Map
				className="map"
				defaultState={{
					center: value,
					zoom: 16,
					controls: ["zoomControl", "fullscreenControl"],
				}}
				onBoundsChange={(e: any) => onBoundsChange(e)}
				modules={["control.ZoomControl", "control.FullscreenControl"]}
			>
				{/* {value && <Placemark defaultGeometry={value} />} */}
				<div className="location-wrapper">
					<ArrowDownOutlined />
				</div>
				<h3 className="address">{address?.name}</h3>
				<Button type="primary" onClick={saveAddress}>
					{t("Save")}
				</Button>
			</Map>
		</YMaps>
	);
}
