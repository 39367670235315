import { Button, Card, Col, message, Row, Table, Tooltip } from "antd";
import RateApi from "api/RateApi";
import { ResourceApi } from "api/ResourceApi";
import { MealsTypes } from "constants/MealsType";
import { PolicyTypes } from "constants/policyTypes";
import { PriceType } from "constants/PriceTypes";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import TraslateService from "services/TranslateService";

const columns = [
	{
		title: `${t("Rate plan name")}`,
		dataIndex: "rate_name",
		key: "rate_name",
	},
	{
		title: `${t("Cancellation policy")}`,
		dataIndex: "cancellation_policy",
		key: "cancellation_policy",
	},
	{
		title: `${t("Price")}`,
		dataIndex: "price",
		key: "price",
	},
	{
		title: `${t("Cancellation rate")}`,
		dataIndex: "cancellation_rate",
		key: "cancellation_rate",
	},
	{
		title: `${t("Net revenue")}`,
		dataIndex: "net_revenue",
		key: "net_revenue",
	},
];

const RatePlanes = () => {
	const [rates, setRates] = useState([]);
	const [loading, setloading] = useState(false);
	const history = useHistory();

	function DeletePlane(id) {
		RateApi.delete(id)
			.then((data) => {
				if (data.status) {
					setRates((rates) => rates.filter((rate) => rate.id !== id));
					message.success("Deleted successfuly!");
				}
			})
			.catch((err) => {
				console.log(err);
				message.error(t(err.response.data.message));
			});
	}
	async function fetch() {
		setloading(true);
		await ResourceApi.getList("property/rates", {
			page: 1,
			limit: 100,
		})
			.then((data) => {
				setRates(data.items);
				setloading(false);
			})
			.catch((err) => {
				console.log(err);
				setloading(false);

				message.error("error");
			});
	}

	useEffect(() => {
		fetch();
	}, []);

	function PriceCalc(item) {
		if (item.based_on) {
			if (item.based_on_value > 0 && item?.based_on) {
				if (item.by_add) {
					return (
						item.based_on_value.toString() +
						"UZS " +
						PriceType[2] +
						" " +
						item.based_on.name
					);
				} else {
					return (
						item.based_on_value.toString() +
						"% " +
						PriceType[2] +
						" " +
						item.based_on.name
					);
				}
			} else {
				if (item.by_add) {
					return (
						(item.based_on_value * -1).toString() +
						"UZS " +
						PriceType[1] +
						" " +
						item.based_on.name
					);
				} else {
					return (
						(item.based_on_value * -1).toString() +
						"% " +
						PriceType[1] +
						" " +
						item.based_on.name
					);
				}
			}
		} else {
			return PriceType[0];
		}
	}

	return (
		<div>
			<h4 className="mt-4">{t("Rate plans")}</h4>
			<p>
				{t("Create and review")}{" "}
				<Link to={"/app/calendar"}>{t("Calendar")}</Link>
			</p>

			<Card>
				<Button
					to={"/app/create-rate"}
					type={"primary"}
					onClick={() => {
						history.push("/app/create-rate");
					}}
					style={{ borderRadius: "2px" }}
				>
					{t("Add new rate plan")}
				</Button>
			</Card>
			<Card>
				<Table
					columns={columns}
					expandable={{
						expandedRowRender: (record) => (
							<p
								style={{
									margin: 0,
								}}
							>
								{record.description}
							</p>
						),
						rowExpandable: (record) =>
							record.name !== "Not Expandable",
					}}
					dataSource={rates.map((item) => {
						console.log(item);
						return {
							key: item.id,
							rate_name: item.name,
							cancellation_policy:
								PolicyTypes[item.rate_policy.free_cancel],
							price: PriceCalc(item),
							cancellation_rate: "Not enough data",
							net_revenue: "Not enough data",
							description: (
								<>
									<Row justify="start">
										<Col span={8}>
											<h5>{t("Room types")}</h5>
										</Col>
										<Col span={5}>
											<h5>{t("Meal plan")}</h5>
										</Col>
										<Col span={6}>
											<h5>{t("Minimum stay")}</h5>
										</Col>
										<Col span={5}>
											<h5>{t("Bookable")}</h5>
										</Col>
									</Row>
									<Row justify="start">
										<Col span={8}>
											{item.rooms.map((item, index) => {
												return (
													<p key={index}>
														{TraslateService(
															item.room_type.name,
															item.room_type
																.name_translations
														)}
													</p>
												);
											})}
										</Col>
										<Col span={5}>
											{item.meals.length > 0
												? item.meals.map(
														(item, index) => {
															return (
																<p key={index}>
																	{
																		MealsTypes[
																			item
																		]
																	}
																</p>
															);
														}
												  )
												: t("No meals")}
										</Col>
										<Col span={6}>
											{item.min_stay > 0
												? item.min_stay +
												  t(" night minimum stay")
												: t(
														"No minimum length of stay"
												  )}
										</Col>
										<Col span={5}>{t("Any time")}</Col>
									</Row>

									<div className="d-flex justify-content-end">
										<Button
											type="primary"
											ghost
											style={{
												marginRight: 5,
											}}
											onClick={() => {
												history.push(
													`/app/edit-rate/${item.id}`
												);
											}}
										>
											{t("Edit")}
										</Button>
										<Tooltip
											placement="top"
											title={t("delete")}
										>
											<Button
												type="primary"
												danger
												onClick={() =>
													DeletePlane(item.id)
												}
											>
												{t("Delete")}
											</Button>
										</Tooltip>
									</div>
								</>
							),
						};
					})}
					loading={loading}
				/>
			</Card>
		</div>
	);
};

export default RatePlanes;
