import { Drawer } from "antd";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import Chat from "services/Chat";
import { FilterViewNew } from "../../../../components/FilterView";

export function FilterDrawer({ filter, onSubmit, fields }) {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		Chat.inbox((open) => {
			setOpen(open);
		}, "filter_drawer");
	}, []);
	return (
		<Drawer
			title={t("filter")}
			open={open}
			onClose={() => setOpen(false)}
			destroyOnClose
			width={600}
		>
			<FilterViewNew
				filter={filter}
				onSubmit={(f) => {
					Chat.send(false, "filter_drawer");
					onSubmit(f);
				}}
				fields={fields}
			/>
		</Drawer>
	);
}
