import axios from "axios";
import { getTOKEN } from "redux/reducers/Auth";
import { signOutDirectly } from "redux/store";

export const mainUrl = () =>
	process.env.REACT_APP_API || "https://api.bookhotel.uz";

const mainApiInstence = axios.create({
	baseURL: mainUrl() + "/api/v1",
	headers: {
		Accept: "application/json",
	},
});
// mainApiInstence.interceptors.
mainApiInstence.interceptors.request.use(function (config) {
	// Do something before request is sent

	config.headers ??= {};

	config.headers.Authorization = "Bearer " + getTOKEN();

	return config;
});

mainApiInstence.interceptors.response.use(
	(config) => config,
	function (err) {
		// Do something with request error
		if (err.message === "Network error") {
			err.message =
				"Internet bilan bog'liq muammo. Internetni tekshiring yoki dasturchiga bog'laning";
		} else if (err.response.status === 401 || err.response.status === 403) {
			signOutDirectly();
		}

		return Promise.reject(err);
	}
);

export default mainApiInstence;
