import { getDataMiddleware } from "api/api.utils";
import QueryString from "qs";
import mainApiInstence from "../mainApiInstence";

export class AminitiesAdminApi {
	static async getList(query: any) {
		return mainApiInstence
			.get(`/amenities?${QueryString.stringify(query)}`)
			.then(getDataMiddleware);
	}
}
