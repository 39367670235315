import { Tag, Tooltip } from "antd";
import { HotelStatus, HotelStatusColors } from "constants/HotelStatus";
import { t } from "i18n";
import React from "react";

export function PropertyStatusComponent({ status }: { status: number }) {
	return (
		<Tooltip
			placement="rightTop"
			title={`${t("Your property status")} ${t(
				(HotelStatus as any)[status]
			)}`}
		>		
			<h1 className="right_top"></h1>
			<Tag color={(HotelStatusColors as any)[status]}>
				{t((HotelStatus as any)[status])}
			</Tag>
		</Tooltip>
	);
}
