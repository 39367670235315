import { Card, Col, Form, Input, Row, Select } from "antd";
import { t } from "i18n";
import React, { useState } from "react";

export default function RegistrationDetails() {
	const [businessType, setBusinessType] = useState(0);

	return (
		<Card>
			<Row gutter={10}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Form.Item
						name={"business_form"}
						label={t("Business registration form")}
						labelCol={{
							span: 24,
						}}
						initialValue={businessType}
						rules={[
							{
								required: true,
								message: t(
									"Please input the form of the bussiness"
								),
							},
						]}
					>
						<Select
							placeholder={t("Business registration form")}
							options={[
								{
									label: t("Legal entity"),
									value: 0,
								},
								{
									label: t("Individual entrepreneur"),
									value: 1,
									disabled: true,
								},
							]}
							showSearch
							onChange={(value) => {
								setBusinessType(value);
							}}
						/>
					</Form.Item>
				</Col>
				{/* {businessType === 0 && (
					<Col xs={24} sm={24} md={12} lg={12}>
						<Form.Item
							name={"business_name"}
							label={t("Business registration form")}
							labelCol={{
								span: 24,
							}}
							rules={[
								{
									required: true,
									message: t(
										"Please input the name of the bussiness form"
									),
								},
							]}
						>
							<Input
								placeholder={t("Business registration form")}
							/>
						</Form.Item>
					</Col>
				)} */}
				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"tin"}
						label={t("STIR")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please input the STIR of the business"
								),
								pattern: new RegExp("^[0-9]*$"),
							},
						]}
					>
						<Input
							style={{ width: "100%" }}
							maxLength={9}
							placeholder={"XXXXXXXXX"}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={12} lg={12}>
					<Form.Item
						name={"okonx"}
						label={t("IFUT code")}
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: t(
									"Please input the IFUT code of the business"
								),
								pattern: new RegExp("^[0-9]*$"),
							},
						]}
					>
						<Input
							style={{ width: "100%" }}
							maxLength={5}
							placeholder={"XXXXX"}
						/>
					</Form.Item>
				</Col>
			</Row>
		</Card>
	);
}
