import { getDataMiddleware } from "api/api.utils";
import mainApiInstence from "./mainApiInstence";

export class RoomTypesApi {
	static async getById(id: any) {
		return mainApiInstence
			.get(`/rooms/room-types/${id}`)
			.then(getDataMiddleware);
	}
}
