import { getDataMiddleware } from "api/api.utils";
import QueryString from "qs";
import mainApiInstence from "../mainApiInstence";

export class PaymenAdminApi {
	static async getList(query: any) {
		return mainApiInstence
			.get(`/payment-methods?${QueryString.stringify(query)}`)
			.then(getDataMiddleware);
	}
}
