import { Checkbox, Col, Radio, Row } from "antd";
import { t } from "i18n";
import React, { useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export default function AmenitieItems({
	amenities,
	setAmenities,
	amenity,
	rooms,
}) {
	const type =
		rooms.length === amenity.prop.rooms.length
			? "all"
			: amenity.prop.rooms.length === 0
			? "none"
			: "some";

	const [radioType, setRadioType] = useState(type);

	function changeRooms(value, values) {
		const a = amenities.find((p) => p.id === amenity.id);

		if (values) {
			a.prop.rooms = values;
		}

		if (value) {
			if (value === "none") {
				a.prop.rooms = [];
			} else if (value === "all") {
				a.prop.rooms = rooms.map((r) => r.id);
			}
		}

		const changedData = amenities.map((p) => {
			if (p.id === a.id) {
				return (p = a);
			} else return p;
		});

		setAmenities(changedData);
		setRadioType(value ? value : "some");
	}

	return (
		<div className="my-4">
			<Row style={{ width: "100%", alignItems: "center" }}>
				<Col span={24} sm={24} md={12} lg={10}>
					<h5>{getNameTranslation(amenity)}</h5>
				</Col>
				<Col span={24} sm={24} md={12} lg={12}>
					<Radio.Group
						className="w-100"
						defaultValue={radioType}
						buttonStyle="solid"
						onChange={(e) => changeRooms(e.target.value)}
						size="middle"
					>
						<Radio.Button value="all">
							{t("All rooms")}
						</Radio.Button>
						<Radio.Button value="some">
							{t("Some rooms")}
						</Radio.Button>
						<Radio.Button value="none">{t("None")}</Radio.Button>
					</Radio.Group>

					{radioType === "some" ? (
						<div className="block">
							<h5 className="mt-2">
								{t("Select where this amenity is available.")}
							</h5>

							<Checkbox.Group
								onChange={(values) =>
									changeRooms(false, values)
								}
								style={{ width: "100%" }}
								defaultValue={amenity.prop.rooms}
							>
								<Row>
									{rooms.map((item) => {
										return (
											<Col span={24} key={item.id}>
												<Checkbox value={item.id}>
													{getNameTranslation(
														item.room_type
													)}
												</Checkbox>
											</Col>
										);
									})}
								</Row>
							</Checkbox.Group>
						</div>
					) : null}
				</Col>
			</Row>
		</div>
	);
}
