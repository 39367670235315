import { FrownOutlined, SmileOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Row } from "antd";
import { ResourceApi } from "api/ResourceApi";
import dayjs from "dayjs";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export function DashboardReviews() {
	const [reviews, setReviews] = useState();
	useEffect(() => {
		ResourceApi.getList("property/reviews", {
			limit: 5,
			page: 1,
			order: "ASC",
		}).then((data) => {
			setReviews(data.items);
		});
	}, []);
	return (
		<Card className="mt-4">
			<div className="d-flex align-items-center justify-content-between">
				<h4 className="m-0">{t("Recent reviews")}</h4>
				<Link to="/app/reviews">{t("All reviews")}</Link>
			</div>
			{reviews?.length > 0 ? (
				reviews?.map((item, index) => {
					return (
						<Card bordered key={index} style={{ padding: 0 }}>
							<Row
								style={{
									justifyContent: "space-between",
									alignItems: "start",
								}}
							>
								<Col span={24} sm={4} md={4} lg={4}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											marginBottom: 5,
										}}
									>
										<span
											style={{
												padding: "4px 10px",
												borderRadius: 3,
												background:
													"linear-gradient(90.29deg, #2378DF 36.51%, #50A5FA 100%, #3F95F0 100%)",
												color: "#fff",
												fontSize: 15,
												fontWeight: "600",
												marginRight: "8px",
											}}
										>
											{item?.mark}
										</span>
										<p className="m-0">
											{item.customer.first_name +
												" " +
												item.customer.last_name}
										</p>
									</div>
								</Col>
								<Col span={24} sm={17} md={17} lg={17}>
									<h5 className="m-0">
										<SmileOutlined /> {t("Good comment:")}{" "}
									</h5>
									<p className="m-0">{item.good_comment}</p>
									<h5 className="m-0">
										<FrownOutlined /> {t("Bad comment:")}{" "}
									</h5>
									<p className="m-0">{item.bad_comment}</p>
								</Col>
								<Col span={24} sm={3} md={3} lg={3}>
									<span
										style={{
											display: "flex",
											justifyContent: "end",
										}}
									>
										{dayjs(item.createdAt).format(
											"DD-MM-YYYY, HH:M"
										)}
									</span>
								</Col>
							</Row>
						</Card>
					);
				})
			) : (
				<Empty />
			)}
		</Card>
	);
}
