import { getDataMiddleware } from "./api.utils";
import mainApiInstence from "./mainApiInstence";

export class PropertyApi {
	static create(data: any) {
		return mainApiInstence.post("/property/registration", data);
	}
	// Property List
	static async getPrimaryProperty() {
		return mainApiInstence.get("/property").then(getDataMiddleware);
	}
	static async putProperty(data: any) {
		return mainApiInstence.put("/property", data);
	}
	static async getPrimaryPropertyPolicy() {
		return mainApiInstence.get("/property").then((r) => r.data.policy);
	}
	static async putPrimaryPropertyPolicy(data: any) {
		return mainApiInstence.put("/property/policy", data);
	}
	static getPropertyList() {
		return mainApiInstence.get("/property/user/properties");
	}
	static patchProperty(data: any) {
		return mainApiInstence.patch("/property/user", data);
	}

	//PLACES
	static getPlaces() {
		return mainApiInstence.get("/property/nearby-places");
	}
	static updatePlaces(data: any) {
		return mainApiInstence.patch("/property/nearby-places", data);
	}
	static deletePlace(id: number) {
		return mainApiInstence.delete(`/property/nearby-places/${id}`);
	}
	// AMENITIES
	static update(data: any) {
		return mainApiInstence.patch("/property/amenities", data);
	}

	// FACILITIES
	static updateFacility(data: any) {
		return mainApiInstence
			.patch("/property/facilities", data)
			.then(getDataMiddleware);
	}

	// PHOTOUPLOAD
	static async updatePhotos(data: any, id: any) {
		return mainApiInstence
			.put(`/property/photos/${id}`, data)
			.then(getDataMiddleware);
	}
	static async updatePhtosSort(data: any) {
		return mainApiInstence
			.put(`/property/photos/sort`, data)
			.then(getDataMiddleware);
	}
	// PHOTO
	static deletePhoto(id: number) {
		return mainApiInstence.delete(`/property/photos/${id}`);
	}

	// CANCEL RESERVATION
	static cancelReservation(id: number, reason: string) {
		return mainApiInstence.patch(
			`/property/reservations/${id}/cencel?reason=${reason}`
		);
	}

	// Marketing
	static updateMarketing(data: { percentage: number }) {
		return mainApiInstence.put(`/property/marketing`, data);
	}
}
