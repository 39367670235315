import { Button, Card, Form } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import FormList from "./FormList";

export default function NearbyPlaces() {
	const [nearbyPlaces, setNearbyPlaces] = useState();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	function onFinish(values) {
		setLoading(true);
		const data = { places: [] };

		for (let key of Object.keys(values)) {
			let places = values[key];
			if (places) {
				places = places.map((p) => {
					const place = {
						name: p.name,
						distance: parseInt(p.distance),
						nearby_place_id: parseInt(key),
					};
					if (p.id) place.id = p.id;
					return place;
				});
				data.places.push(...places);
			}
		}

		PropertyApi.updatePlaces(data)
			.then(() => {
				MessagesService.success();
				setLoading(false);
			})
			.catch((e) => {
				MessagesService.error();
				setLoading(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	useEffect(() => {
		ResourceApi.getList("nearby-places").then((nearbies) => {
			PropertyApi.getPlaces().then(({ data }) => {
				const newData = nearbies.items.map((n) => {
					if (n) {
						n.fields = data.places.filter(
							(p) => p && p.nearby_place_id === n.id
						);
						return n;
					}
					return n;
				});
				setNearbyPlaces(newData);
			});
		});
	}, []);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			initialValues={nearbyPlaces}
		>
			<h3>{t("What's nearby?")}</h3>
			{!nearbyPlaces ? (
				<Loading />
			) : (
				<>
					{nearbyPlaces.map((place, i) => {
						return (
							<Card key={i}>
								<FormList
									mainIndex={i}
									form={form}
									place={place}
									id={place.id}
									formFields={place.fields}
								/>
							</Card>
						);
					})}
					<Button
						loading={loading}
						type="primary"
						htmlType="submit"
						className="mb-5"
					>
						{t("Save")}
					</Button>
				</>
			)}
		</Form>
	);
}
