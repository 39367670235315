import mainApiInstence from "./mainApiInstence";

export default class RateApi {
	static async create(rate: any) {
		return mainApiInstence.post("/property/rates", rate);
	}

	static async update(rate: any, id: number) {
		return mainApiInstence.put(`/property/rates/${id}`, rate);
	}

	static async delete(id: any) {
		return mainApiInstence.delete(`/property/rates/${id}`);
	}
}
