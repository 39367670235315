import { Card, Col, message, Row } from "antd";
import { CustomersApi } from "api/Customers";
import BackButton from "components/shared-components/BackButton";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function CustomersItem() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const { id } = useParams();
	useEffect(() => {
		setLoading(true);
		CustomersApi.getCustomer(id)
			.then(({ data }) => {
				setData(data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				message.error("error");
			});
	}, []);
	if (loading) {
		return <Loading />;
	}
	return (
		<div>
			<Col span={24} sm={24} md={16} lg={16}>
				<Row
					style={{
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: 15,
					}}
				>
					<div className="d-flex">
						<BackButton />
						<h2 className="m-0">{t("Customer infos")}</h2>
					</div>
				</Row>
			</Col>

			<Col span={24} sm={24} md={16} lg={16}>
				<Card>
					<Row>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Guest name:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									<i>
										{data?.first_name +
											" " +
											data?.last_name}
									</i>
								</h4>
							</div>
						</Col>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Birthday:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									<i>
										{data?.birth_date
											? data.birth_date
											: "-"}
									</i>
								</h4>
							</div>
						</Col>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Nationality:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									<i>
										{data?.nationality
											? data.nationality
											: "-"}
									</i>
								</h4>
							</div>
						</Col>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Gender:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									<i>{data?.gender ? data.gender : "-"}</i>
								</h4>
							</div>
						</Col>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Phone number:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									{data?.phone_number ? (
										<a
											href="tet:994551260"
											target={"_blank"}
											rel="noreferrer"
										>
											<i>{data.phone_number}</i>
										</a>
									) : (
										"-"
									)}
								</h4>
							</div>
						</Col>
						<Col span={24} sm={24} md={12} lg={8}>
							<div>
								<p className="m-0">
									<i>{t("Email:")}</i>
								</p>
								<h4 className="mt-1 mb-3 d-flex align-items-center">
									{data?.email ? (
										<a
											rel="noreferrer"
											href={`mailto:${data?.email}`}
											target={"_blank"}
										>
											<i>{data.email}</i>
										</a>
									) : (
										"-"
									)}
								</h4>
							</div>
						</Col>
					</Row>
				</Card>
			</Col>
		</div>
	);
}
