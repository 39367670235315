import { Button, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import dayjs from "dayjs";
import { t } from "i18n";
import React from "react";
import { Link } from "react-router-dom";

export function TableComponent(props) {
	return (
		<Table
			rowKey={"id"}
			dataSource={props.data}
			loading={props.loading}
			scroll={{
				x: 1500,
			}}
		>
			<Column
				title={t("Legal name")}
				dataIndex="name"
				key="name"
				render={(_, alldata) => {
					return (
						<Link to={`legal-customers/${alldata.id}`}>
							{alldata?.name}
						</Link>
					);
				}}
				fixed="left"
			/>
			<Column
				title={t("Customer name")}
				dataIndex="customer_name"
				key="customer_name"
				render={(_, alldata) => {
					return (
						alldata.customer.first_name +
						" " +
						alldata.customer.last_name
					);
				}}
			/>
			<Column
				title={t("Phone number")}
				dataIndex="phone_number"
				key="phone_number"
				render={(_, alldata) => {
					return alldata.customer.phone_number;
				}}
			/>
			<Column title={t("TIN")} dataIndex="tin" key="tin" />
			<Column title={t("MFI")} dataIndex="mfi" key="mfi" />
			<Column
				title={t("Account")}
				dataIndex="account"
				key="account"
				width={200}
			/>
			<Column
				title={t("Created")}
				dataIndex="createdAt"
				key="createdAt"
				render={(date) => {
					return dayjs(date).format("MMM DD, YYYY");
				}}
			/>
			<Column
				title={t("Status")}
				dataIndex="status"
				key="status"
				render={(status) => {
					return (
						<>
							{status === 0 ? (
								<Tag color="blue">{t("Pending")}</Tag>
							) : (
								<Tag color="green">{t("Active")}</Tag>
							)}
						</>
					);
				}}
			/>
			<Column
				title={t("Change status")}
				dataIndex="change_status"
				key="change_status"
				fixed="right"
				render={(_, data) => {
					return (
						<Button
							type="primary"
							size="small"
							onClick={() => {
								props.setFieldsValue(data);
								props.setOpen(true);
							}}
						>
							{t("Change status")}
						</Button>
					);
				}}
			/>
		</Table>
	);
}
