import { Modal } from "antd";
import React from "react";

export default function ResourseModal(props) {
	return (
		<Modal
			title={props.title}
			open={props.isModalOpen}
			onCancel={props.handleCancel}
			footer={false}
			destroyOnClose
			width={props.size}
		>
			{props.isModalOpen && props.content}
		</Modal>
	);
}
