import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import { t } from "i18n";
import React, { useState } from "react";
import { CreateRoomForm } from "./CreateRoomForm";

function EmptyRoom(props) {
	return (
		<div className="container mt-5">
			<Card
				className="text-center"
				style={
					props.rooms.length === 0 ? { border: "1px solid red" } : ""
				}
			>
				<img
					src="https://q.bstatic.com/static/img/join/segmentation/empty-state-createroom@2x.png"
					alt="add room"
					style={{
						width: "200px",
					}}
					className="mb-3"
				/>
				<p>{t("layout and pricing for guests")}</p>
				<Button
					icon={<PlusOutlined />}
					type="primary"
					onClick={() => props.changeModeRoomAdding(true)}
				>
					{t("Add room")}
				</Button>
			</Card>
		</div>
	);
}

function RoomItem({ room, setRooms, rooms }) {
	return (
		<Card>
			<Row
				style={{
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Col span={10}>{room.custom_name}</Col>
				<Col span={4}>
					<Row>
						<Col
							span={24}
							style={{ display: "flex", justifyContent: "end" }}
						>
							<Button
								danger
								onClick={() => {
									setRooms(
										rooms.filter(
											(item) => item.id !== room.id
										)
									);
								}}
							>
								{t("Delete")}{" "}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</Card>
	);
}

export default function LayoutInfo({ form, setRooms, rooms }) {
	const [addingRoom, changeModeRoomAdding] = useState(false);

	if (!addingRoom && rooms.length === 0) {
		return (
			<EmptyRoom
				changeModeRoomAdding={changeModeRoomAdding}
				rooms={rooms}
			></EmptyRoom>
		);
	}

	if (!addingRoom) {
		return (
			<div className="container mt-5">
				<Card>
					<Row justify="space-between">
						{rooms.map((room, index) => {
							return (
								<Col
									xs={24}
									sm={24}
									md={index % 2 === 0 ? 12 : 11}
									lg={index % 2 === 0 ? 12 : 11}
								>
									<RoomItem
										room={room}
										setRooms={setRooms}
										rooms={rooms}
									/>
								</Col>
							);
						})}
					</Row>

					<div>
						<Button
							icon={<PlusOutlined />}
							type="primary"
							onClick={() => changeModeRoomAdding(true)}
						>
							{t("Add room")}
						</Button>
					</div>
				</Card>
			</div>
		);
	}

	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						<h2 className="my-1 mt-2">{t("Layout and pricing")}</h2>
						<p>{t("Start by telling")}</p>
						<CreateRoomForm
							onRoomFinish={(room) => {
								setRooms((rs) => [...rs, room]);
								changeModeRoomAdding(false);
							}}
							form={form}
							rooms={rooms}
						/>
					</Col>
				</Row>
			</div>
		</div>
	);
}
