import { Button, DatePicker, DatePickerProps, Modal, Tabs } from "antd";
import { t } from "i18n";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calendarSelector } from "redux/calendar/calendar.selector";
import Chat from "services/Chat";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { RoomContentSwitcher } from "./BulkEditModalContent";

export default function BulkEditModal({ room }: { room: any }) {
	const calendar = useSelector(calendarSelector);
	const [data, setData] = useState({ open: false, data: "" });
	const [activeBar, setActiveBar] = useState();
	const [dates, setDates] = useState({
		from_date: calendar.date_range[0],
		until_date: calendar.date_range[1],
	});
	useEffect(() => {
		Chat.inbox((data) => {
			setActiveBar(data.id);
			setData({ open: true, data: data });
		}, "room_edit");
	}, []);
	const handleCancel = () => {
		setData({ open: false, data: "" });
	};
	const onChangeOne: DatePickerProps["onChange"] = (date, dateString) => {
		setDates({ ...dates, from_date: dateString });
	};
	const onChangeTwo: DatePickerProps["onChange"] = (date, dateString) => {
		setDates({ ...dates, until_date: dateString });
	};

	return (
		<div>
			<Button
				type="primary"
				onClick={() => {
					Chat.send(room, "room_edit");
					setActiveBar(room.id);
				}}
			>
				{t("Bulk edit")}
			</Button>
			<Modal
				title={t("Bulk edit")}
				className={`bulk-edit-modal`}
				open={data.open}
				onCancel={handleCancel}
				destroyOnClose
			>
				<div className="data-picker">
					<div className="from">
						<h4>{t("From")}:</h4>
						<DatePicker
							size={"large"}
							onChange={onChangeOne}
							defaultValue={moment(dates.from_date)}
						/>
					</div>
					<div className="up-to">
						<h4>{t("Up to and including")}:</h4>
						<DatePicker
							size={"large"}
							onChange={onChangeTwo}
							defaultValue={moment(dates.until_date)}
						/>
					</div>
				</div>

				<div className="tabs">
					<Tabs
						defaultActiveKey={activeBar}
						items={calendar.data.rooms.map((room: any) => ({
							key: room.id,
							tabKey: room.id,
							label: getNameTranslation(room),
							children: (
								<RoomContentSwitcher
									room={room}
									dates={dates}
								/>
							),
						}))}
					/>
				</div>
			</Modal>
		</div>
	);
}
