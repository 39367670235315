import { UserOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	Card,
	Checkbox,
	Col,
	Collapse,
	Form,
	InputNumber,
	Radio,
	Row,
	Select,
} from "antd";
import { PropertyApi } from "api/PropertyApi";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";
import { MessagesService } from "services/Messages";
const { Panel } = Collapse;
const { Option } = Select;
function isEnable(facility) {
	return facility.property_facility && !facility.property_facility.disabled;
}

export default function FacilitiesService() {
	const [facilities, setfacilities] = useState([]);
	const [meals, setMeals] = useState([]);
	const [data, setData] = useState([]);
	const [laguages, setlaguages] = useState([]);
	const [selectLanguages, setSelectLanguages] = useState([]);
	const [breakfastTypes, setBreakfastTypes] = useState([]);
	const [loading, setloading] = useState(false);
	const [buttonLoading, setbuttonLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetch();
	}, []);

	const onFinish = async () => {
		setbuttonLoading(true);
		await PropertyApi.updateFacility({
			facilities: facilities
				.filter((facility) => facility.property_facility)
				.map((f) => f.property_facility),
			meal: { ...meals },
			staff_languages: [...selectLanguages],
		})
			.then((data) => {
				setfacilities(data.facilities);
				MessagesService.success();
			})
			.catch(MessagesService.axiosError);

		setbuttonLoading(false);
	};

	async function fetch() {
		setloading(true);
		await ResourceApi.getList("property/breakfast-types", {
			page: 1,
			limit: 100,
		}).then((data) => {
			setBreakfastTypes(data.items);
		});
		await ResourceApi.getList("property/languages", {
			page: 1,
			limit: 1000,
		}).then((data) => {
			setlaguages(data.items);
		});
		await ResourceApi.getList("property/facilities", {
			page: 1,
			limit: 1000,
		}).then((data) => {
			setfacilities(data.facilities);
			setMeals(data.meal);
			setSelectLanguages(data.staff_languages);
			setData(data);
		});
		setloading(false);
	}

	function onChangePaid(e, item) {
		setfacilities((facilities) => {
			return facilities.map((facility) => {
				if (facility.id === item.id) {
					if (e) {
						return {
							...facility,
							property_facility: {
								...facility.property_facility,
								paid: true,
							},
						};
					} else {
						return {
							...facility,
							property_facility: {
								...facility.property_facility,
								paid: false,
							},
						};
					}
				}
				return facility;
			});
		});
	}
	function onChangeFacility(item, e) {
		return setfacilities((facilities) => {
			return facilities.map((facility) => {
				if (facility.id === item.id) {
					let property_facility = item.property_facility || {};

					if (e.target.value) {
						property_facility.facility_id = item.id;
					} else {
						if (property_facility.id) {
							property_facility.disabled = true;
						} else {
							property_facility = null;
						}
					}
					return {
						...facility,
						property_facility: property_facility,
					};
				}
				return facility;
			});
		});
	}
	function CreateRestaurant(item, values) {
		return setfacilities((facilities) => {
			return facilities.map((facility) => {
				if (facility.id === item.id) {
					return {
						...facility,
						property_facility: values
							? {
									...facility.property_facility,
									data: {
										...values,
									},
							  }
							: null,
					};
				}
				return facility;
			});
		});
	}

	if (loading) {
		return <Loading />;
	}

	return (
		<div>
			<h3>{t("Facilities & services")}</h3>
			<p>
				{t(
					"Listing your facilities can really help influence guests to book! Update the ones available at your property or on-site below."
				)}
			</p>
			<Form form={form} onFinish={onFinish}>
				<Card>
					<h3>{t("Top facilities")}</h3>
					<p>
						{t(
							"These are the facilities that guests look for the most! Tell them which you have by answering"
						)}{" "}
						<b>{t("yes")}</b> {t("or")} <b>{t("no")}</b>{" "}
						{t("to each question and click")} <b>{t("save")}</b>.
					</p>
					<Row
						style={{ justifyContent: "space-between" }}
						gutter={12}
					>
						{facilities.map((item, index) => {
							return (
								<Col
									xs={24}
									sm={12}
									md={12}
									lg={12}
									key={index}
								>
									<div
										style={{
											backgroundColor: "#f2f2f2",
										}}
										className="p-3 mt-2"
									>
										<Row
											justify="between"
											style={{
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<Col xs={12} sm={10} md={10} lg={8}>
												<h5 className="m-0">
													{getNameTranslation(item)}
												</h5>
											</Col>
											<Radio.Group
												onChange={(e) =>
													onChangeFacility(item, e)
												}
												value={Boolean(isEnable(item))}
											>
												<Radio value={true}>
													{t("Yes")}
												</Radio>
												<Radio value={false}>
													{t("No")}
												</Radio>
											</Radio.Group>
										</Row>
									</div>
									{item.pay_option && isEnable(item) ? (
										<Card>
											<>
												<Select
													placeholder={t(
														"Please select"
													)}
													className="mt-2"
													onChange={(e) => {
														onChangePaid(e, item);
													}}
													defaultValue={
														item.property_facility
															.paid
													}
												>
													<Option
														value={false}
														key={1}
													>
														{t("Free")}
													</Option>
													<Option
														value={true}
														key={2}
													>
														{t("Paid")}
													</Option>
												</Select>
											</>
										</Card>
									) : (
										""
									)}
									{/* {item.data_type === "restaurant" &&
									isEnable(item) ? (
										<Collapse
											defaultActiveKey={["1"]}
											expandIconPosition="end"
											className="mt-3"
										>
											<Panel
												header="Restaurant #1"
												key="1"
											>
												<Form
													name="restauran_form"
													onFinish={(values) => {
														return CreateRestaurant(
															item,
															values
														);
													}}
													initialValue={
														item.data_type ===
															"restaurant" &&
														item.property_facility
															? item
																	.property_facility
																	.data
															: ""
													}
												>
													<Form.Item
														name="restaurant_name"
														label={t(
															"Restaurant name"
														)}
														labelCol={{
															span: 24,
														}}
														rules={[
															{
																required: true,
																message: `${t(
																	"Restaurant name is required!"
																)}`,
															},
														]}
													>
														<Input className="p-2" />
													</Form.Item>
													<Form.Item
														name="menu_type"
														label={t("Menu type")}
														labelCol={{
															span: 24,
														}}
													>
														<Row>
															<Checkbox.Group>
																<Col span={24}>
																	<Checkbox
																		value={
																			0
																		}
																	>
																		{t(
																			"Menu"
																		)}
																	</Checkbox>
																</Col>
																<Col span={24}>
																	<Checkbox
																		value={
																			1
																		}
																	>
																		{t(
																			"Shvetskiy stol"
																		)}
																	</Checkbox>
																</Col>
															</Checkbox.Group>
														</Row>
													</Form.Item>
													<Form.Item
														name="restaurant_features"
														label={t(
															"Restaurant features"
														)}
														labelCol={{
															span: 24,
														}}
													>
														<Row>
															<Checkbox.Group>
																<Col span={24}>
																	<Checkbox
																		value={
																			0
																		}
																	>
																		{t(
																			"Open only to guests staying at the property"
																		)}
																	</Checkbox>
																</Col>
																<Col span={24}>
																	<Checkbox
																		value={
																			1
																		}
																	>
																		{t(
																			"Reservations welcomed"
																		)}
																	</Checkbox>
																</Col>
																<Col span={24}>
																	<Checkbox
																		value={
																			2
																		}
																	>
																		{t(
																			"Outdoor seating"
																		)}
																	</Checkbox>
																</Col>
															</Checkbox.Group>
														</Row>
													</Form.Item>
													<Row
														style={{
															justifyContent:
																"space-between",
														}}
													>
														<Col
															xs={24}
															sm={24}
															md={24}
															lg={24}
														>
															<Form.Item
																name="open_for"
																label={t(
																	"Open for"
																)}
																labelCol={{
																	span: 24,
																}}
															>
																<Select
																	mode="multiple"
																	allowClear
																	style={{
																		width: "100%",
																	}}
																	placeholder={t(
																		"Open for"
																	)}
																	maxTagCount="responsive"
																>
																	<Option
																		value={
																			0
																		}
																	>
																		{t(
																			"Breakfast"
																		)}
																	</Option>
																	<Option
																		value={
																			1
																		}
																	>
																		{t(
																			"Brunch"
																		)}
																	</Option>
																	<Option
																		value={
																			2
																		}
																	>
																		{t(
																			"Lunch"
																		)}
																	</Option>
																	<Option
																		value={
																			3
																		}
																	>
																		{t(
																			"Dinner"
																		)}
																	</Option>
																	<Option
																		value={
																			4
																		}
																	>
																		{t(
																			"High tea"
																		)}
																	</Option>
																</Select>
															</Form.Item>
														</Col>
													</Row>
													<Row
														style={{
															justifyContent:
																"space-between",
														}}
													>
														<Col
															xs={24}
															sm={24}
															md={11}
															lg={11}
														>
															<Form.Item
																name="days"
																label={t(
																	"Days"
																)}
																labelCol={{
																	span: 24,
																}}
															>
																<Select
																	mode="multiple"
																	allowClear
																	style={{
																		width: "100%",
																	}}
																	placeholder={t(
																		"Days"
																	)}
																	maxTagCount="responsive"
																	size="medium"
																>
																	<Option
																		value={
																			1
																		}
																	>
																		{t(
																			"Monday"
																		)}
																	</Option>
																	<Option
																		value={
																			2
																		}
																	>
																		{t(
																			"Tuesday"
																		)}
																	</Option>
																	<Option
																		value={
																			3
																		}
																	>
																		{t(
																			"Wednesday"
																		)}
																	</Option>
																	<Option
																		value={
																			4
																		}
																	>
																		{t(
																			"Thursday"
																		)}
																	</Option>
																	<Option
																		value={
																			5
																		}
																	>
																		{t(
																			"Friday"
																		)}
																	</Option>
																	<Option
																		value={
																			6
																		}
																	>
																		{t(
																			"Saturday"
																		)}
																	</Option>
																	<Option
																		value={
																			7
																		}
																	>
																		{t(
																			"Sunday"
																		)}
																	</Option>
																</Select>
															</Form.Item>
														</Col>
														<Col
															xs={11}
															sm={11}
															md={5}
															lg={5}
														>
															<Form.Item
																name="from"
																label={t(
																	"From"
																)}
																labelCol={{
																	span: 24,
																}}
															>
																<Select
																	mode="multiple"
																	allowClear
																	style={{
																		width: "100%",
																	}}
																	placeholder={t(
																		"From"
																	)}
																	maxTagCount="responsive"
																>
																	{hours.map(
																		(
																			item
																		) => {
																			return (
																				<Option
																					value={
																						item
																					}
																					key={
																						item
																					}
																				>
																					{
																						item
																					}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>
														<Col
															xs={11}
															sm={11}
															md={5}
															lg={5}
														>
															<Form.Item
																name="to"
																label="To"
																labelCol={{
																	span: 24,
																}}
															>
																<Select
																	mode="multiple"
																	allowClear
																	style={{
																		width: "100%",
																	}}
																	placeholder="To"
																	maxTagCount="responsive"
																>
																	{hours.map(
																		(
																			item
																		) => {
																			return (
																				<Option
																					value={
																						item
																					}
																					key={
																						item
																					}
																				>
																					{
																						item
																					}
																				</Option>
																			);
																		}
																	)}
																</Select>
															</Form.Item>
														</Col>
													</Row>
													<Button
														type="primary"
														size="large"
														htmlType="submit"
													>
														{t("Create")}
													</Button>
												</Form>
											</Panel>
										</Collapse>
									) : (
										""
									)} */}
								</Col>
							);
						})}
					</Row>
				</Card>

				<Card>
					<h3>{t("Price for meals")}</h3>
					<Alert
						type="warning"
						message={t(
							"Please tell us the price of meals when they are not included in the reservation price. Prices are per person, per day and must include any extra fees or taxes."
						)}
						showIcon
					></Alert>
					<div className="mt-4">
						<Row className="mt-2">
							<Col xs={24} sm={12} md={12} lg={12}>
								<Checkbox
									value="breakfast"
									checked={meals?.breakfast_price}
									className="mt-2"
									onChange={(e) => {
										if (e.target.value === "breakfast") {
											if (e.target.checked) {
												return setMeals({
													...meals,
													breakfast_price: 1,
												});
											}
											return setMeals({
												...meals,
												breakfast_price: null,
											});
										}
									}}
								>
									{t("Breakfast")}
								</Checkbox>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12}>
								<Form.Item
									disabled={!meals?.dinner_price}
									name={"breakfast_price"}
									initialValue={meals?.breakfast_price}
								>
									<InputNumber
										disabled={!meals?.breakfast_price}
										addonBefore="UZS"
										addonAfter={<UserOutlined />}
										size="large"
										onChange={(value) => {
											if (value > 0) {
												setMeals({
													...meals,
													breakfast_price: value,
												});
											} else {
												setMeals({
													...meals,
													breakfast_price: null,
												});
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col xs={24} sm={12} md={12} lg={12}>
								<Checkbox
									value={"lunch"}
									className="mt-2"
									checked={meals?.lunch_price}
									onChange={(e) => {
										if (e.target.value === "lunch") {
											if (e.target.checked) {
												return setMeals({
													...meals,
													lunch_price: 1,
												});
											}
											return setMeals({
												...meals,
												lunch_price: null,
											});
										}
									}}
								>
									{t("Lunch")}
								</Checkbox>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12}>
								<Form.Item
									name={"lunch_price"}
									initialValue={data?.meal?.lunch_price}
								>
									<InputNumber
										disabled={!meals?.lunch_price}
										addonBefore="UZS"
										addonAfter={<UserOutlined />}
										size="large"
										onChange={(value) => {
											if (value > 0) {
												setMeals({
													...meals,
													lunch_price: value,
												});
											} else {
												setMeals({
													...meals,
													lunch_price: null,
												});
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col xs={24} sm={12} md={12} lg={12}>
								<Checkbox
									value={"dinner"}
									className="mt-2"
									checked={meals?.dinner_price}
									onChange={(e) => {
										if (e.target.checked) {
											return setMeals({
												...meals,
												dinner_price: 1,
											});
										}
										return setMeals({
											...meals,
											dinner_price: null,
										});
									}}
								>
									{t("Dinner")}
								</Checkbox>
							</Col>
							<Col xs={24} sm={12} md={12} lg={12}>
								<Form.Item
									name={"dinner_price"}
									initialValue={data?.meal?.dinner_price}
								>
									<InputNumber
										disabled={!meals?.dinner_price}
										addonBefore="UZS"
										addonAfter={<UserOutlined />}
										type="number"
										size="large"
										onChange={(value) => {
											if (value > 0) {
												setMeals({
													...meals,
													dinner_price: value,
												});
											} else {
												setMeals({
													...meals,
													dinner_price: null,
												});
											}
										}}
									/>
								</Form.Item>
							</Col>
						</Row>
					</div>
				</Card>

				{meals?.breakfast_price ? (
					<Card>
						<Form.Item
							name={"breakfast_types"}
							label={t("Breakfast Information")}
							labelCol={{ span: 24 }}
							initialValue={data?.meal?.breakfast_types}
							rules={[
								{
									required: true,
									message: `${t(
										"Breakfast types must contain at least 1 elements"
									)}`,
								},
							]}
						>
							<Select
								mode="multiple"
								allowClear
								style={{
									width: "100%",
								}}
								placeholder="Open for"
								onChange={(value) =>
									setMeals({
										...meals,
										breakfast_types: value,
									})
								}
								maxTagCount="responsive"
								options={breakfastTypes.map((item) => {
									return {
										value: item.id,
										label: item.name,
									};
								})}
							></Select>
						</Form.Item>
					</Card>
				) : (
					" "
				)}

				<Card>
					<h3>{t("Languages spoken")}</h3>
					<Col xs={24} sm={24} md={20} lg={8}>
						<Form.Item
							name="staff_languages"
							label={t("Select staff language your property")}
							className="mt-4"
							labelCol={{
								span: 24,
							}}
							initialValue={data.staff_languages}
							rules={[
								{
									required: true,
									message: `${t(
										"Stadd languages must contain at least 1 elements"
									)}`,
								},
							]}
						>
							<Select
								mode="multiple"
								allowClear
								placeholder={t("Please select")}
								size="middle"
								onChange={(value) => {
									setSelectLanguages(value);
								}}
								options={laguages.map((item) => {
									return {
										value: item.id,
										label: item.name,
									};
								})}
							></Select>
						</Form.Item>
					</Col>
				</Card>

				<div
					style={{
						position: "fixed",
						bottom: 0,
						left: "50%",
						transform: "translate(-50%, 0)",
						width: "100%",
						height: "60px",
						background: "rgba(3,26,58,.8)",
					}}
				>
					<Button
						loading={buttonLoading}
						type="primary"
						htmlType="submit"
						style={{
							left: "50%",
							top: "50%",
							transform: "translate(-50%, -50%)",
						}}
					>
						{t("save")}
					</Button>
				</div>
			</Form>
		</div>
	);
}
