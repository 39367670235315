import QueryString from "qs";
import mainApiInstence from "./mainApiInstence";
export class ReservationsApi {
	//Reservation
	static getReservations(role: string, query: any) {
		return mainApiInstence.get(
			`${role ? "" : "property"}/reservations?${QueryString.stringify(
				query
			)}`
		);
	}
	static getReservation(role: string, id: number) {
		return mainApiInstence.get(
			`${role ? "" : "property"}/reservations/${String(id)}`
		);
	}
}
