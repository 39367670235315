import { Select, Spin } from "antd";
import { ResourceApi } from "api/ResourceApi";
import React, { useEffect, useState } from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

function renderItem(item: any) {
	return getNameTranslation(item) || item.id || "-";
}
function defaulFetchResource(resource: string) {
	return ResourceApi.getList(resource);
}

function defaultFilter(input: any, option: any) {
	return (
		option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	);
}
function defaultFilterItems(i: any) {
	return i;
}

function defaultOptionValueGetter(item: any) {
	return item.id;
}

function defaultOnchage(value: string, item: any) {}

function SelectResource({
	resource = "",
	render = renderItem,
	fetchResource = defaulFetchResource,
	filterOption = defaultFilter,
	filterItems = defaultFilterItems,
	optionValueGetter = defaultOptionValueGetter,
	onChange = defaultOnchage,
	onExtraChange = defaultOnchage,
	initialFetchIfNone = true,
	...props
}) {
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState<any[]>([]);
	useEffect(() => {
		if (items.length === 0) {
			fetchResource(resource)
				.then((r) => {
					setItems(r.items);
					setLoading(false);
				})
				.catch(console.error);
		}
		// eslint-disable-next-line
	}, [items]);

	return (
		<Select
			loading={loading}
			optionFilterProp="children"
			notFoundContent={loading && <Spin size="small" />}
			// onSearch={fetch}
			filterOption={filterOption}
			showSearch
			allowClear
			className="w-100"
			{...props}
			onChange={(value) => {
				const item = items.find(
					(item) => optionValueGetter(item) === value
				);
				onChange(value, item);
				onExtraChange(value, item);
			}}
			options={items.filter(filterItems).map((item, i) => {
				return {
					key: i,
					value: optionValueGetter(item),
					label: render(item),
				};
			})}
		/>
	);
}
export default SelectResource;
