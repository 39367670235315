import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import TraslateService from "services/TranslateService";

export default function RoomItem({ cover, id, data, DeletePlane }) {
	const history = useHistory();
	return (
		<Link to={`/app/room-edit/${data.id}`}>
			<Card
				hoverable
				key={id}
				cover={
					<img
						alt="example"
						src={cover}
						style={{ height: "200px", objectFit: "cover" }}
					/>
				}
			>
				<h4>
					{TraslateService(
						data.room_type.name,
						data.room_type.name_translations
					)}
				</h4>

				<Button
					type="primary"
					ghost
					style={{ marginRight: "10px" }}
					className="w-25"
					onClick={(e) => history.push(`/app/room-edit/${data.id}`)}
					icon={<EditOutlined />}
					size={"small"}
				/>

				<Button
					type="primary"
					danger
					className="w-25"
					icon={<DeleteOutlined />}
					onClick={() => DeletePlane(data.id)}
					size={"small"}
				/>

				{/* <Button type="primary">Upload photos</Button> */}
			</Card>
		</Link>
	);
}
