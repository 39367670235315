import { Button, Collapse, InputNumber, Radio, Select } from "antd";
import { CalendarApi } from "api/CalendarApi";
import { t } from "i18n";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "redux/calendar/calendar.actions";
import { calendarSelector } from "redux/calendar/calendar.selector";
import { mergeUpdatedDate } from "utils/array";

const { Panel } = Collapse;
const { Option } = Select;

export function RoomContentSwitcher(data: any) {
	const calendar = useSelector(calendarSelector);
	const dispatch = useDispatch();
	const [conut, setCount] = useState(0);
	const [price, setPrice] = useState(0);
	const [status, setStatus] = useState<any>();
	const [loadings, setLoadings] = useState<boolean[]>([]);
	const [rate, setRate] = useState<any>();
	const fromDate = new Date(data.dates.from_date)
		.toLocaleString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		})
		.replace(",", " ");

	const untilDate = new Date(data.dates.until_date)
		.toLocaleString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		})
		.replace(",", " ");
	const date = `${t(
		"Changes will be made to the date range"
	)}: ${fromDate} - ${untilDate}`;

	const onChange = (e: any) => {
		if (typeof e === "object" && e !== null) {
			setStatus(e.target.value);
		} else setPrice(e);
	};

	const onChangeCount = (e: any) => {
		setCount(e);
	};

	const onFinish = (data: any, value: any, loadingIndex: number) => {
		setLoadings((prevLoadings) => {
			const newLoadings = [...prevLoadings];
			newLoadings[loadingIndex] = true;
			return newLoadings;
		});
		let newResponse = [];
		if (data.status === "price" && rate) {
			newResponse = [
				{
					field_name: data.status,
					field_value: value,
					rate_id: rate.id,
				},
			];
		} else
			newResponse = [
				{
					field_name: data.status,
					field_value: value,
				},
			];

		CalendarApi.update({
			updates: newResponse,
			room_id: data.room.id,
			from_date: data.dates.from_date,
			until_date: data.dates.until_date,
		})
			.then((res) => {
				const calendar_data = mergeUpdatedDate(calendar.data, res);
				dispatch(CalendarActions.updateData(calendar_data));
				setLoadings((prevLoadings) => {
					const newLoadings = [...prevLoadings];
					newLoadings[loadingIndex] = false;
					return newLoadings;
				});
			})
			.catch((err) => {
				console.log(err);
				setLoadings((prevLoadings) => {
					const newLoadings = [...prevLoadings];
					newLoadings[loadingIndex] = false;
					return newLoadings;
				});
			});
	};
	const finish = (value: any) => {
		const rate = data.room.rates.find((item: any) => item.name === value);
		setRate(rate);
	};

	return (
		<Collapse bordered={false}>
			<Panel header={t("Rooms to sell")} key="1">
				<InputNumber
					addonAfter={t("Room(s)")}
					onChange={onChangeCount}
				/>
				<h6>{date}</h6>
				<div className="buttons">
					<Button
						type="primary"
						loading={loadings[0]}
						disabled={!conut || Number(conut) < 0}
						onClick={() =>
							onFinish(
								{ ...data, status: "rooms_to_sell" },
								conut,
								0
							)
						}
					>
						{t("Save changes")}
					</Button>
					<Button type="ghost" onClick={() => setCount(0)}>
						{t("Cancel")}
					</Button>
				</div>
			</Panel>
			<Panel header={t("Prices")} key="2">
				<div className="second-collapse">
					<Select placeholder={t("Select a rate plan")} onChange={finish}>
						{data.room.rates
							.filter((rate: any) => !rate.independed_rate_id)
							.map((rate: any) => (
								<Option key={rate.id} value={rate.name}>
									{rate.name}
								</Option>
							))}
					</Select>
					<InputNumber addonAfter="UZS" onChange={onChange} />
				</div>

				<h6>{date}</h6>
				<div className="buttons">
					<Button
						type="primary"
						loading={loadings[1]}
						disabled={
							rate === undefined || !price || Number(price) < 0
						}
						onClick={() =>
							onFinish(
								{
									...data,
									status: "price",
									rate_id: rate.id,
								},
								price,
								1
							)
						}
					>
						{t("Save changes")}
					</Button>
					<Button type="ghost">{t("Cancel")}</Button>
				</div>
			</Panel>
			<Panel header={t("Room status")} key="3">
				<Radio.Group onChange={onChange}>
					<Radio value={true}>{t("Open room")}</Radio>
					<Radio value={false}>{t("Close room")}</Radio>
				</Radio.Group>
				<h6>{date}</h6>
				<div className="buttons">
					<Button
						type="primary"
						loading={loadings[2]}
						disabled={status === undefined}
						onClick={() =>
							onFinish(
								{ ...data, status: "status_day" },
								status,
								2
							)
						}
					>
						{t("Save changes")}
					</Button>
					<Button type="ghost" onClick={() => setStatus(undefined)}>
						{t("Cancel")}
					</Button>
				</div>
			</Panel>
		</Collapse>
	);
}
