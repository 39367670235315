export const TranslationLocalesMap = {
	ru: "ru",
	en: "en",
};

export type TItemWithNameTranslations = {
	name_trasnlations: TranslationsData;
	name: string;
	[key: string]: unknown;
};

export const TranslationLocales = Object.keys(TranslationLocalesMap);

export type TranslationsData = {
	ru?: string;
	en?: string;
};
