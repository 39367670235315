import { Button, Card, Col, Form, Row } from "antd";
import RateApi from "api/RateApi";
import { t } from "i18n";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MessagesService } from "services/Messages";
import RateForm from "../components/RateForm";

export default function CreateRate() {
	const [rate, setRate] = useState("list");
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const SelectRate = (select) => {
		setRate(select);
	};
	const [form] = Form.useForm();
	const onFinish = async (values) => {
		if (values?.rooms?.length > 0) {
			form.setFieldValue(
				["rooms"],
				values.rooms.map((item) => ({ id: item }))
			);
			await RateApi.create(form.getFieldValue())
				.then((res) => {
					setLoading(false);
					MessagesService.success();
					history.push("/app/rate-plans");
				})
				.catch((err) => {
					console.log(err.response);
					setLoading(false);
					form.ErrorList(err.response.data.errors);
				});
		} else {
			MessagesService.warning(t("Please select a room"));
		}
	};
	switch (rate) {
		case "list":
			return (
				<div>
					<h2>{t("Add a new rate plan")}</h2>
					<h4>{t("Increase bookings and reduce cancellations")}</h4>
					<p>
						{t(
							"These rate plans will give you a solid foundation for your pricing strategy."
						)}
					</p>
					<Card>
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
							}}
							className="mb-5"
						>
							<Col>
								<h3 className="m-0 p-0">
									{t("Fully flexible")}
								</h3>
								<p className="m-0 p-0">
									{t(
										"Let guests cancel for free and they'll boost your bookings and revenue"
									)}
								</p>
							</Col>

							<Col>
								<Button
									type="primary"
									ghost
									style={{ borderRadius: 0 }}
									onClick={() => SelectRate("flexible")}
								>
									{t("Add rate plan")}
								</Button>
							</Col>
						</Row>
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
							}}
							className="mb-5"
						>
							<Col>
								<h3 className="m-0 p-0">
									{t("Non-refundable")}
								</h3>
								<p className="m-0 p-0">
									{t(
										"Reduce cancellations by attracting guests who are sure of their dates"
									)}
								</p>
							</Col>
							<Col>
								<Button
									type="primary"
									ghost
									style={{ borderRadius: 0 }}
									onClick={() => SelectRate("non-refundable")}
								>
									{t("Add rate plan")}
								</Button>
							</Col>
						</Row>
						<Row
							style={{
								justifyContent: "space-between",
								alignItems: "center",
							}}
							className=""
						>
							<Col>
								<h3 className="m-0 p-0">{t("Custom")}</h3>
								<p className="m-0 p-0">
									{t(
										"Customise a rate plan to suit your needs"
									)}
								</p>
							</Col>
							<Col>
								<Button
									type="primary"
									ghost
									style={{ borderRadius: 0 }}
									onClick={() => SelectRate("custom")}
								>
									{t("Add rate plan")}
								</Button>
							</Col>
						</Row>
					</Card>
				</div>
			);
		case "flexible":
			return (
				<div>
					<h2>{t("Add fully flexible rate plan")}</h2>
					<Row>
						<Col xs={24} sm={24} md={20} lg={16}>
							<Card>
								<RateForm
									initialValues={{
										based_on: null,
										based_on_value: null,
										by_add: false,
										meals: [],
										name: "Fully flexible",
										policy: 2,
										release: 0,
										rate_policy: {
											free_cancel: 1,
										},
									}}
									onFinish={onFinish}
									form={form}
									type={"flexible"}
									loading={loading}
									reqType={"post"}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			);
		case "non-refundable":
			return (
				<div>
					<h2>{t("Add fully flexible rate plan")}</h2>
					<Row>
						<Col xs={24} sm={24} md={20} lg={16}>
							<Card>
								<RateForm
									initialValues={{
										based_on: null,
										based_on_value: null,
										by_add: false,
										meals: [],
										name: "Non-refundable",
										policy: 0,
										release: 0,
										rate_policy: {
											free_cancel: 0,
										},
									}}
									onFinish={onFinish}
									form={form}
									type={"non-refundable"}
									loading={loading}
									reqType={"post"}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			);
		case "custom":
			return (
				<div>
					<h2>{t("Add fully flexible rate plan")}</h2>
					<Row>
						<Col xs={24} sm={24} md={20} lg={16}>
							<Card>
								<RateForm
									initialValues={{
										based_on: null,
										based_on_value: null,
										by_add: false,
										meals: [],
										name: "",
										policy: 0,
										release: 0,
										rate_policy: {},
									}}
									onFinish={onFinish}
									form={form}
									type={"custom"}
									loading={loading}
									reqType={"post"}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			);
	}
}
