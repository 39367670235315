import { FileImageOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";

export function FacilitiesForm(props) {
	return (
		<Form
			name={props.title}
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item
				name="name"
				label={t("Facility name")}
				rules={[
					{
						required: true,
						message: `${t("Name is required")}`,
					},
				]}
			>
				<Input placeholder={t("Facility name")} />
			</Form.Item>

			<ContentTranslationFormItem name="name_translations" />

			<Form.Item
				name="icon"
				label={t("Icon upload")}
				rules={[
					{
						required: true,
						message: `${t("Icon is required")}`,
					},
				]}
			>
				<Upload
					accept="image/png, image/jpeg, image/svg"
					onChange={props.handleChange}
					listType="picture-card"
					fileList={props.fileList}
					customRequest={imageUploadCustomRequest}
					showUploadList={true}
					onRemove={props.deleteFile}
					maxCount={1}
				>
					{props.fileList.length < 1 ? (
						<>
							<FileImageOutlined /> {t("Upload")}
						</>
					) : (
						""
					)}
				</Upload>
			</Form.Item>
			<Form.Item
				name="facility_group_id"
				label={t("Facility group")}
				rules={[
					{
						required: true,
						message: `${t("Facility group is required")}`,
					},
				]}
			>
				<Select
					options={props.facilitiesGroup.map((item) => {
						return {
							label: item.name,
							value: item.id,
						};
					})}
				/>
			</Form.Item>

			<Form.Item
				name="pay_option"
				label={t("Pay options")}
				rules={[
					{
						required: true,
						message: `${t("Pay option is required")}`,
					},
				]}
			>
				<Select
					options={[
						{
							label: `${t("Paid")}`,
							value: true,
						},
						{
							label: `${t("Not Paid")}`,
							value: false,
						},
					]}
				/>
			</Form.Item>
			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
					]}
				/>
			</Form.Item>
			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
