export const PropertyStatus = {
	INACTIVE: 0,
	ACTIVE: 1,
	DRAFT: 2,
	MODERATION: 3,
};

export enum AntTableSortMap {
	ASC = "ascend",
	DESC = "descend",
}
export enum AntTableSortMapRevert {
	ascend = "ASC",
	descend = "DESC",
}

type MyOrder = "ASC" | "DESC";

export type PropertyFilter = {
	order: MyOrder;
	order_by: string;
	[key: string]: unknown;
};
