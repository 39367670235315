import Input from "antd/lib/input/Input";
import { PhoneNumberWithPrefix } from "components/forms/PhoneNumber";
import { t } from "i18n";

export function getCustomersFilterFields(setfilter, filter) {
	return [
		{
			name: "first_name",
			label: t("first_name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: "last_name",
			label: t("last_name"),
			span: 4,
			InputComponent: Input,
		},
		{
			name: "email",
			label: t("Email"),
			span: 4,
			type: "email",
			InputComponent: Input,
		},
		{
			name: "phone_number",
			label: t("Phone number"),
			span: 4,
			type: "number",
			InputComponent: PhoneNumberWithPrefix,
		},
	];
}
