import {
	LogoutOutlined,
	SettingOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import { t } from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";

export const NavProfile = ({ signOut, user, token }) => {
	const history = useHistory();
	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<Avatar
						size={45}
						style={{ backgroundColor: "#fff !important" }}
						src={user.photo ? user.photo : null}
					>
						{user.first_name?.[0] || "U"}
					</Avatar>
					<div className="pl-2" style={{ paddingLeft: "10px" }}>
						<h4 className="mb-0">
							{user.first_name} {user.last_name}
						</h4>
						<span className="text-muted">{t(user.role.name)}</span>
					</div>
				</div>
			</div>
			<Menu>
				{user.role.system != 1 && (
					<>
						<Menu.Item
							key={2}
							onClick={() => {
								history.push("/app/properties");
							}}
						>
							<SyncOutlined style={{ marginRight: 5 }} />
							<span className="font-weight-normal">
								{t("Change property")}
							</span>
						</Menu.Item>
						<Menu.Item
							key={2}
							onClick={() => {
								history.push("/app/settings");
							}}
						>
							<SettingOutlined style={{ marginRight: 5 }} />
							<span className="font-weight-normal">
								{t("Settings")}
							</span>
						</Menu.Item>
					</>
				)}

				<Menu.Item
					key={5}
					onClick={() => {
						signOut();
						history.push("/auth/login");
					}}
				>
					<LogoutOutlined style={{ marginRight: 5 }} />
					<span className="font-weight-normal">{t("Exit")}</span>
				</Menu.Item>
			</Menu>
		</div>
	);
	return (
		<Dropdown
			placement="bottomRight"
			overlay={profileMenu}
			trigger={["click"]}
		>
			<Avatar
				style={{
					color: "#3e79f7",
					backgroundColor: "#f2f2f2",
					border: "2px solid #3e79f7",
					cursor: "pointer",
				}}
				size={45}
				src={user.photo ? user.photo : null}
			>
				{user.first_name?.[0] || "U"}
			</Avatar>
		</Dropdown>
	);
};

export default NavProfile;
