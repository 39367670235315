import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import Chat from "services/Chat";
import YandexMap from "./YandexMap";

export default function MapModal({
	value,
	onChange,
}: {
	value: number[];
	onChange: Function;
}) {
	const [modal, setModal] = useState({ open: false });

	useEffect(() => {
		Chat.inbox((info) => {
			setModal({ ...info });
		}, "map");
	}, []);

	return (
		<Modal
			className="map-modal"
			open={modal.open}
			onCancel={() => setModal({ ...modal, open: false })}
			footer={false}
		>
			<YandexMap
				onChange={onChange}
				value={value ? value : [41.3112092101035, 69.27965188818546]}
			/>
		</Modal>
	);
}
