import { Card, Table } from "antd";
import { t } from "i18n";
import React from "react";

export default function Invoices() {
	const columnsFirst = [
		{
			title: `${t("Document")}`,
			key: "document",
			dataIndex: "document",
		},
		{
			title: `${t("Name")}`,
			key: "name",
			dataIndex: "name",
		},
		{
			title: `${t("Number")}`,
			key: "number",
			dataIndex: "number",
		},
		{
			title: `${t("Date")}`,
			key: "date",
			dataIndex: "date",
		},
		{
			title: `${t("Period")}`,
			key: "period",
			dataIndex: "period",
		},
		{
			title: `${t("Actions")}`,
			key: "actions",
			dataIndex: "actions",
		},
		{
			title: `${t("Status")}`,
			key: "status",
			dataIndex: "status",
		},
		{
			title: `${t("Amount")}`,
			key: "amount",
			dataIndex: "amount",
		},
	];
	const columnsSecond = [
		{
			title: `${t("Document")}`,
			key: "document",
			dataIndex: "document",
		},
		{
			title: `${t("Name")}`,
			key: "name",
			dataIndex: "name",
		},
		{
			title: `${t("Number")}`,
			key: "number",
			dataIndex: "number",
		},
		{
			title: `${t("Date")}`,
			key: "date",
			dataIndex: "date",
		},
		{
			title: `${t("Period")}`,
			key: "period",
			dataIndex: "period",
		},
		{
			title: `${t("Payment date")}`,
			key: "payment",
			dataIndex: "payment",
		},
		{
			title: `${t("Actions")}`,
			key: "actions",
			dataIndex: "actions",
		},
		{
			title: `${t("Status")}`,
			key: "status",
			dataIndex: "status",
		},
		{
			title: `${t("Amount")}`,
			key: "amount",
			dataIndex: "amount",
		},
	];

	return (
		<div>
			<h1>{t("Invoices")}</h1>
			<p>
				{t("we_produce")}
			</p>
			<p>{t("Legal company name Hotel max")}</p>
			<Card>
				<h2>{t("Outstanding balances")}</h2>
				<Table columns={columnsFirst} />
			</Card>
			<Card>
				<Table columns={columnsSecond} />
			</Card>
		</div>
	);
}
