import { Card, Col, message, Rate, Row } from "antd";
import { ResourceApi } from "api/ResourceApi";
import Loading from "components/shared-components/Loading";
import { NO_IMAGE_URL } from "constants/any";
import { t } from "i18n";

import React, { useEffect, useState } from "react";
import { PropertyStatusComponent } from "views/admin-views/properties/AllProperties/PropertyStatus";
import { DashboardReviews } from "./Components/DashboardReviews";
import NavigationMenu from "./Components/navigationMenu";

export default function Dashboard() {
	const [user, setUser] = useState();
	const [loading, setloading] = useState(false);
	useEffect(() => {
		setloading(true);
		ResourceApi.getList("property/user")
			.then((data) => {
				setloading(false);
				setUser(data);
			})
			.catch((err) => {
				console.error(err);
				message.error("error");
				setloading(false);
			});
	}, []);
	if (loading || !user) {
		return <Loading />;
	}
	return (
		<div className="dashboard">
			<div>
				<Card>
					<h2>
						{t("Welcome")} {user.first_name}
					</h2>
				</Card>
				{user.primary_property?.name ? (
					<Card>
						<Row
							style={{
								justifyContent: "space-between",
							}}
						>
							<Col xs={24} sm={24} md={4} lg={4}>
								<img
									src={
										user.primary_property.main_photo
											?.path || NO_IMAGE_URL
									}
									alt="Property"
									style={{
										width: "100%",

										objectFit: "contain",
									}}
								/>
							</Col>
							<Col xs={24} sm={24} md={19} lg={19}>
								<h4 className="d-flex align-items-center">
									<span>{user.primary_property?.name} </span>
									<Rate
										disabled
										defaultValue={
											user.primary_property?.star
										}
										style={{
											color: "#000",
											fontSize: 16,
											marginBottom: 2,
											marginLeft: 10,
											marginRight: 10,
										}}
									/>
									<PropertyStatusComponent
										status={user.primary_property.status}
									/>
								</h4>
								<p className="d-flex align-items-start">
									<svg
										aria-hidden="true"
										height="16"
										width="16"
										focusable="false"
										role="presentation"
										fill="#6B6B6B"
										viewBox="0 0 128 128"
										className="bk-icon -iconset-geo_pin"
										color="#6B6B6B"
									>
										<path d="M98.5 42.5a34.5 34.5 0 1 0-64.3 17.2L64 120l29.8-60.3a34.2 34.2 0 0 0 4.7-17.2zM64 59.7a17.2 17.2 0 1 1 17-17 17.2 17.2 0 0 1-17 17z"></path>
									</svg>
									<span style={{ marginTop: -4 }}>
										{
											user.primary_property.address
												.addressLine
										}
									</span>
								</p>
								<p></p>
							</Col>
						</Row>
					</Card>
				) : (
					""
				)}
			</div>

			<NavigationMenu />
			<DashboardReviews />
		</div>
	);
}
