import { Card, Col, Form, Row } from "antd";
import RateApi from "api/RateApi";
import { ResourceApi } from "api/ResourceApi";
import BackButton from "components/shared-components/BackButton";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MessagesService } from "services/Messages";
import RateForm from "../components/RateForm";

export default function EditRate() {
	const { id } = useParams();
	const [data, setdata] = useState();
	const [loading, setLoading] = useState(false);
	const [initialLoading, setinitialLoading] = useState(true);
	const history = useHistory();
	useEffect(() => {
		setinitialLoading(true);
		ResourceApi.getList(`property/rates/${id}`)
			.then((data) => {
				setdata(
					data.ased_on
						? { ...data, based_on: { id: data.based_on.id } }
						: { ...data }
				);
				setinitialLoading(false);
			})
			.catch((err) => {
				setinitialLoading(false);
			});
	}, []);

	const [form] = Form.useForm();
	const onFinish = async (values) => {
		form.setFieldValue(
			["rooms"],
			values.rooms.map((item) => ({ id: item }))
		);

		await RateApi.update(form.getFieldValue(), id)
			.then((res) => {
				setLoading(false);
				MessagesService.success();
				history.push("/app/rate-plans");
			})
			.catch((err) => {
				MessagesService.error();
				setLoading(false);
				form.ErrorList(err.response.data.errors);
			});
	};

	let roomsArray = data?.rooms.map((item) => {
		return item.id;
	});

	if (initialLoading) {
		return <Loading />;
	}
	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<BackButton />
				<h2 className="m-0 p-0">{t("Edit a rate plan")}</h2>
			</div>
			<Row>
				<Col xs={24} sm={24} md={20} lg={16}>
					<Card>
						<RateForm
							initialValues={{
								...data,
								rooms: roomsArray,
							}}
							onFinish={onFinish}
							form={form}
							type={"custom"}
							loading={loading}
							reqType={"put"}
						/>
					</Card>
				</Col>
			</Row>
		</div>
	);
}

export function SetErrorForm(form, errors) {
	return form.setFields(
		Object.entries(errors).map((item) => ({
			name: item[0].split("."),
			errors: [t(item[1])],
		}))
	);
}
