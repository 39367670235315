import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18n";
import React from "react";
import Chat from "services/Chat";

export function FilterButton({ onResetFilter }) {
	return (
		<>
			<div className="d-flex">
				<Button
					type="primary"
					size="small"
					htmlType="submit"
					style={{ marginRight: 5 }}
					icon={<FilterOutlined />}
					onClick={() => {
						Chat.send(true, "filter_drawer");
					}}
				>
					{t("Filter")}
				</Button>
				<Button
					block
					size="small"
					htmlType="submit"
					icon={<ReloadOutlined />}
					onClick={onResetFilter}
				/>
			</div>
		</>
	);
}
