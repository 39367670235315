import { Card, Col, Form, Radio, Row, Select } from "antd";
import { t } from "i18n";

import React, { useState } from "react";
import { hours } from "views/app-views/property/facilities-services/hours";

export default function Politics({ initialValues, isRegistration }) {
	const { Option } = Select;
	const [policy, setPolicy] = useState(initialValues.cancel_free_days_prior);

	console.log(policy);
	return (
		<div className="h-100">
			<div className="container d-flex flex-column justify-content-center h-100">
				<Row justify="">
					<Col xs={24} sm={24} md={24} lg={24}>
						{isRegistration && (
							<Card>
								<h3 className="m-0">{t("Cancellation Policy")}</h3>
								<Row gutter={15}>
									<Col xs={24} sm={24} md={12} lg={12}>
										<Form.Item
											name={[
												"policies",
												"cancel_free_days_prior",
											]}
											label={t(
												"How many days in advance can guests cancel free of charge?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Select
												placeholder={t(
													"How many days in advance can guests cancel free of charge?"
												)}
												size="large"
											>
												<Option value={99}>
													{t("Any time")}
												</Option>
												<Option value={1}>
													{t("Day of arrival")}{" "}
													(18:00)
												</Option>
												<Option value={2}>
													1 {t("day")}
												</Option>
												<Option value={3}>
													2 {t("day ")}
												</Option>
												<Option value={4}>
													4 {t("days")}
												</Option>
												<Option value={5}>
													5 {t("days")}
												</Option>
												<Option value={6}>
													6 {t("days")}
												</Option>
												<Option value={7}>
													7 {t("days")}
												</Option>
												<Option value={8}>
													8 {t("days")}
												</Option>
												<Option value={9}>
													9 {t("days")}
												</Option>
												<Option value={10}>
													10 {t("days")}
												</Option>
												<Option value={14}>
													14 {t("days")}
												</Option>
												<Option value={30}>
													30 {t("days")}
												</Option>
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={12} lg={12}>
										<Form.Item
											name={[
												"policies",
												"cancel_guest_payment",
											]}
											label={t(
												"How much is the guest charged for cancellation?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Select
												placeholder={t("Please select")}
												size="large"
											>
												<Option value={0}>
													{t(
														"the cost of the first night"
													)}
												</Option>
												<Option value={1}>
													{t(
														"50% of the total price"
													)}
												</Option>
												<Option value={2}>
													{t(
														"100% of the total price"
													)}
												</Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</Card>
						)}

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="space-between" gutter={15}>
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Check-in")}</h3>
										<Form.Item
											name={["policies", "checkin"]}
											label={t("Check-in")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Select
												placeholder={t("Check-in")}
												size="large"
											>
												{hours.map((item) => {
													return (
														<Option
															key={item}
															value={item}
														>
															{item}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Check-out")}</h3>
										<Form.Item
											name={["policies", "checkout"]}
											label={t("Check-out")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Select
												placeholder={"Check-out"}
												size="large"
											>
												{hours.map((item) => {
													return (
														<Option
															key={item}
															value={item}
														>
															{item}
														</Option>
													);
												})}
											</Select>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>

						<Card className="my-3">
							<div className="my-0 p-2">
								<Row justify="start" gutter={15}>
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Children")}</h3>

										<Form.Item
											name={[
												"policies",
												"children_allowed",
											]}
											label={t(
												"Can you accommodate children? (You can specify the ages and prices later)"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Radio.Group
												buttonStyle="solid"
												size="large"
											>
												<Radio.Button value={true}>
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value={false}>
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Col>
									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Pets")}</h3>

										<Form.Item
											name={["policies", "pets_allowed"]}
											label={t(
												"Can you accommodate pets? (You can specify the ages and prices later)"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Radio.Group
												buttonStyle="solid"
												size="large"
											>
												<Radio.Button value={true}>
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value={false}>
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Col>

									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Marriage documents")}</h3>

										<Form.Item
											name={[
												"policies",
												"family_document",
											]}
											label={t(
												"Do you need a marriage certificate to access your property?"
											)}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Radio.Group
												buttonStyle="solid"
												size="large"
											>
												<Radio.Button value={true}>
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value={false}>
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Col>

									<Col xs={24} sm={24} md={12} lg={12}>
										<h3>{t("Payment with VAT")}</h3>

										<Form.Item
											name={[
												"policies",
												"payment_with_vat",
											]}
											label={t("Are you a VAT payer?")}
											className="mt-4"
											labelCol={{
												span: 24,
											}}
											rules={[
												{
													required: true,
												},
											]}
										>
											<Radio.Group
												buttonStyle="solid"
												size="large"
											>
												<Radio.Button value={true}>
													{t("Yes")}
												</Radio.Button>
												<Radio.Button value={false}>
													{t("No")}
												</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Col>
								</Row>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}
