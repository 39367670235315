import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { PropertyApi } from "api/PropertyApi";
import { t } from "i18n";
import React from "react";
import { getNameTranslation } from "services/ContentTranslation/get-translations";

export default function FormList({ place, id, formFields, form, mainIndex }) {
	const rules = [{ required: true }];
	function removePlace(index, remove) {
		const id = form.getFieldsValue()[mainIndex + 1][index]?.id;
		console.log(id);
		if (id) {
			PropertyApi.deletePlace(id);
		}

		remove(index);
	}

	return (
		<>
			<h2>{getNameTranslation(place)}</h2>
			<Form.List name={id} initialValue={formFields}>
				{(fields, { add, remove }) => {
					return (
						<>
							{fields.map((field) => {
								return (
									<Row
										key={field.name}
										gutter={10}
										className="align-items-center"
									>
										<Form.Item
											hidden
											name={[field.name, "id"]}
										/>
										<Col span={10} lg={8} md={12}>
											<Form.Item
												rules={rules}
												label={t("Name")}
												name={[field.name, "name"]}
											>
												<Input />
											</Form.Item>
										</Col>
										<Col span={10} lg={8} md={8}>
											<Form.Item
												rules={rules}
												label={t("Distance")}
												name={[field.name, "distance"]}
											>
												<InputNumber
													style={{ width: "100%" }}
													addonAfter={t("m")}
												/>
											</Form.Item>
										</Col>

										<Col span={2} lg={4}>
											<Button
												danger
												type="ghost"
												onClick={() => {
													removePlace(
														field.name,
														remove
													);
												}}
											>
												{t("Delete")}
											</Button>
										</Col>
									</Row>
								);
							})}
							{fields.length < 3 && (
								<Button
									type="link"
									onClick={add}
									className="p-0"
								>
									+ {t("Add")}
								</Button>
							)}
						</>
					);
				}}
			</Form.List>
		</>
	);
}
