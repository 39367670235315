import { Card } from "antd";
import { t } from "i18n";
import React from "react";
import { useSelector } from "react-redux";

export default function Dashboard() {
	const user = useSelector((state) => state.auth.user);
	return (
		<Card>
			<h2>{t("Welcome super admin")}</h2>
		</Card>
	);
}
