import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	PhoneOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu } from "antd";
import {
	NAV_TYPE_TOP,
	SIDE_NAV_COLLAPSED_WIDTH,
	SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "redux/actions/Auth";
import { onMobileNavToggle, toggleCollapsedNav } from "redux/actions/Theme";
import utils from "utils";
import Logo from "./Logo";
import NavProfile from "./NavProfile";
import TelegramIcon from "./telegramIcon";

const { Header } = Layout;

export const HeaderNav = (props) => {
	const {
		navCollapsed,
		mobileNav,
		navType,
		headerNavColor,
		toggleCollapsedNav,
		onMobileNavToggle,
		isMobile,
		currentTheme,
		user,
		token,
	} = props;
	const [, setSearchActive] = useState(false);
	const onSearchClose = () => {
		setSearchActive(false);
	};

	const onToggle = () => {
		if (!isMobile) {
			toggleCollapsedNav(!navCollapsed);
		} else {
			onMobileNavToggle(!mobileNav);
		}
	};

	const isNavTop = navType === NAV_TYPE_TOP ? true : false;
	const mode = () => {
		if (!headerNavColor) {
			return utils.getColorContrast(
				currentTheme === "dark" ? "#00000" : "#ffffff"
			);
		}
		return utils.getColorContrast(headerNavColor);
	};
	const navMode = mode();
	const getNavWidth = () => {
		if (isNavTop || isMobile) {
			return "0px";
		}
		if (navCollapsed) {
			return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
		} else {
			return `${SIDE_NAV_WIDTH}px`;
		}
	};

	useEffect(() => {
		if (!isMobile) {
			onSearchClose();
		}
	});
	const menu = (
		<Menu style={{ borderRadius: 0 }}>
			<Menu.Item>
				<a
					href="https://t.me/+998900542777"
					target="_blank"
					style={{ display: "flex", alignItems: "center" }}
				>
					<TelegramIcon />
					<span style={{ marginLeft: 5 }}>Telegram</span>
				</a>
			</Menu.Item>
			<Menu.Item>
				<a href="tel:+998900542777">
					<PhoneOutlined /> +998 90 054 27 77
				</a>
			</Menu.Item>
		</Menu>
	);
	console.log(user);
	return (
		<Header
			className={`app-header ${navMode}`}
			style={{
				background: "var(--primary-color)",
			}}
		>
			<div
				className={`app-header-wrapper ${
					isNavTop ? "layout-top-nav" : ""
				}`}
			>
				<Link
					to={
						user?.role?.system === 1
							? "/app/admin/dashboard"
							: "/app/dashboard"
					}
				>
					<Logo logoType={navMode} />
				</Link>

				<div
					className="nav"
					style={{
						width: `calc(100% - ${getNavWidth()})`,
						background: "var(--primary-color)",
						alignItems: "center",
					}}
				>
					<div
						style={{
							height: "50%",
							borderLeft: "1.5px solid #fff",
						}}
					></div>
					{!isMobile ? (
						<span
							style={{
								color: "#fff",
								marginLeft: 10,
								fontSize: 16,
							}}
						>
							{user?.role?.system === 2 &&
								user?.primary_property?.name +
									"  -  " +
									user?.primary_property?.id}
						</span>
					) : (
						""
					)}
					<div className="nav-left">
						<ul className="ant-menu ant-menu-root ant-menu-horizontal">
							{isNavTop && !isMobile ? null : (
								<li
									className="ant-menu-item ant-menu-item-only-child"
									onClick={() => {
										onToggle();
									}}
								>
									{navCollapsed || isMobile ? (
										<MenuUnfoldOutlined
											className="nav-icon"
											style={{ color: "#fff" }}
										/>
									) : (
										<MenuFoldOutlined
											className="nav-icon"
											style={{ color: "#fff" }}
										/>
									)}
								</li>
							)}
						</ul>
					</div>
					<div className="nav-right d-flex align-items-center">
						<Dropdown overlay={menu} trigger={["click"]}>
							<Button
								type="link"
								style={{
									color: "#fff",
									fontSize: 16,
								}}
							>
								<QuestionCircleOutlined />{" "}
								<span>{t("Help")}</span>
							</Button>
						</Dropdown>
						<NavProfile
							user={user}
							token={token}
							signOut={signOut}
						/>
					</div>
				</div>
			</div>
		</Header>
	);
};

const mapStateToProps = ({ theme, auth }) => {
	const {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
	} = theme;

	return {
		navCollapsed,
		navType,
		headerNavColor,
		mobileNav,
		currentTheme,
		direction,
		token: auth.token,
		user: auth.user,
	};
};

export default connect(mapStateToProps, {
	toggleCollapsedNav,
	onMobileNavToggle,
	signOut,
})(HeaderNav);
