import { BellFilled } from "@ant-design/icons";
import {
	Alert,
	Avatar,
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	InputNumber,
	Radio,
	Row,
	Select,
} from "antd";
import { ResourceApi } from "api/ResourceApi";
import { MealsTypes } from "constants/MealsType";
import { t } from "i18n";
import { React, useEffect, useState } from "react";
import TraslateService from "services/TranslateService";
const { Option } = Select;

export default function RateForm({
	initialValues,
	onFinish,
	form,
	type,
	loading,
}) {
	const [males, setmales] = useState(initialValues?.meals?.length > 0);
	const [minimumStay, setminimumStay] = useState(initialValues?.min_stay);
	const [plans, setplans] = useState(initialValues?.based_on);
	const [rales, setRates] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [activerooms, setActiveRooms] = useState([]);
	const [policy, setPolicy] = useState({
		rate_policy: {
			...initialValues?.rate_policy,
		},
	});
	const [isNonRefundable, setIsNonRefundable] = useState(
		initialValues?.rate_policy?.free_cancel === 0 ? false : true
	);
	const options = [
		{ value: true, label: t("Yes") },
		{ value: false, label: t("No") },
	];
	useEffect(() => {
		ResourceApi.getList("property/rates", {
			page: 1,
			limit: 100,
		}).then((data) => {
			setRates(
				data.items.filter(
					(item) =>
						item.based_on === null && item.id !== initialValues?.id
				)
			);
		});
	}, []);

	useEffect(() => {
		ResourceApi.getList("property/rooms", {
			page: 1,
			limit: 1000,
		}).then((data) => {
			setRooms(data.items);

			if (initialValues?.based_on) {
				ResourceApi.getList(
					`property/rates/${initialValues?.based_on?.id}`
				).then((data) => {
					setActiveRooms(data.rooms);
				});
			} else {
				setActiveRooms(data.items);
			}
		});
	}, []);
	return (
		<Form
			name="rate"
			form={form}
			layout="vertical"
			onFinish={onFinish}
			autoComplete="off"
			initialValues={{
				...initialValues,
				based_on_value:
					initialValues.based_on_value || initialValues.by_add
						? 10
						: null,
			}}
		>
			<div className="my-0 p-2">
				<div className="d-flex align-items-center mt-4 mb-3">
					{" "}
					<Avatar
						size={30}
						style={{
							color: "#020202",
							marginRight: "8px",
							fontSize: 14,
						}}
					>
						1
					</Avatar>
					<h5 className="m-0">{t("Cancellations")}</h5>
				</div>

				<label className="d-block">
					{t(
						"Is there a period when the guest can cancel free of charge?"
					)}
				</label>
				<Radio.Group
					options={options}
					optionType="button"
					buttonStyle="solid"
					value={isNonRefundable}
					onChange={(e) => {
						if (e.target.value) {
							setIsNonRefundable(true);
							setPolicy({
								rate_policy: {},
							});
						} else {
							setIsNonRefundable(e.target.value);
							setPolicy({
								rate_policy: {
									free_cancel: 0,
								},
							});
							form.setFieldValue(
								["rate_policy", "free_cancel"],
								0
							);
						}
					}}
				/>

				<Row justify="space-between">
					{isNonRefundable ? (
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item
								name={["rate_policy", "free_cancel"]}
								label={t(
									"How many days in advance can guests cancel free of charge?"
								)}
								className="mt-4"
								labelCol={{
									span: 24,
								}}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder={t(
										"How many days in advance can guests cancel free of charge?"
									)}
									size="large"
									onChange={(value) => {
										if (value === 99 || value === 0) {
											console.log(value);
											setPolicy({
												rate_policy: {
													cancel_penalty: undefined,
													no_show_penalty: undefined,
													free_cancel: value,
												},
											});
										}
										setPolicy({
											rate_policy: {
												...policy.rate_policy,
												free_cancel: value,
											},
										});
									}}
								>
									<Option value={99}>{t("Any time")}</Option>
									<Option value={1}>
										{t("Day of arrival")} (18:00)
									</Option>
									<Option value={2}>1 {t("day")}</Option>
									<Option value={3}>2 {t("day ")}</Option>
									<Option value={4}>4 {t("days")}</Option>
									<Option value={5}>5 {t("days")}</Option>
									<Option value={6}>6 {t("days")}</Option>
									<Option value={7}>7 {t("days")}</Option>
									<Option value={8}>8 {t("days")}</Option>
									<Option value={9}>9 {t("days")}</Option>
									<Option value={10}>10 {t("days")}</Option>
									<Option value={14}>14 {t("days")}</Option>
									<Option value={30}>30 {t("days")}</Option>
								</Select>
							</Form.Item>
						</Col>
					) : null}
					{policy?.rate_policy?.free_cancel >= 0 &&
					policy?.rate_policy?.free_cancel !== 99 ? (
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item
								name={["rate_policy", "cancel_penalty"]}
								label={
									policy?.rate_policy?.free_cancel === 1
										? t(
												"How much is the guest charged for cancellation after "
										  ) +
										  " 18:00 " +
										  " " +
										  t("on the day of arrival?")
										: policy?.rate_policy?.free_cancel > 3
										? t(
												"How much is the guest charged for cancellation after "
										  ) +
										  " " +
										  policy?.rate_policy?.free_cancel +
										  t(" days ") +
										  t("before arrival?")
										: policy?.rate_policy?.free_cancel >=
												2 &&
										  policy?.rate_policy?.free_cancel <= 3
										? t(
												"How much is the guest charged for cancellation after "
										  ) +
										  " " +
										  (policy?.rate_policy?.free_cancel -
												1) +
										  t(" day ") +
										  t("before arrival?")
										: !isNonRefundable
										? t(
												"How much is the guest charged for cancellation?"
										  )
										: null
								}
								className="mt-4"
								labelCol={{
									span: 24,
								}}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder={t("Please select")}
									size="large"
									onChange={(value) => {
										setPolicy({
											rate_policy: {
												...policy.rate_policy,
												cancel_penalty: value,
											},
										});
									}}
								>
									<Option value={0}>
										{t("the cost of the first night")}
									</Option>
									<Option value={1}>
										{t("50% of the total price")}
									</Option>
									<Option value={2}>
										{t("100% of the total price")}
									</Option>
								</Select>
							</Form.Item>
						</Col>
					) : null}
					{policy?.rate_policy?.cancel_penalty >= 0 &&
					policy?.rate_policy?.free_cancel !== 99 ? (
						<Col xs={24} sm={24} md={24} lg={24}>
							<Form.Item
								name={["rate_policy", "no_show_penalty"]}
								label={t(
									"How much is the guest charged for a no show?"
								)}
								className="mt-4"
								labelCol={{
									span: 24,
								}}
								rules={[
									{
										required: true,
									},
								]}
							>
								<Select
									placeholder={t("Please select")}
									size="large"
									onChange={(value) => {
										setPolicy({
											rate_policy: {
												...policy.rate_policy,
												no_show_penalty: value,
											},
										});
									}}
								>
									<Option value={0}>
										{t("the cost of the first night")}
									</Option>
									<Option value={1}>
										{t("50% of the total price")}
									</Option>
									<Option value={2}>
										{t("100% of the total price")}
									</Option>
								</Select>
							</Form.Item>
						</Col>
					) : null}
				</Row>

				<Alert
					style={{ marginTop: 15 }}
					message={
						<>
							<h4>
								<BellFilled /> {t("Policy summary")}
							</h4>
							<h5>
								{policy?.rate_policy?.free_cancel === 99 &&
									t(
										"The guest can cancel free of charge at any time."
									)}
							</h5>
							{policy?.rate_policy?.free_cancel !== 99 && (
								<h5>
									{policy?.rate_policy?.free_cancel === 1 &&
										t(
											"The guest can cancel free of charge until 18:00 on the day of arrival. "
										)}
									{policy?.rate_policy?.free_cancel > 1 &&
										t(
											"The guest can cancel free of charge until "
										) +
											policy?.rate_policy?.free_cancel +
											t(" days before arrival.") +
											" "}
									{policy?.rate_policy?.cancel_penalty ===
										0 &&
										!isNonRefundable &&
										t("The guest will be charged ") +
											t("the cost of the first night") +
											t(
												"if they cancel after reservation."
											)}{" "}
									{policy?.rate_policy?.cancel_penalty ===
										1 &&
										!isNonRefundable &&
										t("The guest will be charged ") +
											t("50% of the total price") +
											t(
												"if they cancel after reservation."
											)}
									{policy?.rate_policy?.cancel_penalty ===
										2 &&
										!isNonRefundable &&
										t("The guest will be charged ") +
											t("100% of the total price") +
											t(
												"if they cancel after reservation."
											)}{" "}
									{policy?.rate_policy?.cancel_penalty ===
										0 &&
										isNonRefundable &&
										t("The guest will be charged ") +
											t("the cost of the first night") +
											" " +
											t("if they cancel in the ") +
											" " +
											policy?.rate_policy?.free_cancel +
											" " +
											t("days before arrival.")}{" "}
									{policy?.rate_policy?.cancel_penalty ===
										1 &&
										isNonRefundable &&
										t("The guest will be charged ") +
											t("50% of the total price") +
											" " +
											t("if they cancel in the ") +
											" " +
											policy?.rate_policy?.free_cancel +
											" " +
											t("days before arrival.")}{" "}
									{policy?.rate_policy?.cancel_penalty ===
										2 &&
										isNonRefundable &&
										t("The guest will be charged ") +
											t("100% of the total price") +
											" " +
											t("if they cancel in the ") +
											" " +
											policy?.rate_policy?.free_cancel +
											" " +
											t("days before arrival.")}{" "}
									{policy?.rate_policy?.no_show_penalty ===
										2 &&
										t(
											"If the guest doesn’t show up, they will be charged the total price of the reservation."
										)}
									{policy?.rate_policy?.no_show_penalty ===
										1 &&
										t(
											"If the guest does not show up, he will have to pay half the cost of the reservation."
										)}
									{policy?.rate_policy?.no_show_penalty ===
										0 &&
										t(
											"If the guest does not arrive, he must pay the first night of the reservation price."
										)}
								</h5>
							)}
							<p>{t("Please note")}</p>
						</>
					}
					type="info"
				/>
			</div>
			<hr
				style={{
					border: "0.8px solid #f2f2f2",
				}}
			/>
			<div className="d-flex align-items-center mt-4 mb-3">
				{" "}
				<Avatar
					size={30}
					style={{
						color: "#020202",
						marginRight: "8px",
						fontSize: 14,
					}}
				>
					2
				</Avatar>
				<h5 className="m-0">
					{t("Would you like to include meals in this rate plan?")}
				</h5>
			</div>
			<Radio.Group
				onChange={(e) => {
					if (!e.target.value) {
						form.setFieldValue(["meals"], []);
					}
					setmales(e.target.value);
				}}
				defaultValue={initialValues.meals.length > 0}
			>
				<Row>
					<Col span={24}>
						<Radio value={false}>{t("No")}</Radio>
					</Col>
					<Col span={24}>
						<Radio value={true}>{t("Yes, add a meal plan")}</Radio>
					</Col>
				</Row>
			</Radio.Group>
			{males ? (
				<Form.Item
					name="meals"
					className="mt-2"
					labelCol={{
						span: 24,
					}}
				>
					<Checkbox.Group style={{ width: "100%" }}>
						<Row>
							{Object.entries(MealsTypes).map(([key, value]) => (
								<Col key={key} span={24}>
									<Checkbox value={parseInt(key)}>
										{value}
									</Checkbox>
								</Col>
							))}
						</Row>
					</Checkbox.Group>
				</Form.Item>
			) : (
				""
			)}
			<hr
				style={{
					border: "0.8px solid #f2f2f2",
				}}
			/>

			<div className="d-flex align-items-center mt-4 mb-3">
				{" "}
				<Avatar
					size={30}
					style={{
						color: "#020202",
						marginRight: "8px",
						fontSize: 14,
					}}
				>
					3
				</Avatar>
				<h5 className="m-0">
					{t(
						"Would you like to set a minimum length of stay for this rate plan?"
					)}
				</h5>
			</div>
			<Radio.Group
				onChange={(e) => setminimumStay(e.target.value)}
				defaultValue={initialValues.min_stay > 0}
			>
				<Row>
					<Col span={24}>
						<Radio value={false}>{t("No")}</Radio>
					</Col>
					<Col span={24}>
						<Radio value={true}>{t("Yes")}</Radio>
					</Col>
				</Row>
			</Radio.Group>
			{minimumStay ? (
				<>
					<Form.Item
						name="min_stay"
						className="mt-2"
						labelCol={{
							span: 24,
						}}
					>
						<InputNumber
							min={1}
							className={"w-lg-50 w-100"}
							addonAfter={<span> {t("night minimum stay")}</span>}
						/>
					</Form.Item>
					<Alert
						message={
							<>
								<h5
									style={{
										color: "orange",
									}}
								>
									{t("Keep in mind")}
								</h5>
								{t(
									"48% of bookings are made for only one night. If you set a minimum length of stay, you are likely to lose these bookings"
								)}
							</>
						}
						type="warning"
					/>
				</>
			) : (
				""
			)}

			<hr
				style={{
					border: "0.8px solid #f2f2f2",
				}}
			/>
			<div className="d-flex align-items-center mt-4 mb-3">
				{" "}
				<Avatar
					size={30}
					style={{
						color: "#020202",
						marginRight: "8px",
						fontSize: 14,
					}}
				>
					4
				</Avatar>
				<h5 className="m-0">
					{t("How would you like to manage this rate plan?")}
				</h5>
			</div>
			<Radio.Group
				onChange={(e) => {
					setplans(e.target.value);
					if (!e.target.value) {
						form.setFieldValue("based_on", null);
						setActiveRooms(rooms);
					}
				}}
				defaultValue={initialValues.based_on ? true : false}
			>
				<Row>
					<Col span={24}>
						<Radio value={false}>
							{t("Set up as a new rate plan")}
						</Radio>
					</Col>
					<Col span={24}>
						<Radio value={true}>
							{t("Based on one of my current rate plans")}
						</Radio>
					</Col>
				</Row>
			</Radio.Group>
			{plans ? (
				<>
					<Form.Item
						name={["based_on", "id"]}
						className="mt-2"
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: `${t("Based on is required")}`,
							},
						]}
					>
						<Select
							onChange={(e) => {
								let rooms = rales.filter(
									(rate) => rate.id === e
								);
								setActiveRooms(rooms[0].rooms);
							}}
							placeholder={t("Select rate")}
							options={rales.map((item) => {
								return {
									value: item.id,
									label: item.name,
								};
							})}
						/>
					</Form.Item>
					<Alert
						type="info"
						message={t(
							"This is the easiest and quickest way to manage your new rate plan. It will base its price on Standard Rate, plus whatever price increase you set in the next step. The rate plan will only be available on Bookhotel.uz . You won't need to changeprices, load availability or map the rate plan in your channel manager."
						)}
					/>

					<hr
						style={{
							border: "0.8px solid #f2f2f2",
						}}
					/>
					<div className="d-flex align-items-center mt-4 mb-3">
						{" "}
						<Avatar
							size={30}
							style={{
								color: "#020202",
								marginRight: "8px",
								fontSize: 14,
							}}
						>
							5
						</Avatar>
						<h5 className="m-0">
							{t(
								"How much more expensive than Standard Rate would you like to set this rate plan?"
							)}
						</h5>
					</div>
					<Form.Item
						name="based_on_value"
						className="mt-2"
						labelCol={{
							span: 24,
						}}
						rules={[
							{
								required: true,
								message: `${t("Based on value is required")}`,
							},
						]}
					>
						<BasedOnValue />
					</Form.Item>
				</>
			) : (
				<Alert
					className="mt-3"
					message={
						<>
							<h5
								style={{
									color: "orange",
								}}
							>
								{t("Keep in mind")}
							</h5>
							{t("There are a few extra steps")}
							<b> bookhotel.uz</b>
						</>
					}
					type="warning"
				/>
			)}

			<hr
				style={{
					border: "0.8px solid #f2f2f2",
				}}
			/>
			<Form.Item
				name={["rooms"]}
				className="mt-2"
				rules={[
					{
						type: "array",
					},
					{
						required: true,
						message: `${t("Room is required")}`,
					},
				]}
				label={
					<>
						<div className="d-flex align-items-center mt-4 mb-3">
							{" "}
							<Avatar
								size={30}
								style={{
									color: "#020202",
									marginRight: "8px",
									fontSize: 14,
								}}
							>
								{plans ? 6 : 5}
							</Avatar>
							<h5 className="m-0">
								{t(
									"Which room types will be bookable with this rate plan?"
								)}
							</h5>
						</div>
					</>
				}
				labelCol={{
					span: 24,
				}}
			>
				<Checkbox.Group style={{ width: "100%" }}>
					<Row>
						{activerooms.map((room) => {
							return (
								<Col span={24} key={room.id}>
									<Checkbox value={parseInt(room.id)}>
										{TraslateService(
											room.room_type.name,
											room.room_type.name_translations
										)}
									</Checkbox>
								</Col>
							);
						})}
					</Row>
				</Checkbox.Group>
			</Form.Item>

			<hr
				style={{
					border: "0.8px solid #f2f2f2",
				}}
			/>

			<Form.Item
				name="name"
				className="mt-2"
				rules={[
					{
						required: true,
						message: `${t("Room name is required")}`,
					},
				]}
				label={
					<>
						<div className="d-flex align-items-center mt-4 mb-2">
							{" "}
							<Avatar
								size={30}
								style={{
									color: "#020202",
									marginRight: "8px",
									fontSize: 14,
								}}
							>
								{plans ? 7 : 6}
							</Avatar>
							<h5 className="m-0">
								{t(
									"What would you like to name this rate plan?"
								)}
							</h5>
						</div>
					</>
				}
				labelCol={{
					span: 24,
				}}
			>
				<Input></Input>
			</Form.Item>
			<Alert
				type="info"
				message={
					<span>
						{t(
							"This name is just for you. We won't show it to customers on"
						)}
						<b> bookhotel.uz</b>
					</span>
				}
			/>

			<Form.Item name="release" hidden>
				<Input type="number"></Input>
			</Form.Item>

			<Button
				type="primary"
				className="my-4 float-right "
				style={{ borderRadius: 0 }}
				htmlType="submit"
				size="middle"
				loading={loading}
			>
				{t("Active rate plan")}
			</Button>
		</Form>
	);
}

function isPositive(num) {
	return num >= 0;
}

function BasedOnValue({ value, onChange }) {
	return (
		<InputNumber
			value={value ? Math.abs(value) : value}
			onChange={onChange}
			style={{ width: "50%" }}
			name="based_on_value"
			addonBefore={
				<Select
					value={isPositive(value)}
					onChange={(v) => {
						if (!v && value) {
							onChange(value * -1);
						} else {
							onChange(Math.abs(value));
						}
					}}
					className="select-after"
				>
					<Option value={true}>+</Option>
					<Option value={false}>-</Option>
				</Select>
			}
			addonAfter={
				<Form.Item
					name="by_add"
					noStyle
					rules={[
						{
							required: true,
							message: `${t("By add is required")}`,
						},
					]}
				>
					<Select className="select-after">
						<Option value={false}>%</Option>
						<Option value={true}>UZS</Option>
					</Select>
				</Form.Item>
			}
			min={0}
		></InputNumber>
	);
}
