import { Modal } from "antd";
import { CalendarApi } from "api/CalendarApi";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalendarActions from "redux/calendar/calendar.actions";
import { calendarSelector } from "redux/calendar/calendar.selector";
import Chat from "services/Chat";
import { margeCalendarUpdatedStrategy } from "utils/array";
import EditPriceModalContent from "./EditPriceModalContent";

export function EditPriceModal({ fetchCalendar }: any) {
	const [modal, setModal] = useState({
		open: false,
		data: { name: "", room_id: "", rate_id: "" },
	});
	const [strategy, setStrategy] = useState({});
	const calendar = useSelector(calendarSelector);
	const dispatch = useDispatch();
	useEffect(() => {
		Chat.inbox((info) => {
			setModal({
				open: info.open,
				data: { ...info },
			});
			if (info.room_id) {
				CalendarApi.pricePerGuestStrategy({
					room_id: info.room_id,
					rate_id: info.rate_id,
				}).then((res: any) => {
					const new_calendar = margeCalendarUpdatedStrategy({
						...res,
						room_id: info.room_id,
						rate_id: info.rate_id,
						calendar: calendar,
					});
					dispatch(CalendarActions.updateData(new_calendar));
					setStrategy(res);
				});
			}
		}, "edit_pricing");
	}, [calendar, dispatch]);
	return (
		<Modal
			className="edit-pricing-popup"
			title={
				<>
					<h3 className="m-0 font-weight-bold">
						{t("Edit pricing per guest")}
					</h3>
					<h5 className="m-0 font-weight-normal">
						{modal.data.name}
					</h5>
				</>
			}
			open={modal.open}
			onCancel={() => setModal({ ...modal, open: false })}
			footer={false}
		>
			<h5 className="border-bottom p-4 m-0">
				{t("Your prices can change")}
			</h5>
			{strategy && (
				<EditPriceModalContent
					fetchCalendar={fetchCalendar}
					strategy={{
						...strategy,
						room_id: modal.data.room_id,
						rate_id: modal.data.rate_id,
						name: modal.data.name,
					}}
				/>
			)}
		</Modal>
	);
}
