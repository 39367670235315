import {
	Button,
	Card,
	Col,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
} from "antd";
import { CustomersApi } from "api/Customers";
import { FilterView } from "components/FilterView";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import { getCustomersFilterFields } from "./getCustomersFilterFields";
import { TableComponent } from "./TableComponent";

const initial_filter = {
	customer: {
		first_name: "",
		last_name: "",
		tin: "",
	},
};

export default function LegalCustomers() {
	const [filter, setfilter] = useState(initial_filter);
	const [data, setdata] = useState([]);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [form] = Form.useForm();
	useEffect(() => {
		fetch(filter);
	}, []);

	const fetch = (filter) => {
		setLoading(true);
		CustomersApi.getLegalCustomers({
			...filter,
		})
			.then(({ data }) => {
				setdata(data.items);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				MessagesService.error(t("Error"));
			});
	};

	const changeStatus = () => {
		CustomersApi.editLegalCustomer(form.getFieldValue().id, {
			tin: String(form.getFieldValue().tin),
			mfi: String(form.getFieldValue().mfi),
			account: String(form.getFieldValue().account),
			status: form.getFieldValue().status,
			name: form.getFieldValue().name,
		})
			.then((customer) => {
				form.setFieldsValue({});
				MessagesService.success(t("Changed successfully"));
				setOpen(false);
				fetch(filter);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div>
			<Row
				style={{
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Col>
					<h2>{t("Legal customers")}</h2>
				</Col>
			</Row>
			<FilterView
				filter={filter}
				onSubmit={(new_filter) => {
					const f = { ...filter, ...new_filter };
					setfilter(f);
					fetch(f);
				}}
				fields={getCustomersFilterFields()}
			/>

			<Card>
				<TableComponent
					data={data}
					loading={loading}
					setOpen={setOpen}
					setFieldsValue={form.setFieldsValue}
				></TableComponent>
			</Card>

			<Modal
				title={t("Change status")}
				open={open}
				footer={null}
				onCancel={() => setOpen(false)}
			>
				<Form name="change_status" form={form} onFinish={changeStatus}>
					<Form.Item label={t("Id")} name="id" hidden>
						<Input disabled />
					</Form.Item>
					<Form.Item
						label={t("Legal name")}
						name="name"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: t("Please select legal name"),
							},
						]}
					>
						<Input placeholder={t("Legal name")} />
					</Form.Item>
					<Form.Item
						label={t("TIN")}
						name="tin"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: t("Please select tin"),
							},
							() => ({
								validator(_, value) {
									if (!value && value.length !== 9) {
										return Promise.reject(
											"Please enter 9 digits"
										);
									}
									return Promise.resolve();
								},
							}),
						]}
					>
						<InputNumber
							placeholder={t("TIN")}
							style={{ width: "100%" }}
							maxLength={9}
						/>
					</Form.Item>
					<Form.Item
						label={t("MFI")}
						name="mfi"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: t("Please select MFI"),
							},
							() => ({
								validator(_, value) {
									if (!value && value.length !== 5) {
										return Promise.reject(
											"Please enter a valid MFI"
										);
									}
									return Promise.resolve();
								},
							}),
						]}
					>
						<InputNumber
							placeholder={t("MFI")}
							style={{ width: "100%" }}
							maxLength={5}
						/>
					</Form.Item>
					<Form.Item
						label={t("Account")}
						name="account"
						labelCol={{ span: 24 }}
						rules={[
							{
								required: true,
								message: t("Please select account"),
							},
							{
								validator(_, value) {
									if (!value && value.length !== 20) {
										return Promise.reject(
											"Account must be 20 digits"
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<InputNumber
							placeholder={t("Account")}
							style={{ width: "100%" }}
							maxLength={20}
						/>
					</Form.Item>
					<Form.Item
						label={t("Status")}
						name="status"
						rules={[
							{
								required: true,
								message: t("Please select status"),
							},
						]}
						labelCol={{ span: 24 }}
					>
						<Select
							placeholder={t("Please select status")}
							allowClear
						>
							<Select.Option value={0}>
								{t("Pending")}
							</Select.Option>
							<Select.Option value={1}>
								{t("Active")}
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						style={{ display: "flex", justifyContent: "end" }}
					>
						<Button type="primary" htmlType="submit">
							{t("Change status")}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}
