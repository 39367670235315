import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import { t } from "i18n";

export const roles = {
	super_admin: 1,
	property_owner: 2,
	maderator: 3,
	accountant: 4,
};

const dashBoardNavTree = {
	key: "home5",
	path: `${APP_PREFIX_PATH}/`,
	title: `${t("Dashboard")}`,
	icon: "SearchOutlined",
	breadcrumb: false,
	submenu: [
		{
			key: "home2",
			path: `${APP_PREFIX_PATH}/dashboard`,
			title: `${t("home")}`,
			icon: "HomeOutlined",
			role: roles.property_owner,
			submenu: [],
			other: true,
		},
		{
			key: "rates",
			path: `${APP_PREFIX_PATH}/rates`,
			title: `${t("Calendar")}`,
			icon: "FileDoneOutlined",
			role: roles.property_owner,
			submenu: [
				{
					key: "rates/calendar",
					path: `${APP_PREFIX_PATH}/calendar`,
					title: `${t("Calendar")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "rates/plans",
					path: `${APP_PREFIX_PATH}/rate-plans`,
					title: `${t("Rate plans")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				// {
				// 	key: "rates/availability",
				// 	path: `${APP_PREFIX_PATH}/availability-optimisation`,
				// 	title: `${t("Availability optimisation")}`,
				// 	role: roles.property_owner,
				// 	submenu: [],
				// 	other: true,
				// },
			],
		},
		{
			key: "rates/reservations",
			path: `${APP_PREFIX_PATH}/reservations`,
			title: `${t("Reservations")}`,
			icon: "UnorderedListOutlined",
			role: roles.property_owner,
			submenu: [],
		},
		{
			key: "properties",
			path: `${APP_PREFIX_PATH}/properties`,
			title: `${t("Property")}`,
			icon: "EditOutlined",
			role: roles.property_owner,
			submenu: [
				// {
				// 	key: "properties/score",
				// 	path: `${APP_PREFIX_PATH}/properties-score`,
				// 	title: `${t("Property page score")}`,
				// 	role: roles.property_owner,
				// 	submenu: [],
				// 	other: true,
				// },
				{
					key: "properties/general",
					path: `${APP_PREFIX_PATH}/general-info`,
					title: `${t("General info")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "marketing",
					path: `${APP_PREFIX_PATH}/marketing`,
					title: `${t("Marketing")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},

				{
					key: "properties/photos",
					path: `${APP_PREFIX_PATH}/photos`,
					title: `${t("Photos")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "properties/facilities-services",
					path: `${APP_PREFIX_PATH}/facilities-services`,
					title: `${t("Facilities Services")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "properties/policies",
					path: `${APP_PREFIX_PATH}/policies`,
					title: `${t("Policies")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "properties/room-details",
					path: `${APP_PREFIX_PATH}/room-details`,
					title: `${t("Room details")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "properties/amenities",
					path: `${APP_PREFIX_PATH}/room-amenities`,
					title: `${t("Room amenities")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
				{
					key: "properties/nearby-places",
					path: `${APP_PREFIX_PATH}/nearby-places`,
					title: `${t("What's nearby places")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
			],
		},

		{
			key: "inbox",
			path: `${APP_PREFIX_PATH}/inbox`,
			title: `${t("Chat")}`,
			icon: "MailOutlined",
			role: roles.property_owner,
			submenu: [
				{
					key: "reservation-messages",
					path: `${APP_PREFIX_PATH}/chat`,
					title: `${t("Reservation messages")}`,
					role: roles.property_owner,
					submenu: [],
					other: true,
				},
			],
		},
		// {
		// 	key: "finance",
		// 	path: `${APP_PREFIX_PATH}/finance`,
		// 	title: `${t("Finance")}`,
		// 	icon: "FileDoneOutlined",
		// 	role: roles.property_owner,
		// 	submenu: [
		// 		{
		// 			key: "invoices",
		// 			path: `${APP_PREFIX_PATH}/invoices`,
		// 			title: `${t("Invoices")}`,
		// 			role: roles.property_owner,
		// 			submenu: [],
		// 			other: true,
		// 		},
		// 	],
		// },
		{
			key: "reviews",
			path: `${APP_PREFIX_PATH}/reviews`,
			title: `${t("Guest reviews")}`,
			icon: "LikeOutlined",
			role: roles.property_owner,
			submenu: [],
			other: true,
		},
		{
			key: "super_dashboard",
			path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/dashboard`,
			title: `${t("home")}`,
			icon: "HomeOutlined",
			role: roles.super_admin,
			submenu: [],
			other: true,
		},
		{
			key: "resources",
			title: `${t("Resources")}`,
			icon: "EditOutlined",
			role: roles.super_admin,
			submenu: [
				// {
				// 	key: "resources/facilities-group",
				// 	path: `${
				// 		APP_PREFIX_PATH + ADMIN_PREFIX_PATH
				// 	}/facilities-group`,
				// 	title: `${t("Facilities group")}`,
				// 	role: roles.super_admin,
				// 	submenu: [],
				// 	other: true,
				// },
				{
					key: "resources/categories",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/categories`,
					title: `${t("Categories")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/facilities",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/facilities`,
					title: `${t("Facilities")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/amenities-group",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/amenities-group`,
					title: `${t("Aminity group")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/amenities",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/amenities`,
					title: `${t("Amenities")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/regions",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/regions`,
					title: `${t("Regions")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/payment-methods",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/payment-methods`,
					title: `${t("Payment methods")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/nearby-places",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/nearby-places`,
					title: `${t("Nearby places")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/photo-tags",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/photo-tags`,
					title: `${t("Photo tags")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},

				{
					key: "resources/rooms/bed-types",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/bed-types`,
					title: `${t("Bed types")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/rooms/room-types",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/room-types`,
					title: `${t("Room types")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/breakfast-types",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/breakfast-types`,
					title: `${t("Breakfast types")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "resources/languages",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/languages`,
					title: `${t("Staff languages")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
			],
			other: true,
		},
		{
			key: "reservations",
			path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/reservations`,
			title: `${t("Reservations")}`,
			icon: "UnorderedListOutlined",
			role: roles.super_admin,
			submenu: [],
			other: true,
		},
		{
			key: "properties",
			title: `${t("Properties")}`,
			path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/all-properties`,
			icon: "PicLeftOutlined",
			role: roles.super_admin,
			submenu: [
				{
					key: "properties",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/all-properties`,
					title: `${t("Properties")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "all-legal-datas",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/all-legal-datas`,
					title: `${t("All legal datas")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
			],
			other: true,
		},
		{
			key: "customers",
			path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/customers`,
			title: `${t("Customers")}`,
			icon: "UserOutlined",
			role: roles.super_admin,
			submenu: [
				{
					key: "Customers",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/customers`,
					title: `${t("Customers")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "Legal customers",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/legal-customers`,
					title: `${t("Legal customers")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
			],
			other: true,
		},
		{
			key: "payments",
			path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/payments`,
			title: `${t("Payments")}`,
			icon: "DollarCircleOutlined",
			role: roles.super_admin,
			submenu: [
				{
					key: "Payments",
					path: `${APP_PREFIX_PATH + ADMIN_PREFIX_PATH}/payments`,
					title: `${t("Payments")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
				{
					key: "corporate transaction",
					path: `${
						APP_PREFIX_PATH + ADMIN_PREFIX_PATH
					}/corporate-transaction`,
					title: `${t("Corporate transaction")}`,
					role: roles.super_admin,
					submenu: [],
					other: true,
				},
			],
			other: true,
		},
	],
};

export const permissions = {
	READ_SEARCH_PANEL: "READ_SEARCH_PANEL",
};

export function generateNavigationConfig(user) {
	return dashBoardNavTree.submenu.filter((i) => i.role === user.role.system);
}

const navigationConfig = [dashBoardNavTree];

export default navigationConfig;
