import { Button, Col, Form } from "antd";
import { PropertyApi } from "api/PropertyApi";
import Loading from "components/shared-components/Loading";
import { t } from "i18n";
import React, { useEffect, useState } from "react";
import { MessagesService } from "services/Messages";
import Politics from "views/app-views/properties/about/components/Politics";

export default function Policy() {
	const [form] = Form.useForm();
	const [policy, setPolicy] = useState();
	const [btnLoader, setBtnLoader] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		PropertyApi.getPrimaryPropertyPolicy().then((policy) => {
			setPolicy(policy);
			setLoading(false);
		});
	}, []);
	const onFinish = () => {
		setBtnLoader(true);
		PropertyApi.putPrimaryPropertyPolicy(form.getFieldsValue().policies)
			.then((r) => {
				MessagesService.success();
				setBtnLoader(false);
			})
			.catch((err) => {
				MessagesService.error();
			});
	};
	if (loading) {
		return <Loading />;
	}
	return (
		<>
			<h2 className="my-3 mt-2">{t("Policies")}</h2>
			<p>{t("Specify some")}</p>
			{/* <CancelationPoliticsList /> */}
			<Form form={form} initialValues={{ policies: policy }}>
				<Politics initialValues={policy} isRegistration={false} />
				<Col xs={24} sm={24} md={24} lg={18}>
					<Form.Item style={{ float: "right" }}>
						<Button
							type="primary"
							onClick={onFinish}
							loading={btnLoader}
						>
							{t("Save")}
						</Button>
					</Form.Item>
				</Col>
			</Form>
		</>
	);
}
