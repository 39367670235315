import { FileImageOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Upload } from "antd";
import { t } from "i18n";
import React from "react";
import ContentTranslationFormItem from "services/ContentTranslation/ContentTranslationFormItem";
import { imageUploadCustomRequest } from "views/app-views/properties/about/components/UploadCustomRequest";

export function BedTypesForm(props) {
	return (
		<Form
			name="edit"
			layout="vertical"
			form={props.form}
			onFinish={props.onFinish}
		>
			<Form.Item
				name="name"
				label={t("Bed Types name")}
				rules={[
					{
						required: true,
						message: `${t("Name is required")}`,
					},
				]}
			>
				<Input placeholder={t("Bed Types name")} />
			</Form.Item>

			<ContentTranslationFormItem name="name_translations" />

			<Form.Item name="icon" label={t("Icon link(url)")}>
				<Upload
					accept="image/png, image/jpeg, image/svg"
					onChange={props.handleChange}
					listType="picture-card"
					fileList={props.fileList}
					customRequest={imageUploadCustomRequest}
					showUploadList={true}
					onRemove={props.deleteFile}
					maxCount={1}
				>
					{props.fileList.length < 1 ? (
						<>
							<FileImageOutlined /> Upload
						</>
					) : (
						""
					)}
				</Upload>
			</Form.Item>

			<Form.Item name="status" label={t("Status")}>
				<Select
					options={[
						{
							label: `${t("In Active")}`,
							value: 0,
						},
						{
							label: `${t("Active")}`,
							value: 1,
						},
						{
							label: `${t("Draft")}`,
							value: 2,
						},
					]}
				/>
			</Form.Item>

			<Form.Item>
				<Button type="primary" htmlType="submit">
					{t("Save")}
				</Button>
			</Form.Item>
		</Form>
	);
}
